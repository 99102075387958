import { saveState, loadState } from '../utils/storage'
import { v4 } from 'uuid'

const KEY_NAME = 'UUID'

export const getUuid = () => {
  let id = loadState(false, KEY_NAME, '')

  if (!id) {
    id = v4()
    saveState(id, false, KEY_NAME)
  }
  
  return id
}