import Message from './Message'

const Receiver = ({ data: { author, time, message, responses } }) => {
  return (
    <div className='receiver__response'>
      {message && <Message
        isAvatar
        author={author}
        message={message}
        time={time} />}

      {responses.length > 0 && responses.map(({ author, message, time }, index) => {
        const isAvatar = !(responses[index + 1] && responses[index + 1].author === author)

        return <Message
          key={index}
          isAvatar={isAvatar}
          author={author}
          message={message}
          time={time} />
      })}
    </div>
  )
}

export default Receiver