import OpinionPopup from '../../../components/popups/OpinionPopup'
import Star from '../../../components/icons/Star'
import { useState, useEffect } from 'react'

const OrderOpinion = ({ order_ident, questions, answers: previousAnswers }) => {
  const [opinionPopupVisible, setOpinionPopupVisibility] = useState(false)
  const [answers, setAnswers] = useState(previousAnswers)
  const [answersAvg, setAnswersAvg] = useState(0)

  const showOpinionPopup = () => setOpinionPopupVisibility(!opinionPopupVisible)

  useEffect(() => {
    if (Object.keys(answers).length > 0) {
      let length = 0
      const sum = Object.entries(answers).reduce((r, [key, value]) => {
        const number = Number(value)
        if (number) {
          r += number
          length++
        }
        return r
      }, 0)
      if (sum && length) setAnswersAvg(sum / length)
    }
  }, [answers])

  const opinionPopup = <OpinionPopup
    order_ident={order_ident}
    questions={questions}
    answers={answers}
    closeAction={showOpinionPopup}
    submitAnswers={answers => {
      setAnswers(answers)
      showOpinionPopup(false)
    }} />

  return (
    <>
      {opinionPopupVisible && opinionPopup}

      <h2>Oceń obsługę i danie</h2>
      <div className='order__starts' onClick={showOpinionPopup}>
        <Star filled={answersAvg >= 1} />
        <Star filled={answersAvg >= 2} />
        <Star filled={answersAvg >= 3} />
        <Star filled={answersAvg >= 4} />
        <Star filled={answersAvg >= 5} />
      </div>
    </>
  )
}

export default OrderOpinion