import './style.scss'

const Error = ({text = 'Coś poszło nie tak 😞'}) => {
  return (
    <div className='error'>
      {text}
    </div>
  )
}

export default Error