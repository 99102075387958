import TextBtn, { TEXT_BTN_TYPE } from '../../../components/buttons/TextBtn'
import { lazy, Suspense, useEffect, useState } from 'react'
import Loading from '../../../components/Loading'
import { STATUS } from '../../../rest/status'
import { post } from '../../../rest/request'
import { payment } from '../../../rest/urls'
import { useHistory } from 'react-router'
import Header from './Header'
import './style.scss'

const QRCode = lazy(() => import('react-qr-code'))

const Restaurant = ({ checkout }) => {
  const [status, setStatus] = useState(STATUS.pennding)
  const [code, setCode] = useState(0)
  const isCheckout = checkout === 'true'

  let { goBack, push } = useHistory()

  const id = 299

  useEffect(() => {
    if (isCheckout) {
      (async () => {
        try {
          const response = await post(payment, { gatewayType: 'cash' })
          console.log(response)
          if (response.code) {
            setCode(response.code)
            setStatus(STATUS.succeed)
          } else throw new Error('no code')
        } catch (err) {
          console.log(err)
          setStatus(STATUS.failed)
        }
      })()
    }
  }, [isCheckout])

  return (
    <div className='payment__restaurant'>
      <header>
        <Header
          h1='Gotowe!'
          p={isCheckout ? 'Poniżej twój kod płatności' : 'Opłać swoje zamówienie u kelnera'} />
      </header>

      <div className='restaurant__main'>
        {isCheckout ? (
          status === STATUS.pennding ? <Loading middleOfPage={false} />
            : status === STATUS.failed ? <p>Cos poszło nie tak</p>
              : <>
                <Suspense fallback={<div />}>
                  <QRCode size={128} value={`${code}`} />
                </Suspense>
                <Header
                  h1={code}
                  p='Pokaż ten kod przy kasie aby opłacić swoje zamówienie i ominąć kolejkę!' />
              </>
        ) : (
          <Header
            h1={`ID: ${id}`}
            p='Nie masz jeszcze otwartego rachunku, musisz wysłać je kelnerowi.' />
        )}

        <div className='restaurant__buttons'>
          <TextBtn
            desc={isCheckout ? 'OK, pokaż moje zamówienia' : 'Prześlij zamówienie kelnerowi'} />
          <TextBtn
            desc='Lub zmień formę płatności'
            type={TEXT_BTN_TYPE.unfilled}
            clickAction={() => window.history.state ? goBack() : push('/')} />
        </div>
      </div>
    </div>
  )
}

export default Restaurant