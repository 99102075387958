import './style.scss'

export const TEXT_BTN_TYPE = {
  border: '--border',
  normal: '--normal',
  reduced: '--reduced',
  unfilled: '--unfilled',
}

const TextBtn = ({ desc, type = TEXT_BTN_TYPE.normal, clickAction }) => {
  return (
    <button
      className={`button__text ${type}`}
      onClick={clickAction}>
      {desc}
    </button>
  )
}

export default TextBtn