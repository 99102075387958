import { selectBasketPrice } from '../../conteiners/Basket/basketSlice'
import { useSelector } from 'react-redux'
import './style.scss'

const Price = () => {
  const finalPrice = useSelector(selectBasketPrice)

  return (
    <div className='price'>
      <span>Razem: </span>
      <span className='--heavy'>{finalPrice}</span>
      <sup> zł</sup>
    </div>
  )
}

export default Price