import { ReactComponent as Cooking } from '../../assets/icons/cooking.svg'
import Logo from '../../components/Logo'
import './style.scss'

const Break = () => {
  return (
    <div className='technicalBreak'>
      <main>
        <Logo />
        <h2>Przerwa techniczna</h2>

        <Cooking />

        <h3>
          <div>Bez obaw, jeśli zdążyłeś zamówić</div>
          <div>danie na pewno do Ciebie dotrze</div>
        </h3>
      </main>
    </div>
  )
}

export default Break