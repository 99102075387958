import { selectedConfig } from '../Settings/settingsSlice'
import { addOrUpdateTips, selectTips } from './basketSlice'
import TipPopup from '../../components/popups/TipPopup'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'

const TipElement = () => {
  const [popupVisible, setPopupVisibility] = useState(false)
  const config = useSelector(selectedConfig)
  const data = useSelector(selectTips)
  const dispatch = useDispatch()

  const { basketPrice, tips } = data

  const showDetails = () => setPopupVisibility(!popupVisible)

  const tipsPrice = Object.entries(tips).reduce((r, [key, value]) => {
    r += value > 0 && value < 1 ? basketPrice * value : value
    return r
  }, 0)

  const price = tipsPrice > 0 ? <span>{(tipsPrice).toFixed(2)}<sup> zł</sup></span> : '+'
  const text = tipsPrice > 0 ? 'Napiwek' : 'Dodaj napiwek dla obsługi'

  const tipPopup = <TipPopup
    data={{ ...data, definitions: config['tip_definitions'] }}
    closeAction={showDetails}
    sendTips={tips => {
      dispatch(addOrUpdateTips(tips))
      showDetails()
    }} />

  return (
    <div className='list__element --none'>
      {popupVisible && tipPopup}
      <div className='element__description'>
        <div className='element__col'>
          <span className='element__title'>{text}</span>
          <div className='additional__list'>

            {Object.entries(tips).map(([key, value]) => {
              if (value > 0) {
                return <div key={key} className='additional__element'>
                  <span className='element__title --color'>+</span>
                  <span className='element__title'>dla {key === '1' ? 'kelnera' : key === '2' ? 'kucharza' : 'aplikacji'}</span>
                  <span className='element__title --secondary'>
                    {value < 1 ? `10% = (${(value * basketPrice).toFixed(2)}` : `(${value.toFixed(2)}`}zł)
                  </span>
                </div>
              }
              else return false
            })}

          </div>
        </div>
      </div>
      <div className='element__price' onClick={showDetails}>{price}</div>
    </div>
  )
}

export default TipElement