import { selectedTable } from '../../../conteiners/Scanner/tableSlice'
import { SCANNER_TYPE } from '../../../conteiners/Scanner'
import { post } from '../../../rest/request'
import Comments from '../../inputs/Comments'
import TextBtn from '../../buttons/TextBtn'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { chat } from '../../../rest/urls'
import { useHistory } from 'react-router'
import OneBtnPopup from '../OneBtnPopup'
import Input from '../../inputs/Input'
import Popup from '../Popup'
import './style.scss'

const CleanUpTable = ({ order_ident = 0, history = true, number = '', closeAction }) => {
  const tableNumber = useSelector(selectedTable)
  const [table, setTable] = useState(number ? number : tableNumber ? tableNumber : '')
  const [errorPopup, setErrorPopup] = useState('')
  const [warning, setWarning] = useState(false)
  const [notes, setNotes] = useState('')

  let { push, replace, location: { pathname } } = useHistory()

  useEffect(() => {
    if (order_ident === 0 && !tableNumber && pathname !== '/scan')
      push(`/scan?type=${SCANNER_TYPE.cleanUp}`)
  }, [order_ident, tableNumber, pathname, push])

  const changeHandler = e => {
    const { value } = e.target
    const number = Number(value)

    if (!value || Number.isInteger(number))
      setTable(value)
    if (number) setWarning(false)
  }

  const submitOption = async () => {
    const body = {
      action: 'new_request',
      help_type: 1,
      notes,
    }

    if (order_ident) body.order_ident = order_ident
    else body.table = table

    try {
      const response = await post(chat, body)
      if (response.wynik === 'ok')
        history ? push('/chat') : replace('/chat')
      else setErrorPopup(response.error)
    }
    catch (err) {
      console.log(err)
      setErrorPopup('Nie udało się!')
    }
  }

  return (
    errorPopup ? <OneBtnPopup
      title='Uwaga'
      btnText='OK'
      btnAction={closeAction}
      description={errorPopup}
      isCloseBtn={false}
    /> : (
      <Popup closeAction={closeAction}>
        <div className='popup__main--service'>
          <h2 className='popup__title'>Sprzątanie stolika</h2>
          <div className='popup__description'>Czy jesteś pewien, że chcesz poprosić o sprzątanięcie stolika?</div>


          <div className='popup__inputs'>
            {!order_ident && <Input
              value={table}
              warning={warning}
              type='number'
              placeholder='Wpisz numer stolika'
              warningMessage='Wpisz właściwy nr stolika'
              changeHandler={changeHandler}
            />}

            <Comments
              value={notes}
              placeholder='Uwagi'
              changeHandler={e => setNotes(e.target.value)} />
          </div>

          <TextBtn
            desc={`Tak, proszę o sprzątnięcie`}
            clickAction={() => {
              if (table || order_ident) submitOption()
              else setWarning(true)
            }} />
        </div>
      </Popup>
    )
  )
}

export default CleanUpTable