import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { post } from '../../rest/request'
import { status } from '../../rest/urls'

export const fetchStatus = createAsyncThunk('status/fetchStatus', async () => {
  const { pathname, search } = window.location
  const response = await post(status, { page_path: `${pathname}${search}` })
  return response
})

const DURATION = {
  short: 2000,
  long: process.env.REACT_APP_CHRISTMAS_STORE === 'true' ? 30000 : 10000,
}

export const statusSlice = createSlice({
  name: 'status',
  initialState: {
    data: {},
    duration: DURATION.long,
  },
  reducers: {
    speedUpDuration: (state) => {
      state.duration = DURATION.short
    }
  },
  extraReducers: {
    [fetchStatus.fulfilled]: (state, action) => {
      const status = action.payload
      const { payment } = action.payload

      if (payment.wynik && payment.wynik === 'end')
        state.duration = DURATION.long

      state.data = status
    },
  }
})

export const { speedUpDuration } = statusSlice.actions

export const selectStatus = state => state.status.data
export const selectDuration = state => state.status.duration
export const selecetCanOrder = state => state.status.data.can_order ? state.status.data.can_order : '0'
export const selectOpenedStatus = state => state.status.data.is_opened ? state.status.data.is_opened : ''
export const selectPaymentStatus = state => state.status.data.payment ? state.status.data.payment : ''
export const selectOrderStatus = state => {
  const { data } = state.status
  return (data.orders_status && !data.orders_status.carts) ? data.orders_status : 0
}

export default statusSlice.reducer
