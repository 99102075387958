import BasketDetailsPopup from '../../components/popups/BasketDetailsPopup'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { useState } from 'react'

const FoodElement = ({ data, index }) => {
  const { name, EKR_ID = '', additional, fields, order_zpo, amount, unitPrice, finalPrice } = data
  const [popupVisible, setPopupVisibility] = useState(false)

  const showDetails = () => setPopupVisibility(!popupVisible)

  const additionalElements = (additional && additional.length > 0) ? additional
    .map(({ products }) => products.filter(e => e.amount).map(({ id, name, amount, price }) => {
      return (
        <div
          key={id}
          className='additional__element'>
          <span className='element__title --color'>+</span>
          <span className='element__title'>{amount} x {name}</span>
          <span className='element__title --secondary'>({price.toFixed(2)}zł)</span>
        </div>
      )
    })) : (fields && order_zpo && fields.length > 0) ? fields
      .filter(({ type }) => type === 'number' || type === 'select' || type === 'text')
      .map(({ field_id, type, unit, options = [] }) => {
        if (type === 'number') {
          const slider = fields.find(({ type }) => type === 'slider')

          return <div
            key={field_id}
            className='additional__element'>
            {order_zpo[field_id] && <span className='element__title'>{order_zpo[field_id]} {unit}</span>}
            {slider && <span className='element__title'> między {order_zpo[slider.field_id]?.min} a {order_zpo[slider.field_id]?.max} {slider?.unit}</span>}
          </div>

        } else if (type === 'select') {
          return <div
            key={field_id}
            className='additional__element'>
            {order_zpo[field_id] && order_zpo[field_id].map(e => {
              const num = Number(e)
              const selected = options.find(({ value }) => value === num)
              return <span key={e} className='element__title'>{selected?.name}</span>
            }
            )}
          </div>

        } else return order_zpo[field_id] ? <div
          key={field_id}
          className='additional__element'>
          <span className='element__title'><i>„{order_zpo[field_id]}”</i></span>
        </div> : false
      }) : false

  return (
    <>
      {popupVisible && <BasketDetailsPopup
        index={index}
        closeAction={showDetails} />}

      <div className='list__element'>
        <div className='element__description'>
          <div className='element__col'>
            <span className='element__title'>{amount && `${amount} x `}{name}</span>
            <span className='element__title --secondary'>({unitPrice.toFixed(2)}zł)</span>
            <div className='additional__list'>{additionalElements}</div>
          </div>
          <div className='element__col'>
            <EditIcon onClick={showDetails} />
          </div>
        </div>
        <div className='element__price'>
          <span>{EKR_ID ? '~ ' : ''}{finalPrice.toFixed(2)}<sup> zł</sup></span>
        </div>
      </div>
    </>
  )
}

export default FoodElement