import { useState, useEffect } from 'react'

const Search = ({ clicked, clickHandler }) => {
  const [isClicked, setClick] = useState(clicked)
  const buttonClicked = () => {
    setClick(!isClicked)
    clickHandler()
  }

  useEffect(() => {
    setClick(clicked)
  }, [clicked, isClicked])

  const color = isClicked ? 'var(--main-color)' : 'var(--theme-text)'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" cursor="pointer" onClick={buttonClicked}>
      <g id="Szukaj" transform="translate(0 -0.113)">
        <rect id="Rectangle_19" data-name="Rectangle 19" width="40" height="40" rx="9" transform="translate(0 0.113)" fill="rgba(255,255,255,0)" />
        <g id="Group_7" data-name="Group 7" transform="translate(11.704 10.64)">
          <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(0.296 0.473)" fill="var(--theme-secondary-bg)" stroke={color} strokeWidth="2">
            <circle cx="8" cy="8" r="8" stroke="none" />
            <circle cx="8" cy="8" r="7" fill="none" />
          </g>
          <line id="Line_3" data-name="Line 3" x2="3.584" y2="3.584" transform="translate(13.677 14.272)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
        </g>
      </g>
    </svg>
  )
}

export default Search