import { ReactComponent as Arrows } from '../../assets/icons/arrowUpDown.svg'
import { ReactComponent as Warning } from '../../assets/icons/warning.svg'
import { ReactComponent as Clock } from '../../assets/icons/clock.svg'
import './style.scss'

const TimePicker = ({ today = true, selected, hours, warning = false, warningMessage = '', changeHandler }) => {

  const getCurrentOpyions = (() => {
    const options = []
    if (hours) {
      const lastIdx = hours.length - 1
      hours.map((e, idx) => idx !== lastIdx ? options.push(`${e}:00`, `${e}:30`) : options.push(`${e}:00`))

    } else {
      const now = new Date()
      const hour = now.getHours()
      const minutes = now.getMinutes()
      const startTime = hour < 11 || !today ? 11 : hour

      for (let i = startTime; i < 20; i++) {
        if (i === hour) {
          if (minutes < 0) options.push(`${i}:00`)
          if (minutes < 15) options.push(`${i}:15`)
          if (minutes < 30) options.push(`${i}:30`)
          if (minutes < 45) options.push(`${i}:45`)
        } else options.push(`${i}:00`, `${i}:15`, `${i}:30`, `${i}:45`)
      }
    }

    return options
  })()

  return (
    <div className='select'>
      <div className='select__icon --left'>
        {warning ? <Warning /> : <Clock />}
      </div>

      <div className='select__icon --right'>
        <Arrows />
      </div>

      <select
        name='time'
        defaultValue=''
        className={`select__main ${warning ? '--warning' : ''}`}
        onChange={changeHandler}
        required>

        {!selected && <option value='' disabled hidden>Wybierz godzine</option>}
        {getCurrentOpyions.map(e => <option
          key={e}
          value={e}>
          {e}
        </option>)}

      </select>
      {warning && <span className='select__warning'>{warningMessage}</span>}
    </div>
  )
}

export default TimePicker