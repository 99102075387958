import { ReactComponent as Sender } from '../../assets/icons/send.svg'
import BackBtn from '../../components/buttons/BackBtn'
import { useEffect, useState } from 'react'
import { post } from '../../rest/request'
import { useHistory } from 'react-router'
import { chat } from '../../rest/urls'
import Receiver from './Receiver.js'
import './style.scss'

const Chat = ({ id }) => {
  const [selectedChat, selectChat] = useState(0)
  const [notes, setNotes] = useState('')
  const [chats, setChats] = useState([])

  let { push, goBack } = useHistory()

  useEffect(() => {
    (async () => {
      try {
        const response = await post(chat, { action: 'get_chat' })
        if (response.wynik === 'chat') {
          setChats(response.chats)
          if (id) {
            const index = response.chats.findIndex(e => e.id === id)
            if (index > -1) selectChat(index)

          } else if (response.chats.length === 1) selectChat(0)
        }
      } catch (err) { console.log(err) }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchChat = async () => {
      try {
        const response = await post(chat, { action: 'get_chat' })
        if (response.wynik === 'chat') setChats(response.chats)
      } catch (err) { console.log(err) }
    }

    let interval = setInterval(fetchChat, 2000)
    return () => clearInterval(interval)
  }, [])

  const sendMessage = async () => {
    if (!notes || chats[selectedChat].closed_date) return

    const body = {
      action: 'reply',
      help_id: chats[selectedChat].id,
      notes,
    }

    try {
      const response = await post(chat, body)
      if (response.wynik === 'ok') setNotes('')
    } catch (err) { console.log(err) }
  }

  return (
    <div className='chat'>
      <main>
        <nav>
          <BackBtn backAction={() => window.history.state ? goBack() : push('/')} />
          {chats.map(({ id, type_name, unread, time, related }, index) => {
            const table = related ? related.table_number : ''
            const timeList = time ? time.split(' ') : []

            return <div
              key={id}
              className={`chat__button ${index === selectedChat ? '--filled' : ''}`}
              onClick={() => selectChat(index)} >
              <div>{unread ? '* ' : ''}{type_name}</div>
              <span>{timeList.length === 2 ? timeList[1] : time} </span>
              {table && <span>stolik: {table}</span>}
            </div>
          })}
        </nav>

        <div className='chat__receiver'>
          {chats[selectedChat] && <>
            <h2 className='receiver__title'>{chats[selectedChat].type_name}</h2>
            <Receiver data={chats[selectedChat]} />
            
            {chats[selectedChat].closed_date && <div className='receiver__title'>
              <div className='--big'>Zgłoszenie zamknięte</div>
              <div className='--small'>{chats[selectedChat].closed_date}</div>
            </div>}
          </>}
        </div>

        <div className='chat__sender'>
          <textarea
            rows='1'
            value={notes}
            placeholder='Napisz swoją wiadomość...'
            disabled={chats[selectedChat] && chats[selectedChat].closed_date}
            onChange={e => e.target.value !== '\n' && setNotes(e.target.value)} />
          <Sender onClick={sendMessage} />
        </div>
      </main>
    </div>
  )
}

export default Chat