import { selectCatregories, selectResponseStatus } from './categoriesSlice'
import { useHistory, useRouteMatch } from 'react-router'
import CategoriesTiles from './CategoriesTiles'
import Loading from '../../components/Loading'
import Error from '../../components/Error'
import { STATUS } from '../../rest/status'
import { useSelector } from 'react-redux'
import './style.scss'

const Categories = ({ isMobile = true }) => {

  const categories = useSelector(selectCatregories)
  const status = useSelector(selectResponseStatus)

  let { path } = useRouteMatch()
  let { location: { pathname } } = useHistory()
  let actualKey = pathname.split('/').pop()

  const tiles = categories.map((tile) => (
    <CategoriesTiles key={tile.key} isMobile={isMobile} actualKey={actualKey} path={path} data={tile} />))

  if (!isMobile) {
    return <div className={`categories--desktop`}>{tiles}</div>
  }

  return (
    <div className='categories'>
      <div className='categories__titles'>
        <div className='categories__title'>Kategorie</div>
        <div className='categories__title--secondary'>Poznaj nasze menu</div>
      </div>
      <div className='categories__tiles'>
        {status === STATUS.failed ? <Error />
          : status === STATUS.succeed ? tiles
            : <Loading />}
      </div>
    </div>
  )
}

export default Categories