import { useEffect, useState } from 'react'

const Hamburger = ({ clicked, clickHandler }) => {
  const [isClicked, setClick] = useState(clicked)
  const buttonClicked = () => {
    setClick(!isClicked)
    clickHandler()
  }

  useEffect(() => {
    setClick(clicked)
  }, [clicked, isClicked])

  const color = isClicked ? 'var(--main-color)' : 'var(--theme-text)'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" cursor="pointer" onClick={buttonClicked}>
      <g id="menu_trigger" data-name="menu trigger" transform="translate(0 -0.113)">
        <rect id="Rectangle_14" data-name="Rectangle 14" width="40" height="40" rx="9" transform="translate(0 0.113)" fill="none" />
        <g id="Sort_icon" data-name="Sort icon" transform="translate(11.126 13.547)">
          <line id="Line_11" data-name="Line 11" x1="18.012" transform="translate(0)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
          <line id="Line_12" data-name="Line 12" x1="18.012" transform="translate(0 6.539)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
          <line id="Line_13" data-name="Line 13" x1="18.012" transform="translate(0 13.078)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="2" />
        </g>
      </g>
    </svg>
  )
}

export default Hamburger