import TextBtn, { TEXT_BTN_TYPE } from '../../../components/buttons/TextBtn'
import { selectBasketPrice } from '../../Basket/basketSlice'
import Input from '../../../components/inputs/Input'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useState } from 'react'
import './style.scss'

const BlikForm = ({ error, submitForm }) => {
  const finalPrice = useSelector(selectBasketPrice)
  const [warning, setWarning] = useState(false)
  const [code, setCode] = useState('')
  const blikRegex = /^[0-9]{3} [0-9]{3}$/

  let { goBack, push } = useHistory()

  const changeHandler = e => {
    if (!e) return

    let { value } = e.target
    value = e.target.value.replace(/^(\d{3})(\d+)$/, '$1 $2')
    if (!value || value.match(/^([0-9]{0,3})[ ]?([0-9]{0,3})$/)) setCode(value)
    if (value.match(blikRegex)) setWarning(false)
  }

  return (
    <>
      <div className='blik__price'>
        <span>Do zapłaty: </span>
        <span className='--heavy'>{finalPrice}</span>
        <sup> zł</sup>
      </div>

      {error && <div
        className='blik__error'>Płatność odrzucona przez Bank</div>}

      <div className="blik__form">
        <div className="blik__title">Wpisz kod BLIK</div>

        <Input
          value={code}
          warning={warning}
          type='tel'
          placeholder='Wpisz kod BLIK'
          warningMessage='Wpisz właściwy kod BLIK'
          changeHandler={changeHandler} />

        <div className="blik__text">Płatność realizowana za pośrednictwem Blue Media S.A.</div>
      </div>

      <div className="blik__buttons">
        <TextBtn
          desc='Wykonaj'
          clickAction={() =>
            code.match(blikRegex) ? submitForm(code) : setWarning(true)} />

        <TextBtn
          type={TEXT_BTN_TYPE.unfilled}
          desc='Wróć'
          clickAction={() => window.history.state ? goBack() : push('/')} />
      </div>
    </>
  )
}

export default BlikForm