import CleanUpTable from '../../../components/popups/ServicePopup/CleanUpTable'
import { checknumber, checktablecode } from '../../../rest/urls'
import { selectBasketPrice } from '../../Basket/basketSlice'
import { updateTable } from '../../Scanner/tableSlice'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../../components/Loading'
import { FORM_TYPE } from '../../Finalization'
import { STATUS } from '../../../rest/status'
import { post } from '../../../rest/request'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import QrReader from 'react-qr-reader'
import TablePopup from './TablePopup'
import { SCANNER_TYPE } from '../'
import './style.scss'

const Qr = ({ type, showError }) => {
  const [tablePopupVisible, setTablePopupVisibility] = useState(false)
  const [support, showSupport] = useState(type === SCANNER_TYPE.cleanUp)
  const [status, setStatus] = useState(STATUS.pennding)
  const [active, setActive] = useState(true)
  const [table, setTable] = useState('')

  const basketPrice = useSelector(selectBasketPrice)
  const dispatch = useDispatch()

  let { goBack, push, replace } = useHistory()

  const mod = status === STATUS.succeed ? '--white' : '--normal'
  const back = () => window.history.state ? goBack() : push('/')
  const showTablePopup = () => setTablePopupVisibility(!tablePopupVisible)

  useEffect(() => {
    if (type === SCANNER_TYPE.table && status === STATUS.succeed) {
      let timer = setTimeout(() => showSupport(true), 5000)
      return () => clearTimeout(timer)
    }
  }, [type, status])

  const dispatchTable = (table, orderStatus) => {
    if (Number(table)) {
      if (type === SCANNER_TYPE.cleanUp) {
        setTable(table)
        showTablePopup()

      } else {
        dispatch(updateTable(table))
        if (orderStatus) replace('/orders')
        else Number(basketPrice) > 0 ? replace(`/finalization/form?type=${FORM_TYPE.orderToTable}`) : back()
      }

    } else setActive(true)
  }

  const dispatchStatus = table => {
    if (Number(table)) {
      dispatch(updateTable(table))
      replace(type === SCANNER_TYPE.table ? `/finalization/form?type=${FORM_TYPE.orderToTable}` : '/orders')

    } else setActive(true)
  }

  const fetchData = async (url, body) => {
    try {
      const response = await post(url, body)
      const { stolik: table, order_status: orderStatus } = response

      if (table) url === checknumber ? dispatchStatus(table) : dispatchTable(table, orderStatus)
    }
    catch (err) { console.log(err) }
  }

  const handleScan = data => {
    if (!active || !data) return
    setActive(false)

    if (data.includes('stolik')) fetchData(checktablecode, { 'table_code': data })
    if (data.includes('status')) fetchData(checknumber, { 'external': data })
  }

  return (
    <div className='scan'>
      {support && <div
        className={`scan__support ${mod}`}
        onClick={() => {
          setStatus(STATUS.idle)
          showTablePopup()
        }} >Wolisz wpisać ręcznie?</div>}

      <div className={`scan__title ${mod}`}>
        {type === SCANNER_TYPE.status ? 'Zeskanuj swoje zamówienie' : 'Zeskanuj numer swojego stolika'}
      </div>

      {tablePopupVisible ? (
        type === SCANNER_TYPE.table ? <TablePopup
          updateTable={dispatchTable}
          showWarning={status === STATUS.failed}
          closeAction={status === STATUS.idle ? showTablePopup : back}
        /> : <CleanUpTable
          history={false}
          number={table}
          closeAction={status === STATUS.idle ? showTablePopup : back} />
      ) : (
        <>
          {status === STATUS.pennding && <Loading />}

          {status === STATUS.succeed && <>
            <div className='scan__frame --top' />
            <div className='scan__frame --left' />
            <div className='frame__cross' />
            <div className='scan__frame --right' />
            <div className='scan__frame --bottom' />
          </>}

          <QrReader
            delay={500}
            onScan={handleScan}
            onError={() => {
              setStatus(STATUS.failed)
              type === SCANNER_TYPE.status ? showError() : showTablePopup()
            }}
            onLoad={() => setStatus(STATUS.succeed)}
            style={{ width: window.innerWidth, height: window.innerHeight }} />

        </>
      )}

      <div
        className={`scan__button ${mod}`}
        onClick={back}>Zamknij</div>
    </div>
  )
}

export default Qr // https://osada.dostolika.pl/stolik/GrvXQN