import { ReactComponent as Mouse } from '../../assets/icons/mouse.svg'
import { selectedConfig } from '../../conteiners/Settings/settingsSlice'
import { getBannersList } from './getBannersList'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TextBtn from '../buttons/TextBtn'
import { useRef } from 'react'
import './style.scss'

const Banners = ({ extraList = [] }) => {
  const isHomePage = window.location.pathname === '/'
  const { banners } = useSelector(selectedConfig)
  const [list, setList] = useState(extraList)
  const listRef = useRef(null)

  useEffect(() => {
    if (banners && banners.collection && !list.length) setList(getBannersList(banners, isHomePage))
  }, [banners, isHomePage, list.length])

  return list.length ? (
    <div className='banners'>
      <div
        className='banners__header'
        onClick={() => listRef.current && listRef.current.scrollIntoView({ behavior: "smooth" })}>
        <div className='banners__title'>Zobacz co się u Nas dzieje</div>
        <Mouse />
      </div>

      <div
        ref={listRef}
        className='banners__list'>
        {list.map(({ title, description, picture, link_type, link }, idx) => {
          const action = () => window.open(link, link_type ? '_self' : '_blank')

          return <div
            key={idx}
            className='banner'>

            <div className='banner__picture'>
              <img src={picture} alt={title} onClick={action} />
              <div className='banner__title'>{title}</div>
            </div>
            <div className='banner__details'>
              <div className='banner__desc'>{description}</div>
              <div className='banner__button'>
                <TextBtn desc='Zobacz szczegóły' clickAction={action} />
              </div>
            </div>
          </div>
        })}
      </div>

    </div>
  ) : false
}

export default Banners