import { ReactComponent as BasketIcon } from '../../../assets/icons/shoppingBasket.svg'
import ComposeListElement from './ComposeListElement'
import CloseBtn from '../../buttons/CloseBtn'
import FoodCounter from '../../FoodCounter'
import { useEffect, useState } from 'react'
import './style.scss'

const ComposeFoodPopup = ({ data, closeAction, basketAction }) => {
  const { name, amount, price: foodPrice, finalPrice: fPrice, additional: originalAdditional } = data
  const [additional, setAdditional] = useState([])
  const [foodAmount, setFoodAmount] = useState(amount || 1)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [finalPrice, setFinalPrice] = useState(fPrice || foodAmount * foodPrice)

  useEffect(() => {
    const escDetect = e => e.keyCode === 27 && closeAction()
    document.body.style.overflow = 'hidden'
    document.addEventListener('keydown', escDetect, false)

    return () => {
      document.body.style.overflow = 'auto'
      document.removeEventListener('keydown', escDetect, false)
    }
  }, [closeAction])

  useEffect(() => {
    const newAdditional = [...originalAdditional]
      .map(a => {
        const { id, max_chosen_count, min_chosen_count, name, products } = a
        const collection = Array.isArray(products) ? products : products.collection
        const max = max_chosen_count ? max_chosen_count : a.max
        const min = min_chosen_count ? min_chosen_count : a.min

        const list = collection.map(l => {
          const { id, name, gross_price, amount } = l
          const price = gross_price ? Number(gross_price) : l.price
          return { id, name, price, amount: amount ? amount : 0 }
        })
        return { id, max, min, name, products: list }
      })

    setAdditional(newAdditional)
    updateNumberOfSelectedOptions(newAdditional)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalAdditional])

  const updateNumberOfSelectedOptions = (list = additional) =>
    setSelectedOptions(list.map(({ products }) => products.reduce((r, { amount }) => r += amount, 0)))

  const updateAmountOfAdditional = (element, value) => {
    if (element) {
      const { price, amount = 0 } = element
      const difference = value - amount

      element.amount = value
      setFinalPrice(finalPrice + (difference * price * foodAmount))
      updateNumberOfSelectedOptions()
    }
  }

  const updateAmountOfFood = newAmount => {
    setFinalPrice((finalPrice * newAmount) / foodAmount)
    setFoodAmount(newAmount)
  }

  const list = additional.map(({ id, name, max, products }, index) => {
    return (
      <div key={id}
        className='popup__list'>
        <div className='list__title'>{name} ({selectedOptions[index]} z {max})</div>
        <div className='list__main'>
          {products.map(e =>
            <ComposeListElement
              key={e.id}
              data={e}
              maxOptions={max}
              currentOptions={selectedOptions[index]}
              updateAmount={updateAmountOfAdditional} />
          )}
        </div>
      </div>
    )
  })

  return (
    <div className='popup--compose'>
      <div className='popup__interior'>
        <CloseBtn
          desc='Zamknij i usuń'
          closeAction={closeAction} />

        <div className='popup__conteiner'>
          <div className='popup__main'>
            <div className='popup__title'>{name}</div>
            {list}
          </div>

          <div className='popup__footer'>
            <div className='footer__col'>
              <div className='footer__title'>Ilość zestawów</div>
              <FoodCounter
                amount={foodAmount}
                amountChanged={updateAmountOfFood} />
            </div>

            <div className='footer__col'>
              <div className='footer__title'>Podsumowanie</div>
              <div className='footer__group'>
                <div className='footer__price'>
                  <div><span>{finalPrice.toFixed(2)}</span>
                    <sup>zł</sup></div>
                </div>

                <div className='footer__button'
                  onClick={() => basketAction(foodAmount, additional, finalPrice)}>
                  <BasketIcon />
                  <span>Do koszyka</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComposeFoodPopup