
import OrderOpinion from './orderOpinion'
import OrderBasket from './orderBasket'

const months = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień']

const ChristmasOrder = ({ data: { order_ident, order_info, amount, pozycje }, status, questions, answers }) => {
  const { id_zam_pos, data_godz_odbioru, data_godz_odbioru_txt = '', imie, nazwisko, uwagi } = order_info
  const date = new Date(data_godz_odbioru.date)
  const time = data_godz_odbioru_txt.split(' ')

  return (
    <div className='order'>
      <div className='order__row'>
        <div className='order__group'>
          <div className='order__text--medium'>{id_zam_pos}</div>
          <div className='order__text'>Numer zamówienia</div>
        </div>

        <div className='order__group'>
          {date && <div className='order__text--medium'>{date.getDate()} {months[date.getMonth()]}</div>}
          <div className='order__text'>Data odbioru</div>
        </div>
      </div>

      <div className='order__info'>
        <div className='oreder__label'>Dane zamawiającego:</div>
        <p>{imie} {nazwisko}</p>

        {time[1] && <>
          <div className='oreder__label'>Godzina odbioru:</div>
          <p>{time[1]}</p>
        </>}

        {uwagi && <>
          <div className='oreder__label'>Uwagi:</div>
          <p>{uwagi}</p>
        </>}
      </div>

      <hr />

      <div>
        <p>{status.description}</p>
        <div className='order__progress'>
          <div className={`bubble ${status.step === 1 ? '--highlighted' : ''}`}>1</div>
          <hr />
          <div className={`bubble ${status.step === 2 ? '--highlighted' : ''}`}>2</div>
          <hr />
          <div className={`bubble ${status.step === 3 ? '--highlighted' : ''}`}>3</div>
        </div>
      </div>

      <hr />

      <OrderOpinion
        order_ident={order_ident}
        questions={questions}
        answers={answers} />

      <div className='order__price--store'>Przybliżona wartość zamówienia: <span>{amount.toFixed(2)}</span> <sup>zł</sup></div>

      {Object.entries(pozycje).map(([key, value]) =>
        Object.entries(value).map(([key, value]) =>
          <OrderBasket key={key} data={value} />
        )
      )}

    </div>
  )
}

export default ChristmasOrder