import { ReactComponent as PiecesLeftIcon } from '../../../../assets/icons/piecesLeft.svg'
import { ReactComponent as BasketIcon } from '../../../../assets/icons/shoppingBasket.svg'
import { ReactComponent as ComposeIcon } from '../../../../assets/icons/compose.svg'
import BasketDetailsPopup from '../../../../components/popups/BasketDetailsPopup'
import FoodDetailsPopup from '../../../../components/popups/FoodDetailsPopup'
import ComposeFoodPopup from '../../../../components/popups/ComposeFoodPopup'
import TextBtn, { TEXT_BTN_TYPE } from '../../../../components/buttons/TextBtn'
import VariantsPopup from '../../../../components/popups/VariantsPopup'
import IconTextBtn from '../../../../components/buttons/IconTextBtn'
import OrderPopup from '../../../../components/popups/OrderPopup'
import Weight from '../../../../components/icons/Weight'
import { addFood } from '../../../Basket/basketSlice'
import { useDispatch } from 'react-redux'
import { ReactSVG } from 'react-svg'
import { useState } from 'react'
import './style.scss'

const ListElementWide = ({ show, icon, photoVisibility, element }) => {

  const { id, is_for_order, name, weight, piecesLeft = 5, description: desc, display_unit, display_price, picture, variants = [], modifications = [] } = element
  const { is_drink: drink, czy_zablokowany_sprzedazowo: isBlocked, czy_zastrzezony_w_sprzedazy: isProprietary, is_deleted: isDeleted } = element
  const { url = '', width = 0 } = picture
  const price = Number(element.gross_price)

  const [popup, setPopup] = useState(show ? 'details' : '')
  const dispatch = useDispatch()

  const isAvailable = !isBlocked && !isProprietary && !isDeleted
  const isPhoto = !!(photoVisibility && width)
  const activMod = !isAvailable ? '--inactive' : ''
  const colorMod = isPhoto ? '--white' : ''
  const style = isPhoto ?
    { 'backgroundImage': `url(${url})` } :
    { 'border': '1px solid var(--border-color)' }

  const hidePopup = () => setPopup('')

  const showBasketDetails = () => setPopup('basket')

  const showPopup = (e, name) => {
    setPopup(name)
    e.stopPropagation()
  }

  const addToBasket = (e) => {
    dispatch(addFood({ id, name, drink, price }))
    showBasketDetails()
    e.stopPropagation()
  }

  const btnParams = !isAvailable ? {
    desc: 'Tymczasowo brak',
    type: TEXT_BTN_TYPE.reduced,
  } : is_for_order ? {
    desc: 'Zamów',
    Icon: BasketIcon,
    clickAction: e => showPopup(e, 'order'),
  } : variants.length > 0 ? {
    desc: 'Wybierz wariant',
    Icon: BasketIcon,
    clickAction: e => showPopup(e, 'variants'),
  } : modifications.length > 0 ? {
    desc: 'Komponuj',
    Icon: ComposeIcon,
    clickAction: e => showPopup(e, 'compose'),
  } : {
    desc: 'Do koszyka',
    Icon: BasketIcon,
    clickAction: addToBasket
  }

  const foodDetailsPopup = <FoodDetailsPopup
    data={{ id, name, desc, price, src: isPhoto ? url : '', icon }}
    buyBtn={!isAvailable ? <TextBtn
      desc={btnParams.desc}
      type={btnParams.type} /> : <IconTextBtn
      Icon={btnParams.Icon}
      desc={btnParams.desc}
      clickAction={btnParams.clickAction} />}
    closeAction={hidePopup} />

  const basketDetailsPopup = <BasketDetailsPopup closeAction={hidePopup} />

  const orderPopup = <OrderPopup
    id={id}
    name={name}
    price={price}
    closeAction={ordered => ordered ? showBasketDetails() : hidePopup()}
  />

  const foodVariantsPopup = <VariantsPopup
    list={variants}
    closeAction={hidePopup}
    sendAction={(variant) => {
      dispatch(addFood({ id, name, variant, drink, price }))
      showBasketDetails()
    }} />

  const composeFoodPopup = <ComposeFoodPopup
    data={{ id, name, price, additional: modifications }}
    closeAction={hidePopup}
    basketAction={(amount, newAdditional) => {
      dispatch(addFood({ id, name, amount, drink, additional: newAdditional, price }))
      showBasketDetails()
    }} />

  const details = <>
    <div className='element__price'>
      <div>{display_price} <sup>zł</sup></div>
      <div>za {display_unit}</div>
    </div>
    {weight && <div className='element__group'>
      <Weight white={isPhoto} />
      <div className='element__title'>{weight}</div>
    </div>}
  </>

  return (
    <>
      {popup && <>
        {popup === 'details' ? foodDetailsPopup :
          popup === 'basket' ? basketDetailsPopup :
            popup === 'variants' ? foodVariantsPopup :
              popup === 'compose' ? composeFoodPopup :
                orderPopup}
      </>}

      <div style={style}
        id={`food_list_element_${id}`}
        className={`list__element--wide ${activMod} ${colorMod}`}
        onClick={() => setPopup('details')}>

        <div className={`element__top ${isPhoto ? '--filled' : ''}`}>
          <div className='element__col'>
            <div className='element__title'>{name}</div>
            {desc && <div className='element__desc'>
              <div>{desc}</div>
              <span>{isPhoto && desc?.length > 30 ? 'czytaj więcej' : ''}</span>
            </div>}
          </div>

          <div className='element__col'>
            {isPhoto && details}
            {piecesLeft > 0 && piecesLeft < 5 && <div className='element__group'>
              <PiecesLeftIcon />
              <div className='element__title'>Zostało: {piecesLeft}</div>
            </div>}
          </div>
        </div>

        <div className='element__bottom'>
          <div className='element__col'>
            {!isPhoto && details}
          </div>
          <div className='element__col'>
            {!isPhoto && <div
              className='element__icon'><ReactSVG src={icon} /></div>}
            {!isAvailable ? <TextBtn
              desc={btnParams.desc}
              type={btnParams.type} /> : <IconTextBtn
              Icon={btnParams.Icon}
              desc={btnParams.desc}
              isColumnDirection
              clickAction={btnParams.clickAction} />}
          </div>
        </div>
      </div>
    </>
  )
}

export default ListElementWide