import { ReactComponent as CashRegister } from '../../assets/icons/cashRegister.svg'
import { ReactComponent as CreditCards } from '../../assets/icons/creditCards.svg'
//import { ReactComponent as Waiter } from '../../assets/icons/waiter.svg'
import TextBtn, { TEXT_BTN_TYPE } from '../../components/buttons/TextBtn'
import { useHistory, useRouteMatch } from 'react-router'
import blik from '../../assets/icons/blik.png'
import { FORM_TYPE } from '../Finalization'
import { gpayconfig, serverUrl } from '../../rest/urls'
import { useEffect, useState } from 'react'
import { STATUS } from '../../rest/status'
import Price from '../../components/Price'
import { get } from '../../rest/request'
import GPay from './GPay'
import './style.scss'

const Payment = ({ type }) => {
  const [status, setStatus] = useState(STATUS.pennding)
  const [data, setData] = useState({})

  let { push, goBack } = useHistory()
  let { url } = useRouteMatch()

  useEffect(() => {
    (async () => {
      try {
        const response = await get(gpayconfig)
        if (response.error) throw new Error(response.error)
        else {
          setData(response)
          setStatus(STATUS.succeed)
        }

      } catch (err) { 
        console.log(err)
        setStatus(STATUS.failed)
      }
    })()
  }, [])

  const openDetails = checkout =>
    push(`${url}/restaurant?checkout=${checkout}`)

  return (
    <div className='payment'>
      <div className='payment__main'>
        <header className='payment__header'>
          <CreditCards />
          <h2 className='header__title'>Zapłać teraz</h2>
          <p className='header__text'>Wybierz formę płatności z listy</p>
        </header>

        <Price />

        <div className='payment__list'>
          {status === STATUS.succeed && <div className='payment__element --none'>
            <GPay config={data} />
          </div>}

          <div
            className='payment__element'
            onClick={() => push(`${url}/blik`)}>
            <img src={blik} alt='blik' />
          </div>

          <div className='payment__element --small'
            onClick={() => push(`${url}/banks`)}>
            <img src={`${serverUrl}img/blue_media.png`} alt='bluemedia' />
            <span>Przelew błyskawiczny</span>
          </div>

          {type !== FORM_TYPE.homeDelivery && <>
            {/* <div
              className='payment__element'
              onClick={() => openDetails(false)}>
              <Waiter />
              <span>Płacę u kelnera</span>
            </div> */}

            <div
              className='payment__element'
              onClick={() => openDetails(true)}>
              <CashRegister />
              <span>Płacę przy kasie</span>
            </div>
          </>}
        </div>

        <TextBtn
          type={TEXT_BTN_TYPE.unfilled}
          desc='Wróć'
          clickAction={() => window.history.state ? goBack() : push('/')} />
      </div>
    </div>
  )
}

export default Payment