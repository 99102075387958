import { useEffect, useRef, useState } from 'react'
import CloseBtn from '../../buttons/CloseBtn'
import { STATUS } from '../../../rest/status'
import { get } from '../../../rest/request'
import { terms } from '../../../rest/urls'
import Loading from '../../Loading'
import './style.scss'

const FetchPopup = ({ url = terms, closeAction }) => {
  const [status, setStatus] = useState(STATUS.idle)
  const [data, setData] = useState('')
  const ref = useRef(null)

  useEffect(() => {
    setStatus(STATUS.pennding);
    (async () => {
      try {
        const response = await get(url)
        setData(response)
        setStatus(STATUS.succeed)

      } catch (err) {
        setStatus(STATUS.failed)
      }
    })()
  }, [url])

  useEffect(() => {
    const escDetect = e => e.keyCode === 27 && closeAction()
    document.body.style.overflow = 'hidden'
    document.addEventListener('keydown', escDetect, false)

    return () => {
      document.body.style.overflow = 'auto'
      document.removeEventListener('keydown', escDetect, false)
    }
  }, [closeAction])

  useEffect(() => {
    if (ref && status === STATUS.succeed) ref.current.innerHTML = data
  }, [status, data])

  return (
    <div className='popup--fetch'>
      <div className='popup__interior'>
        {status === STATUS.failed ? <div className='popup__error'>Brak wyników</div>
          : status === STATUS.succeed ? <div ref={ref} />
            : <Loading middleOfPage={false} />}
      </div>

      <div className='popup__button'>
        <CloseBtn closeAction={closeAction} />
      </div>
    </div>
  )
}

export default FetchPopup