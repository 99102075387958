const createCookie = (cookieName, cookieValue) => {
  const name = encodeURIComponent(cookieName)
  const value = encodeURIComponent(cookieValue)
  const oneYear = new Date(Date.now() + 31536000000).toUTCString() 

  document.cookie = `${name}=${value}; expires=${oneYear};`
}

const getCookie = cookieName => {
  if (document.cookie !== '') {
    const cookies = document.cookie.split(/; */);

    for (let i = 0; i < cookies.length; i++) {
      const cookiePart = cookies[i].split('=')
      const name = cookiePart[0]
      const value = cookiePart[1]

      if (name === decodeURIComponent(cookieName))
        return decodeURIComponent(value)
    }
  }
  return ''
}

export { createCookie, getCookie }