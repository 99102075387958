import { selectedTheme, selectedMainColor, selectedConfig } from './conteiners/Settings/settingsSlice'
import CookiePopup from './components/popups/CookiesPopup'
import OneBtnPopup from './components/popups/OneBtnPopup'
import Announcements from './conteiners/Announcements'
import React, { useState, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getCookie } from './utils/cookies'
//import Desktop from './layouts/Desktop'
import Mobile from './layouts/Mobile'
import './Themes.css'

function App() {
  const theme = useSelector(selectedTheme)
  const config = useSelector(selectedConfig)
  const mainColor = useSelector(selectedMainColor)

  const [popup, setPopup] = useState(false)
  const [consent, setConsent] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)

  const showPopup = () => setPopup(!popup)

  useEffect(() => {
    updateVH()
    updateConset()
  }, [])

  useEffect(() => {
    const handleWindowResize = () => {
      updateVH()
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [width])

  useEffect(() => {
    if (process.env.REACT_APP_CHRISTMAS_STORE === 'true' && config.christmas_first_page_show === '1') setPopup(true)
  }, [config])

  const updateVH = () => {
    let vh = window.innerHeight * 0.01
    document.body.style.setProperty('--vh', `${vh}px`)
  }

  const updateConset = () => setConsent(!getCookie('CONSENT').includes('YES'))

  return (
    <div className={`App --${theme} --${mainColor}`}>
      {popup && <OneBtnPopup
        title='Uwaga'
        description={<div dangerouslySetInnerHTML={{ __html: config.christmas_first_page }} />}
        btnText='OK'
        btnAction={showPopup}
        closeAction={showPopup} />}

      <BrowserRouter>
        {/* {width < 900 ? <Mobile /> : <Desktop />} */}
        <Mobile />
        <Announcements />
        {consent && <CookiePopup updateConsent={updateConset} />}
      </BrowserRouter>
    </div>
  )
}

export default App
