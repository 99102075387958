import TipListElement from './TipListElement'
import TextBtn from '../../buttons/TextBtn'
import { useState } from 'react'
import Popup from '../Popup'
import './style.scss'

const TipPopup = ({ data, closeAction, sendTips }) => {
  const { header = '', definitions = [], basketPrice = 0, tips: previovsTips = {} } = data
  const [tips, setTips] = useState({ ...previovsTips })
  
  const price = (() => {
    const tipsPrice = Object.entries(tips).reduce((r, [key, value]) => {
      r += value > 0 && value < 1 ? basketPrice * value : value
      return r
    }, 0)
    return tipsPrice > 0 ? `${(tipsPrice).toFixed(2)}zł` : ''
  })()

  return (
    <Popup closeAction={closeAction}>
      <div className='popup__main--tip'>
        <div className='popup__title'>Daj napiwek</div>
        <div className='popup__text'>{header ? header : `Kwota rachunku wyniosła: ${basketPrice.toFixed(2)} zł`}</div>
        <div className='popup__list'>
          {definitions.map(({ id, name, options }) => <TipListElement
            key={id}
            id={id}
            value={tips[id] ? tips[id] : 0}
            text={name}
            options={options}
            sendData={tip => setTips({
              ...tips,
              [id]: tip
            })} />)}
        </div>

        <TextBtn
          desc={`Prześlij teraz ${price}`}
          clickAction={() => price && sendTips(tips)} />
      </div>
    </Popup>
  )
}

export default TipPopup