import CloseBtn from '../../buttons/CloseBtn'
import { useEffect } from 'react'
import './style.scss'

const Popup = ({ children, isCloseBtn = true, closeBtnDesc = 'Zamknij', closeAction }) => {
  useEffect(() => {
    const escDetect = e => e.keyCode === 27 && isCloseBtn && closeAction()
    document.body.style.overflow = 'hidden'
    document.addEventListener('keydown', escDetect, false)

    return () => {
      document.body.style.overflow = 'auto'
      document.removeEventListener('keydown', escDetect, false)
    }
  }, [isCloseBtn, closeAction])

  return (
    <div className='popup'>
      <div className='popup__interior'>
        {children}
      </div>

      {isCloseBtn && <CloseBtn
        desc={closeBtnDesc}
        closeAction={closeAction} />}
    </div>
  )
}

export default Popup