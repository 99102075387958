import TextBtn from '../../buttons/TextBtn'
import Calendar from 'react-calendar'
import { useState, useEffect } from 'react'

const MONTH = 2592000000

const CalendarPopup = ({ value, options, change, close }) => {
  const { min_date = '', max_date = '', closed_weekdays = [], disabled_dates = [] } = options
  const [date, setDate] = useState(value)

  const disabledDatesValues = Object.values(disabled_dates)
  const closedWeekdaysValues = Object.values(closed_weekdays)

  useEffect(() => {
    const escDetect = e => e.keyCode === 27 && close()
    document.body.style.overflow = 'hidden'
    document.addEventListener('keydown', escDetect, false)

    return () => {
      document.body.style.overflow = 'auto'
      document.removeEventListener('keydown', escDetect, false)
    }
  }, [close])

  const dateToString = d => {
    const year = d.getFullYear()
    const month = d.getMonth() + 1
    const day = d.getDate()

    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`
  }

  const tileDisabled = ({ date }) => {
    if (closedWeekdaysValues.length > 0) {
      const dateDay = date.getDay() || 7
      const isClosedWeekday = closedWeekdaysValues.find(e => dateDay === e)
      if (isClosedWeekday) return true
    }

    if (disabledDatesValues.length > 0) {
      const dateString = dateToString(date)
      const isDisableDate = disabledDatesValues.find(e => dateString === e)
      if (isDisableDate) return true
    }

    return false
  }

  return (
    <div
      className='popup__calendar'
      onClick={close}>

      <div
        className='popup__interior'
        onClick={e => e.stopPropagation()}>

        <div className='popup__shadow --bottom' />
        <div className='popup__shadow' />

        <div className='popup__main'>
          <div>
            <Calendar
              minDetail='year'
              value={date ? new Date(date) : undefined}
              minDate={new Date(min_date || Date.now())}
              maxDate={new Date(max_date || (Date.now() + (2 * MONTH)))}
              showNeighboringMonth={false}
              tileDisabled={tileDisabled}
              onChange={d => setDate(dateToString(d))}
            />

            <div style={!date ? { opacity: 0.5 } : {}}>
              <TextBtn
                desc='Zapisz zmiany'
                clickAction={() => {
                  if (date) {
                    change(date)
                    close()
                  }
                }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendarPopup