const Star = ({ filled = false, clickHandler }) => (
  <div style={{ display: 'inline-block', margin: '.3em' }} onClick={clickHandler} >
    <svg xmlns="http://www.w3.org/2000/svg" width="22.548" height="22.544" viewBox="0 0 22.548 22.544">
      <g id="star" transform="translate(0.768 0.75)">
        <path id="star-2" data-name="star" d="M20.956,8.435a1.126,1.126,0,0,0-.96-.8L13.93,7.06,11.533,1.2a1.1,1.1,0,0,0-2.055,0L7.081,7.06l-6.066.575a1.13,1.13,0,0,0-.96.8A1.2,1.2,0,0,0,.38,9.674l4.585,4.2L3.613,20.083a1.191,1.191,0,0,0,.434,1.2,1.076,1.076,0,0,0,1.227.057l5.23-3.263,5.229,3.263a1.078,1.078,0,0,0,1.228-.057,1.191,1.191,0,0,0,.434-1.2l-1.352-6.213,4.585-4.2a1.2,1.2,0,0,0,.326-1.239Zm-10.35,9.581" transform="translate(0 -0.496)" fill={filled ? 'var(--main-color)' : 'none'} stroke="var(--main-color)" strokeWidth="1.5" />
      </g>
    </svg>
  </div>
)


export default Star