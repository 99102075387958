import { ReactSVG } from "react-svg"

const FoodHeader = ({ data: { name, icon, description: desc }, backBtn = false }) => {
  return (
    <div className='food__header'>
      {backBtn}
      <ReactSVG src={icon} />
      <div className='food__title'>{name}</div>
      <div className='food__line'></div>
      <div className='food__greeting'>{desc}</div>
    </div>
  )
}

export default FoodHeader