import { useHistory, useParams } from 'react-router'
import { christmasorder } from '../../rest/urls'
import Loading from '../../components/Loading'
import { post } from '../../rest/request'
import { useEffect } from 'react'

const OrderStatus = () => {
  let { replace } = useHistory()
  let { code } = useParams()

  useEffect(() => {
    (async () => {
      try {
        const response = await post(`${christmasorder}/${code}`)
        const { order_status: orderStatus } = response

        replace(orderStatus ? '/orders' : '/')
      } catch (err) { replace('/') }
    })()
  }, [code, replace])

  return (
    <div>
      <Loading />
    </div>
  )
}

export default OrderStatus