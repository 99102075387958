import { ReactComponent as ShoppingBag } from '../../../assets/icons/shoppingBag.svg'
import { ReactComponent as DiningRoom } from '../../../assets/icons/diningRoom.svg'
import { ReactComponent as Delivery } from '../../../assets/icons/delivery.svg'
import { ReactComponent as Clock } from '../../../assets/icons/clock.svg'
import { ReactComponent as Cupon } from '../../../assets/icons/cupon.svg'
import TextBtn, { TEXT_BTN_TYPE } from '../../../components/buttons/TextBtn'
import { selectedData, updateData, resetCompanyData } from '../formSlice'
import { cart as cartUrl, paymentCancel } from '../../../rest/urls'
import { selectBasketToRequest } from '../../Basket/basketSlice'
import OneBtnPopup from '../../../components/popups/OneBtnPopup'
import TimePicker from '../../../components/selects/TimePicker'
import RulesAgreement from '../../../components/RulesAgreement'
import Checkbox from '../../../components/inputs/Checkbox'
import NipInput from '../../../components/inputs/NipInput'
import Comments from '../../../components/inputs/Comments'
import { selectedTable } from '../../Scanner/tableSlice'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../components/inputs/Input'
import * as regex from '../../../utils/regex'
import { post } from '../../../rest/request'
import { SCANNER_TYPE } from '../../Scanner'
import { useHistory } from 'react-router'
import { useState } from 'react'
import FormHeader from './header'
import { FORM_TYPE } from '../'
import './style.scss'


const FinalizationForm = ({ type }) => {

  const { cart, tips } = useSelector(selectBasketToRequest)
  const tableNumber = useSelector(selectedTable)
  const data = useSelector(selectedData)
  const dispatch = useDispatch()

  const [errorPopupVisible, setErrorPopupVisibility] = useState(false)
  const [cardPopupVisible, setCardPopupVisibility] = useState(false)
  const [restCompanyData, showRestCompanyData] = useState(data.vat)
  const [warning, setWarning] = useState({
    phone: false,
    time: false,
    mail: false,
    address: false,
    town: false,
    companyNip: false,
    notificationsAgreement: false,
    rulesAgreement: false,
  })

  let { push, goBack } = useHistory()
  let refs = []

  const showErrorPopup = () => setErrorPopupVisibility(!errorPopupVisible)
  const showCardPopup = () => setCardPopupVisibility(!cardPopupVisible)
  const openPayment = () => push(`/finalization/payment?type=${type}`)

  const changeHandler = e => {
    if (e) {
      const { name, value, checked, type } = e.target
      const newValue = type === 'checkbox' ? checked : value

      dispatch(updateData({ name, value: newValue }))

      if ((name === 'phone' && value.match(regex.phone)) ||
        (name === 'mail' && value.match(regex.mail)) ||
        (name === 'address' && value.length > 1) ||
        (name === 'town' && value.length > 1) ||
        (name === 'time' && value.length > 1) ||
        (name === 'notificationsAgreement' || name === 'rulesAgreement') ||
        (data.vat && name === 'companyNip' && value.match(regex.nip)))
        setWarning({
          ...warning,
          [name]: false,
        })
    }
  }

  const blurHandler = e => {
    if (e) {
      const { name, value } = e.target

      if ((name === 'phone' && !value.match(regex.phone)) ||
        (name === 'mail' && !value.match(regex.mail)) ||
        (name === 'address' && value.length < 2) ||
        (name === 'town' && value.length < 2) ||
        (data.vat && name === 'companyNip' && !value.match(regex.nip)))
        setWarning({
          ...warning,
          [name]: true,
        })
    }
  }

  const enterClicked = ref => {
    const index = refs.findIndex(r => Object.is(r, ref))
    if (index !== undefined && refs[index + 1]) refs[index + 1].focus()
  }

  const submitForm = async () => {
    const { phone, mail, address, town, time, vat, comments, cardNumber, companyNip, companyName, companyStreet, companyCode, companyCity, notificationsAgreement, rulesAgreement } = data
    const phoneOK = phone.match(regex.phone) !== null
    const mailOK = mail.match(regex.mail) !== null
    const nipOK = companyNip.match(regex.nip) !== null

    const body = {
      cart,
      tips,
      common: {
        phone: phone.replace(/[-| ]/g, ''),
        mail,
        cardNumber,
        comments,
        rules_agreement: rulesAgreement,
        notifications_agreement: notificationsAgreement,
      }
    }

    if (vat) body.invoice = { companyNip, companyName, companyStreet, companyCode, companyCity }

    switch (type) {
      case FORM_TYPE.takeawayOrder:
        if (phoneOK && time && notificationsAgreement && rulesAgreement && (!vat || (vat && nipOK))) {
          body.takeawayOrder = { time }
          sendForm(body)

        } else {
          setWarning({
            ...warning,
            phone: !phoneOK,
            companyNip: vat && !nipOK,
            time: time.length < 1,
            notificationsAgreement: !notificationsAgreement,
            rulesAgreement: !rulesAgreement,
          })
        }
        break

      case FORM_TYPE.homeDelivery:
        if (phoneOK && mailOK && address && town && notificationsAgreement && rulesAgreement && (!vat || (vat && nipOK))) {
          body.homeDelivery = { address, town }
          sendForm(body)

        } else {
          setWarning({
            ...warning,
            phone: !phoneOK,
            mail: !mailOK,
            companyNip: vat && !nipOK,
            address: address.length < 1,
            town: town.length < 1,
            notificationsAgreement: !notificationsAgreement,
            rulesAgreement: !rulesAgreement,
          })
        }
        break

      default:
        if (phoneOK && notificationsAgreement && rulesAgreement && (!vat || (vat && nipOK))) {
          if (tableNumber) body.orderToTable = { table: tableNumber }
          sendForm(body)

        } else {
          setWarning({
            ...warning,
            phone: !phoneOK,
            companyNip: vat && !nipOK,
            notificationsAgreement: !notificationsAgreement,
            rulesAgreement: !rulesAgreement,
          })
        }
    }
  }

  const sendForm = async body => {
    console.log('body', body)
    try {
      const response = await post(cartUrl, body)
      if (response.success) openPayment()
      else if (response.error_func === 'unlock_cart') showErrorPopup()
    } catch (err) { console.log(err) }
  }

  const header = (() => {
    switch (type) {
      case FORM_TYPE.takeawayOrder:
        return <FormHeader
          Icon={ShoppingBag}
          title='Zamawiam na wynos'
          subTitle='Uzupełnij poniższe dane aby złożyć zamówienie.' />

      case FORM_TYPE.homeDelivery:
        return <FormHeader
          Icon={Delivery}
          title='Dostawa do domu'
          subTitle='Uzupełnij poniższe dane abyśmy mogli dostarczyć twoje zamówienie.' />

      case FORM_TYPE.orderToTable:
        return <FormHeader
          Icon={DiningRoom}
          title={`Stolik nr.${tableNumber}`}
          subTitle='Zmień stolik'
          clickHandler={() => push(`/scan?type=${SCANNER_TYPE.table}`)} />

      default:
        return <FormHeader
          Icon={Clock}
          title='Uzupełnię później'
          subTitle='Po opłaceniu zamówienia pamiętaj aby uzupełnić numer stolika!' />
    }
  })()


  const cardPopup = <OneBtnPopup
    title='Jak założyć kartę klienta?'
    description='Zapytaj kelnera o kartę stałego klienta dzięki której zyskasz dostęp do niesamowitych okazji cenowych i nowości.'
    btnText='OK, zamknij'
    isCloseBtn={false}
    btnAction={showCardPopup} />

  const errorPopup = <OneBtnPopup
    title='Uwaga'
    description='Płatność została już rozpoczęta, czy chcesz ją anulować?'
    btnText='Tak'
    closeAction={showErrorPopup}
    btnAction={async () => {
      try {
        const response = await post(paymentCancel)
        if (response.unlocked) {
          showErrorPopup()
          submitForm()
        }
      }
      catch (err) { console.log('payment cancel error') }
    }} />

  return (
    <div className='finalization__form'>

      {cardPopupVisible && cardPopup}
      {errorPopupVisible && errorPopup}

      <div className='form__main'>
        {header}
        <Input
          ref={r => refs.push(r)}
          value={data.phone}
          warning={warning.phone}
          name='phone'
          type='tel'
          placeholder='Wpisz numer tel'
          warningMessage='Wpisz właściwy numer telefonu'
          enterClicked={enterClicked}
          changeHandler={e => {
            e.target.value = e.target.value.replace(/(\d{3})(\d+)/g, '$1 $2')
            changeHandler(e)
          }}
          blurHandler={blurHandler}
          restAttr={{ autoComplete: 'off' }} />

        {data.cardNumber ? <input
          className='form__input'
          value={data.cardNumber}
          disabled />
          : <button
            className='form__button'
            onClick={() => push(`/scan?type=${SCANNER_TYPE.barcode}`)}>
            <Cupon />
            <span>Zeskanuj kartę stałego klienta</span>
          </button>}

        {type === FORM_TYPE.homeDelivery && <>
          <Input
            ref={r => refs.push(r)}
            value={data.mail}
            warning={warning.mail}
            name='mail'
            type='email'
            placeholder='Wpisz adres e-mail'
            warningMessage='Wpisz właściwy adres e-mail'
            enterClicked={enterClicked}
            changeHandler={changeHandler}
            blurHandler={blurHandler} />

          <Input
            ref={r => refs.push(r)}
            value={data.address}
            warning={warning.address}
            name='address'
            placeholder='Wpisz ulicę i numer'
            warningMessage='Wpisz właściwą ulicę i numer'
            enterClicked={enterClicked}
            changeHandler={changeHandler}
            blurHandler={blurHandler}
            restAttr={{ minLength: '1', maxLength: '100' }} />

          <Input
            ref={r => refs.push(r)}
            value={data.town}
            warning={warning.town}
            name='town'
            placeholder='Wpisz miejscowość'
            warningMessage='Wpisz właściwą miejscowość'
            enterClicked={enterClicked}
            changeHandler={changeHandler}
            blurHandler={blurHandler}
            restAttr={{ minLength: '1', maxLength: '100' }} />
        </>}

        <Comments
          value={data.comments}
          changeHandler={changeHandler} />

        {type === FORM_TYPE.takeawayOrder && <>
          <TimePicker
            selected={data.time}
            warning={warning.time}
            warningMessage='Wybierz godzine odbioru'
            changeHandler={changeHandler} />
        </>}

        <div className='form__company'>
          <Checkbox
            name='vat'
            label='Chcę otrzymać fakturę VAT'
            checked={data.vat}
            changeHandler={(e) => {
              const { name, checked } = e.target

              dispatch(updateData({ name, value: checked }))

              if (!checked) {
                dispatch(resetCompanyData())
                showRestCompanyData(false)
                setWarning({
                  ...warning,
                  companyNip: false
                })
              }
            }} />

          {data.vat && <>
            <NipInput
              ref={r => refs.push(r)}
              value={data.companyNip}
              warning={warning.companyNip}
              showRestCompanyData={() => showRestCompanyData(true)}
              setFocus={focus => {
                const found = refs.find(({ name }) => name === 'companyNip')
                if (found) focus ? found.focus() : found.blur()
              }}
              enterClicked={enterClicked}
              changeHandler={changeHandler}
              blurHandler={blurHandler} />

            {restCompanyData && <>
              <Input
                ref={r => refs.push(r)}
                value={data.companyName}
                name='companyName'
                placeholder='Pełna nazwa firmy'
                enterClicked={enterClicked}
                changeHandler={changeHandler}
                restAttr={{ maxLength: '200' }} />

              <Input
                ref={r => refs.push(r)}
                value={data.companyStreet}
                name='companyStreet'
                placeholder='Ulica firmy'
                enterClicked={enterClicked}
                changeHandler={changeHandler}
                restAttr={{ maxLength: '200' }} />

              <Input
                ref={r => refs.push(r)}
                value={data.companyCode}
                name='companyCode'
                type='tel'
                placeholder='Kod pocztowy firmy'
                enterClicked={enterClicked}
                changeHandler={e => {
                  e.target.value = e.target.value.replace(/^(\d{2})(\d+)$/, '$1-$2')
                  changeHandler(e)
                }} />

              <Input
                ref={r => refs.push(r)}
                value={data.companyCity}
                name='companyCity'
                placeholder='Adres firmy'
                enterClicked={enterClicked}
                changeHandler={changeHandler}
                restAttr={{ maxLength: '200' }} />
            </>}
          </>}

          <hr />
        </div>

        <Checkbox
          name='notificationsAgreement'
          label='* Powiadomienia dot. zamówienia'
          warning={warning.notificationsAgreement}
          checked={data.notificationsAgreement}
          changeHandler={changeHandler} />

        <RulesAgreement
          warning={warning.rulesAgreement}
          checked={data.rulesAgreement}
          changeHandler={changeHandler} />

        <div className='form__buttons'>
          <TextBtn
            desc='Przejdź do płatności'
            clickAction={submitForm} />
          <TextBtn
            desc='Lub wróć'
            type={TEXT_BTN_TYPE.unfilled}
            clickAction={() => window.history.state ? goBack() : push('/')} />

          <hr />
          <button
            className='form__button'
            onClick={showCardPopup}>
            <Cupon />
            <span>Załóż kartę stałego klienta</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default FinalizationForm