import { ReactComponent as QrCode } from '../../assets/icons/qrCode.svg'
import { ReactComponent as Clock } from '../../assets/icons/clock.svg'
import { ReactComponent as Delivery } from '../../assets/icons/delivery.svg'
import { ReactComponent as Friend } from '../../assets/icons/friend.svg'
import TwoBtnPopup from '../../components/popups/TwoBtnPopup'
import { selectBasketPrice } from '../Basket/basketSlice'
import { useHistory, useRouteMatch } from 'react-router'
import { selectedTable } from '../Scanner/tableSlice'
import { useSelector } from 'react-redux'
import { SCANNER_TYPE } from '../Scanner'
import { useState } from 'react'
import './style.scss'

export const FORM_TYPE = {
  sendToFriend: 'sendtofriend',
  completeLater: 'completelater',
  homeDelivery: 'homedelivery',
  takeawayOrder: 'takeawayorder',
  orderToTable: 'ordertotable',
  chrisrtmasOrder: 'chrisrtmasorder',
}

const Finalization = () => {
  const [variantPopupVisibile, setVariantPopupVisibility] = useState(false)
  const [warningPopupVisibile, setWarningPopupVisibility] = useState(false)
  const basketPrice = useSelector(selectBasketPrice)
  const tableNumber = useSelector(selectedTable)
  const minOrderPrice = 10

  let { url } = useRouteMatch()
  let { push } = useHistory()

  const showVariantPopup = () => setVariantPopupVisibility(!variantPopupVisibile)
  const showWarningPopup = () => setWarningPopupVisibility(!warningPopupVisibile)
  const openForm = type => push(`${url}/form?type=${type}`)

  const deliveryOrTakeawayPopup = <TwoBtnPopup
    title='Dowóz czy na wynos?'
    description='Określ sposób zamówienia. Wybierz dostawę do domu, lub zabierz danie na wynos.'
    firstBtnText='Zamawiam na wynos'
    firstBtnAction={() => {
      showVariantPopup()
      openForm(FORM_TYPE.takeawayOrder)
    }}
    secondBtnText='Chcę dostawę do domu'
    secondBtnReduced={false}
    secondBtnAction={() => {
      showVariantPopup()
      basketPrice >= minOrderPrice ? openForm(FORM_TYPE.homeDelivery) : showWarningPopup()
    }}
    isCloseBtn
    closeAction={showVariantPopup}
  />

  const deliveryWarningPopup = <TwoBtnPopup
    title='Uwaga!'
    description={`Minimalna wartość zamówienia dla dostawy to ${minOrderPrice}zł`}
    firstBtnText='Dodaj coś do koszyka'
    firstBtnAction={() => {
      showWarningPopup()
      push('/categories')
    }}
    secondBtnText='Wybierz inną opcje'
    secondBtnReduced
    secondBtnAction={showWarningPopup}
    isCloseBtn={false}
  />

  return (
    <div className='finalization'>

      {variantPopupVisibile && deliveryOrTakeawayPopup}
      {warningPopupVisibile && deliveryWarningPopup}

      <div className='finalization__title'>Czas na finalizację</div>
      <div className='finalization__title--secondary'>Jak chcesz zrealizować zamówienie?</div>

      <div className='finalization__tiles'>
        {tableNumber && <div
          className='tiles__element --selected'
          onClick={() => openForm(FORM_TYPE.orderToTable)}>
          <div className='tiles__title'>{tableNumber}</div>
          <div className='tiles__description'>Zostaję przy<br />tym stoliku</div>
        </div>}

        <div
          className='tiles__element'
          onClick={() => push(`/scan?type=${SCANNER_TYPE.table}`)}>
          <QrCode />
          <div className='tiles__description'>
            <span>Zeskanuj</span>
            <div>{tableNumber ? 'inny stolik' : 'swój stolik'}</div>
          </div>
        </div>

        {!tableNumber && <div
          className='tiles__element'
          onClick={() => openForm(FORM_TYPE.completeLater)}>
          <Clock />
          <div className='tiles__description'>Uzupełnię<br />później</div>
        </div>}

        <div
          className='tiles__element --muted'>
          {/* onClick={() => showVariantPopup()}> */}
          <Delivery />
          <div className='tiles__description'>Dowóz<br />lub wynos</div>
        </div>

        <div
          className='tiles__element --muted'>
          {/* onClick={() => openForm(FORM_TYPE.sendToFriend)}> */}
          <Friend />
          <div className='tiles__description'>Podeślij znajomemu<br />do opłacenia</div>
        </div>
      </div>
    </div>
  )
}

export default Finalization