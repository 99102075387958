export const getDimensions = element => {
  const { height } = element.getBoundingClientRect()
  const offsetTop = element.offsetTop
  const offsetBottom = offsetTop + height

  return { height, offsetTop, offsetBottom }
}

export const scrollToTop = (element, displacement = 0, behavior = 'smooth') => {
  const top = element.getBoundingClientRect().top + window.pageYOffset + displacement
  window.scrollTo({ 
    top: top, 
    behavior
  })
}