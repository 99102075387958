import { ReactComponent as ShoppingBag } from '../../../assets/icons/shoppingBag.svg'
import { cart as cartUrl, christmasorders, paymentCancel } from '../../../rest/urls'
import { ReactComponent as Cupon } from '../../../assets/icons/cupon.svg'
import TextBtn, { TEXT_BTN_TYPE } from '../../../components/buttons/TextBtn'
import { selectBasketToRequest, resetBasket } from '../../Basket/basketSlice'
import OneBtnPopup from '../../../components/popups/OneBtnPopup'
import TimePicker from '../../../components/selects/TimePicker'
import RulesAgreement from '../../../components/RulesAgreement'
import DateInput from '../../../components/inputs/DateInput'
import Comments from '../../../components/inputs/Comments'
import { selectedData, updateData } from '../formSlice'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../components/inputs/Input'
import FormHeader from '../FinalizationForm/header'
import Loading from '../../../components/Loading'
import { STATUS } from '../../../rest/status'
import * as regex from '../../../utils/regex'
import { post } from '../../../rest/request'
import { SCANNER_TYPE } from '../../Scanner'
import { useHistory } from 'react-router'
import { useEffect, useState } from 'react'
import '../FinalizationForm/style.scss'

const OrderForm = () => {

  const { name, surname, phone, comments, cardNumber, rulesAgreement } = useSelector(selectedData)
  const { cart } = useSelector(selectBasketToRequest)
  const dispatch = useDispatch()

  const [popup, setPopup] = useState('')
  const [error, setError] = useState([])
  const [hours, setHours] = useState([])
  const [calendar, setCalendar] = useState({})
  const [blockSend, setBlockSend] = useState(false)
  const [status, setStatus] = useState(STATUS.succeed)
  const [form, setForm] = useState({
    date: '',
    time: '',
  })
  const [warning, setWarning] = useState({
    name: false,
    surname: false,
    phone: false,
    date: false,
    time: false,
    rulesAgreement: false,
  })

  const { date, time } = form

  let { push, replace, goBack } = useHistory()
  let refs = []

  const hidePopup = () => setPopup('')

  useEffect(() => {
    setStatus(STATUS.pennding);

    (async () => {
      try {
        const response = await post(cartUrl, { cart })
        const { success, error_func, errors, calendar } = response

        if (success) {
          if (calendar) setCalendar(calendar)
          else {
            const date = new Date(Date.now() + 86400000)
            setCalendar({ min_date: date.getTime() })
          }
          setStatus(STATUS.succeed)
        }
        else if (error_func === 'unlock_cart') setPopup('paymentError')
        else if (errors && Array.isArray(errors)) {
          setPopup('inaccessibleError')
          setError(errors)
        }

      } catch (err) {
        console.log(err)
        setStatus(STATUS.failed)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!date) return
    const body = { action: 'CheckPickupTime', date };

    (async () => {
      try {
        const response = await post(christmasorders, body)
        const { wynik, hours } = response

        if (wynik === 'ok' && hours) setHours(hours)

      } catch (err) {
        console.log(err)
        setStatus(STATUS.failed)
      }
    })()
  }, [date])

  const changeHandler = e => {
    if (!e) return
    const { name, value: v, checked, type } = e.target
    const value = type === 'checkbox' ? checked : v

    if (name === 'date' || name === 'time') setForm({ ...form, [name]: value })
    else dispatch(updateData({ name, value }))

    if (((name === 'name' || name === 'surname' || name === 'time' || name === 'date' || name === 'rulesAgreement') && value) ||
      (name === 'phone' && value.match(regex.phone)))
      setWarning({
        ...warning,
        [name]: false,
      })
  }

  const blurHandler = e => {
    if (!e) return
    const { name, value } = e.target

    if ((name === 'phone' && !value.match(regex.phone)) ||
      ((name === 'name' || name === 'surmane') && value.length < 2))
      setWarning({
        ...warning,
        [name]: true,
      })
  }

  const enterClicked = ref => {
    const index = refs.findIndex(r => Object.is(r, ref))
    if (index !== undefined && refs[index + 1]) refs[index + 1].focus()
  }

  const submitForm = async () => {
    if (blockSend) return
    const phoneOK = phone.match(regex.phone) !== null

    if (name && surname && phoneOK && time && date && rulesAgreement) {
      setBlockSend(true)
      const body = {
        action: 'MakeOrder',
        order_data: {
          accept_shoprules: rulesAgreement,
          tel_number: phone.replace(/[-| ]/g, ''),
          name,
          surname,
          client_card: cardNumber,
          uwagi: comments,
          picked_date: date,
          picked_time: time,
        },
        cart
      }

      try {
        const response = await post(christmasorders, body)
        const { wynik, clear_cart, error_func = '' } = response

        if (wynik === 'ok') {
          if (clear_cart) dispatch(resetBasket())
          replace('/orders')
        }
        else if (error_func === 'unlock_cart') setPopup('error')
        setBlockSend(false)

      } catch (err) { 
        console.log(err)
        setBlockSend(false)
      }

    } else {
      setWarning({
        name: !name,
        surname: !surname,
        phone: !phoneOK,
        date: !date,
        time: !time,
        rulesAgreement: !rulesAgreement,
      })
    }
  }

  const paymentPopup = <OneBtnPopup
    title='Uwaga'
    description='Płatność została już rozpoczęta, czy chcesz ją anulować?'
    btnText='Tak'
    closeAction={hidePopup}
    btnAction={async () => {
      try {
        const response = await post(paymentCancel)
        if (response.unlocked) {
          hidePopup()
          submitForm()
        }
      }
      catch (err) { console.log('payment cancel error') }
    }} />

  const inaccessiblePopup = <OneBtnPopup
    title='Uwaga'
    description={<div>
      <div>Niestety nie możemy zrealizaować Twojego zamówienia, z powodu:</div>
      <div>{error.map((e, i) => <li key={i}>{e}</li>)}</div>
    </div>}
    btnText='Popraw zamówienie'
    isCloseBtn={false}
    btnAction={goBack} />

  return (
    <div className='finalization__form'>
      {popup && (popup === 'paymentError' ? paymentPopup : inaccessiblePopup)}

      <div className='form__main'>
        <FormHeader
          Icon={ShoppingBag}
          title='Zamawiam na wynos'
          subTitle='Uzupełnij poniższe dane aby złożyć zamówienie.' />

        {status === STATUS.succeed ? <>
          <Input
            ref={r => refs.push(r)}
            value={name}
            warning={warning.name}
            name='name'
            placeholder='Wpisz imię'
            warningMessage='Wpisz właściwe imię'
            enterClicked={enterClicked}
            changeHandler={changeHandler}
            blurHandler={blurHandler}
            restAttr={{ minLength: '1', maxLength: '100' }} />

          <Input
            ref={r => refs.push(r)}
            value={surname}
            warning={warning.surname}
            name='surname'
            placeholder='Wpisz nazwisko'
            warningMessage='Wpisz właściwe nazwisko'
            enterClicked={enterClicked}
            changeHandler={changeHandler}
            blurHandler={blurHandler}
            restAttr={{ minLength: '1', maxLength: '100' }} />

          <Input
            ref={r => refs.push(r)}
            value={phone}
            warning={warning.phone}
            name='phone'
            type='tel'
            placeholder='Wpisz numer tel'
            warningMessage='Wpisz właściwy numer telefonu'
            enterClicked={enterClicked}
            changeHandler={e => {
              e.target.value = e.target.value.replace(/(\d{3})(\d+)/g, '$1 $2')
              changeHandler(e)
            }}
            blurHandler={blurHandler}
            restAttr={{ autoComplete: 'off' }} />

          <DateInput
            value={date}
            warning={warning.date}
            options={calendar}
            placeholder='Data odbioru'
            warningMessage='Podaj datę odbioru'
            changeHandler={changeHandler} />

          {hours.length > 0 && <TimePicker
            selected={time}
            warning={warning.time}
            hours={hours}
            warningMessage='Wybierz godzine odbioru'
            changeHandler={changeHandler} />}

          {cardNumber ? <input
            className='form__input'
            value={cardNumber}
            disabled />
            : <button
              className='form__button'
              onClick={() => push(`/scan?type=${SCANNER_TYPE.barcode}`)}>
              <Cupon />
              <span>Zeskanuj kartę stałego klienta</span>
            </button>}

          <Comments
            value={comments}
            changeHandler={changeHandler} />

          <RulesAgreement
            warning={warning.rulesAgreement}
            checked={rulesAgreement}
            changeHandler={changeHandler} />
        </> : status === STATUS.failed ? <div className='popup__error'>Coś poszło nie tak</div> : <Loading middleOfPage={false} />}

        <div className='form__buttons'>
          {status === STATUS.succeed && <TextBtn
            desc='Przejdź do płatności'
            clickAction={submitForm} />}

          <TextBtn
            desc='Lub wróć'
            type={TEXT_BTN_TYPE.unfilled}
            clickAction={() => window.history.state ? goBack() : push('/')} />
        </div>
      </div>
    </div>
  )
}

export default OrderForm