import { deleteFood, selectBasketListByIndex, selectBasketListLastIndex, updateAmountAndAdditionalOfFood, updateCommentOfFood } from '../../../conteiners/Basket/basketSlice'
import { ReactComponent as BasketIcon } from '../../../assets/icons/shoppingBasket.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg'
import { useDispatch, useSelector } from 'react-redux'
import ComposeFoodPopup from '../ComposeFoodPopup'
import TextBtn from '../../buttons/TextBtn'
import FoodCounter from '../../FoodCounter'
import { useHistory } from 'react-router'
import TwoBtnPopup from '../TwoBtnPopup'
import OrderPopup from '../OrderPopup'
import { Fragment, useState } from 'react'
import Popup from '../Popup'
import './style.scss'

const BasketDetailsPopup = ({ index = -1, closeAction }) => {
  const [popup, setPopup] = useState('')
  const lastIndex = useSelector(selectBasketListLastIndex)
  const basketIdx = index > -1 ? index : lastIndex
  const data = useSelector(state => selectBasketListByIndex(state, basketIdx))

  const { id, name = '', EKR_ID = '', order_zpo = {}, additional = [], fields = [], comment = '', amount = 1, unitPrice, finalPrice } = data
  const areAdditional = additional.length > 0
  let { push, location: { pathname } } = useHistory()
  const dispatch = useDispatch()

  const hidePopup = () => setPopup('')

  const closeOrChangeComponent = path => {
    closeAction()
    const pathList = pathname.split('/')
    if (pathList[1] && pathList[1] !== path) push(`/${path}`)
  }

  const composePopup = <ComposeFoodPopup
    data={{ id, name, amount, price: unitPrice, finalPrice, additional }}
    closeAction={hidePopup}
    basketAction={(value, additional, price) => {
      hidePopup()
      if (value > 0) dispatch(updateAmountAndAdditionalOfFood({ basketIdx, value, additional, price }))
    }} />

  const selectionPopup = <TwoBtnPopup
    secondBtnAction={hidePopup}
    firstBtnAction={() => {
      dispatch(deleteFood({ basketIdx }))
      hidePopup()
      closeAction()
    }} />

  const orderPopup = <OrderPopup
    id={id}
    name={name}
    index={basketIdx}
    price={unitPrice}
    order={order_zpo}
    screen={EKR_ID}
    closeAction={hidePopup} />

  return (
    popup === 'compose' ? composePopup
      : popup === 'selection' ? selectionPopup
        : popup === 'order' ? orderPopup
          : <Popup isCloseBtn={false}>
            <div className='popup__main--basketdetails'>
              <BasketIcon />
              <div className={`popup__text ${areAdditional ? '--secondary' : ''}`}>Dodałeś produkt do koszyka!</div>
              <div className={`popup__title ${areAdditional ? '--nomargin' : ''}`}>{name}</div>

              {areAdditional ? <div className='popup__additional --food'>
                {additional.map(({ products }) => products.filter(e => e.amount).map(({ id, name, amount }) => {
                  return (
                    <div key={id} className='additional__item'>
                      <span>+</span>
                      <span>{amount} x {name}</span>
                    </div>)
                }))}
              </div> : (fields.length > 0 && order_zpo) ? <div className='popup__additional --order'>
                {fields
                  .filter(({ type }) => type === 'number' || type === 'select' || type === 'text')
                  .map(({ field_id, type, unit, options = [] }) => {
                    if (type === 'number') {
                      const slider = fields.find(({ type }) => type === 'slider')

                      return <div
                        key={field_id}
                        className='additional__item'>
                        {order_zpo[field_id] && <span>{order_zpo[field_id]} {unit}</span>}
                        {slider && <span> między {order_zpo[slider.field_id]?.min} a {order_zpo[slider.field_id]?.max} {slider?.unit}</span>}
                      </div>
                    }

                    return <div
                      key={field_id}
                      className='additional__item'>
                      {order_zpo[field_id] && (type === 'select' ? order_zpo[field_id].map(e => {
                        const num = Number(e)
                        const selected = options.find(({ value }) => value === num)
                        return <span key={e}>{selected?.name}</span>
                      }) : <div><p></p><span>{order_zpo[field_id]}</span></div>)}
                    </div>
                  })}
              </div> : false}

              {!EKR_ID && <>
                <div className='popup__text --secondary'>Ile sztuk chcesz zamówić?</div>
                <FoodCounter
                  amount={amount}
                  amountChanged={value => dispatch(updateAmountAndAdditionalOfFood({ basketIdx, value }))} />
              </>}

              <div className='popup__buttons'>
                {areAdditional && <div className='popup__group' onClick={() => setPopup('compose')}>
                  <EditIcon />
                  <div className='popup__title--secondary'>Edytuj zestaw</div>
                </div>}

                {EKR_ID && <div className='popup__group' onClick={() => setPopup('order')}>
                  <EditIcon />
                  <div className='popup__title--secondary'>Edytuj zamówienie</div>
                </div>}

                <div className='popup__group' onClick={() => setPopup('selection')}>
                  <TrashIcon />
                  <div className='popup__title--secondary'>Usuń z koszyka</div>
                </div>
              </div>

              {EKR_ID ? <div className='popup__additional --order'>
                <div className='additional__item'>Przybliżona kwota do zapłaty:</div>
                <div className='additional__item --calc'>
                  {fields.filter(({ type }) => type === 'hidden' || type === 'number' || type === 'slider')
                    .map(({ type, unit, field_id }, idx, arr) => {
                      if (unit === 'kg ' && order_zpo[field_id]) {
                        const num = type === 'slider' ? Number(((Number(order_zpo[field_id]?.min) + Number(order_zpo[field_id]?.max)) / 2).toFixed(2)) : order_zpo[field_id]
                        return <Fragment key={idx}>
                          <span>~{num}</span>
                          <sup> kg</sup>
                          <span> x </span>
                        </Fragment>

                      } else if (unit === 'szt' && (order_zpo[field_id] > 1 || arr.length === 1)) {
                        return <Fragment key={idx}>
                          <span>{order_zpo[field_id]}</span>
                          <sup> szt</sup>
                          <span> x </span>
                        </Fragment>
                      }
                      return false
                    })}
                  <span>{unitPrice}</span>
                  <sup> zł</sup>
                  <span> = {finalPrice.toFixed(2)}</span>
                  <sup> zł</sup>
                </div>
              </div> : <textarea
                value={comment}
                className='popup__input'
                placeholder='Dodaj uwagi do potrawy...'
                onChange={(e) => dispatch(updateCommentOfFood({ comment: e.target.value, basketIdx }))} />}

              <TextBtn
                desc='Kontynuuj zakupy'
                clickAction={() => closeOrChangeComponent('categories')} />
              <div
                className='popup__button'
                onClick={() => closeOrChangeComponent('basket')}>lub do kasy</div>
            </div>
          </Popup>
  )
}

export default BasketDetailsPopup