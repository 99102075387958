import { ReactComponent as Selector } from './rangeSelector.svg'

const RangeSelector = ({ idx, value, left, handleMove }) => {
  const onMouseDown = () => {
    let currentValue = value

    const onMouseMove = e => {
      currentValue = handleMove(e, idx, currentValue) || currentValue
    }

    const onMouseUp = () => {
      document.removeEventListener('mouseup', onMouseUp)
      document.removeEventListener('mousemove', onMouseMove)
    }

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
  }

  return (
    <div
      className='range__selector'
      style={{ left: `calc(${left(value)}% - 25px)` }}
      onTouchMove={e => handleMove(e, idx, value)}
      onMouseDown={onMouseDown}>

      <Selector />

      <div className='selector__desc'>
        <div>{Number(value.toFixed(2))}</div>
        <div>kg</div>
      </div>
    </div>
  )
}

export default RangeSelector