import { ReactComponent as CreditCards } from '../../../assets/icons/creditCards.svg'
import TextBtn, { TEXT_BTN_TYPE } from '../../../components/buttons/TextBtn'
import { speedUpDuration } from '../../Announcements/statusSlice'
import { banklist, payment } from '../../../rest/urls'
import { get, post } from '../../../rest/request'
import Loading from '../../../components/Loading'
import Price from '../../../components/Price'
import { STATUS } from '../../../rest/status'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import './style.scss'

const Banks = () => {
  const [status, setStatus] = useState(STATUS.idle)
  const [data, setData] = useState('')
  const dispatch = useDispatch()

  let { goBack, push } = useHistory()

  useEffect(() => {
    setStatus(STATUS.pennding);

    (async () => {
      try {
        const response = await get(banklist)
        console.log(response)
        setData(response)
        setStatus(STATUS.succeed)

      } catch (err) { setStatus(STATUS.failed) }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const openPayment = async (id, type) => {
    const body = { gatewayID: id, gatewayType: type }

    try {
      const response = await post(payment, body)
      console.log(response)

      if (response.error) throw new Error(response.statusText)
      if (response.typ === 'link' && response.redirect) {
        dispatch(speedUpDuration())
        window.location.replace(response.redirect)
      }

    } catch (err) { console.log(err) }
  }

  const elements = typeof data === 'object' && Object.entries(data)
    .map(([key, value]) => {
      const { id = 0, name = '', icon = '', is_blik = 0, type = '' } = value
      return <div key={id}
        className='bank'
        onClick={() => is_blik ? push('/finalization/payment/blik') : openPayment(id, type)}>
        <img src={icon} alt={name} />
      </div>
    })

  const error = <div className='list__error'>Chilowo nie jest możliwe<br />pobranie listy banków</div>

  return (
    <div className='banks'>
      <header className='banks__header'>
        <CreditCards />
        <h2 className='header__title'>Zapłać teraz</h2>
        <p className='header__text'>Wybierz Twój bank z listy</p>
      </header>

      <Price />

      <div className='banks__list'>
        {status === STATUS.failed ? error
          : status === STATUS.succeed ? elements
            : <Loading middleOfPage={false} />}
      </div>

      <TextBtn
        type={TEXT_BTN_TYPE.unfilled}
        desc='Wybierz inną płatność'
        clickAction={() => window.history.state ? goBack() : push('/')} />
    </div>
  )
}

export default Banks