import { checknumber, checktablecode } from '../../rest/urls'
import { useHistory, useParams } from 'react-router'
import { updateTable } from '../Scanner/tableSlice'
import Loading from '../../components/Loading'
import { post } from '../../rest/request'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

const TableStatus = ({ status = false }) => {
  const dispatch = useDispatch()
  let { replace } = useHistory()
  let { code } = useParams()

  useEffect(() => {
    const body = status ? { 'external': code } : { 'table_code': code }
    const url = status ? checknumber : checktablecode;

    (async () => {
      try {
        const response = await post(url, body)
        const { stolik: table, order_status: orderStatus } = response

        if (Number(table)) dispatch(updateTable(table))
        replace(table && (status || orderStatus) ? '/orders' : '/?scanned=true')
      }
      catch (err) { replace('/') }
    })()
  }, [code, status, dispatch, replace])

  return (
    <div>
      <Loading />
    </div>
  )
}

export default TableStatus