import { selectBasketPrice } from '../../Basket/basketSlice'
import GooglePayButton from '@google-pay/button-react'
import { post } from '../../../rest/request'
import { payment } from '../../../rest/urls'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './style.scss'

const GPay = ({ config: { acceptorId, authJwt, env, merchantId, merchantName, merchantOrigin, payment_provider } }) => {
  const [appColor, setAppColor] = useState('white')
  const basketPrice = useSelector(selectBasketPrice)

  useEffect(() => {
    const color = getComputedStyle(document.querySelector('.App')).getPropertyValue('--theme-bg')
    setAppColor(color.includes('fff') ? 'black' : 'white')
  }, [])

  const paymentRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [{
      type: 'CARD',
      parameters: {
        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        allowedCardNetworks: ['MASTERCARD', 'VISA'],
      },
      tokenizationSpecification: {
        type: 'PAYMENT_GATEWAY',
        parameters: {
          gateway: payment_provider,
          gatewayMerchantId: acceptorId
        },
      },
    }],
    merchantInfo: {
      merchantId,
      merchantOrigin,
      merchantName,
      authJwt
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: basketPrice,
      currencyCode: 'PLN',
      countryCode: 'PL',
    },
  }

  const sendResponse = async paymentResponse => {
    const body = { provider: 'google', paymentResponse }
    console.log('body', body)

    try {
      const response = await post(payment, body)
      console.log('response', response)

      if (response.error) throw new Error(response.statusText)
      if (response.typ === 'link' && response.redirect) {
        window.location.replace(response.redirect)
      }

    } catch (err) { console.log(err) }
  }

  return (
    <GooglePayButton
      environment={env}
      paymentRequest={paymentRequest}
      buttonColor={appColor}
      onLoadPaymentData={sendResponse}
    />
  )
}

export default GPay