import './style.scss'

const BackBtn = ({ backAction }) => {
  return (
    <button className='button__back' onClick={backAction}>
      <svg xmlns="http://www.w3.org/2000/svg" width="11.494" height="11.505" viewBox="0 0 11.494 11.505">
        <path id="Path_107" data-name="Path 107" d="M6.029,0V6.245H0" transform="translate(10.081 5.98) rotate(137)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
      </svg>
    </button>
  )
}

export default BackBtn