import { ReactComponent as DiningRoom } from '../../assets/icons/diningRoom.svg'
import TextBtn, { TEXT_BTN_TYPE } from '../../components/buttons/TextBtn'
import { selectedForm } from './reservationSlice'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import FreeOptions from './freeOptions'
import PaidOptions from './paidOptions'
import ReservationForm from './form'
import Summary from './summary'
import './style.scss'

const Reservation = () => {
  const { rulesAgreement: agreement } = useSelector(selectedForm)
  const [warning, setWarning] = useState(false)
  const [screen, setScreen] = useState(0)

  useEffect(() => {
    if (agreement) setWarning(false)
  }, [agreement])

  const send = () => {
    agreement ? console.log('send') : setWarning(true)
  }

  const move = direction => {
    const s = screen + direction
    s > 3 ? send() : setScreen(s)
  }

  const currentScreen = (() => {
    switch (screen) {
      case 0:
        return <ReservationForm move={move} />
      case 1:
        return <PaidOptions />
      case 2:
        return <FreeOptions />
      default:
        return <Summary rulesWarning={warning} />
    }
  })()

  return (
    <div className='reservation'>
      <div className='reservation__main'>
        <DiningRoom />
        {currentScreen}

        {screen !== 0 && <div className='reservation__buttons'>
          <TextBtn
            desc={screen === 3 ? 'Zapłać za rezerwację' : 'Przejdź dalej'}
            clickAction={() => move(1)} />
          <TextBtn
            desc='Lub wróć'
            type={TEXT_BTN_TYPE.unfilled}
            clickAction={() => move(-1)} />
        </div>}
      </div>
    </div>
  )
}

export default Reservation