import { ReactComponent as Cookie } from '../../../assets/icons/cookie.svg'
import { createCookie } from '../../../utils/cookies'
import { policy } from '../../../rest/urls'
import TextBtn from '../../buttons/TextBtn'
import FetchPopup from '../FetchPopup'
import { useState } from 'react'
import './style.scss'

const CookiePopup = ({ updateConsent }) => {

  const [policyPopupVisibile, setPolicyPopupVisibility] = useState(false)
  const showPolicyPopup = () => setPolicyPopupVisibility(!policyPopupVisibile)
  const eatCookie = () => {
    createCookie('CONSENT', 'YES')
    updateConsent()
  }

  const policyPopup = <FetchPopup
    url={policy}
    closeAction={showPolicyPopup} />

  return (
    <>
      {policyPopupVisibile && policyPopup}
      <div className='popup__cookie'>
        <div className='popup__row'>
          <Cookie />
          <div className='popup__link'
            onClick={showPolicyPopup}>Dowiedz się więcej</div>
        </div>

        <div className='popup__text'>
          <span className='--enlarged'>Cookies</span>
          <span> - my też lubimy ciasteczka</span>
        </div>

        <div className='popup__text'>
          <div>Twoje dalsze korzystanie ze strony </div>
          <div>oznacza, że też je lubisz</div>
        </div>

        <div className='popup__button'>
          <TextBtn
            desc='Zjedz ciasteczko'
            clickAction={eatCookie} />
        </div>
      </div>
    </>
  )
}

export default CookiePopup