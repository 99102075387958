import { ReactComponent as Announcement } from '../../../assets/icons/announcement.svg'
import { ReactComponent as ShoppingBag } from '../../../assets/icons/shoppingBag.svg'
import { ReactComponent as Delivery } from '../../../assets/icons/delivery.svg'
import { ReactComponent as QrCode } from '../../../assets/icons/qrCode.svg'
import { ReactComponent as Tips } from '../../../assets/icons/tips.svg'
import { serverUrl, updatetable, sendtip } from '../../../rest/urls'
import ServicePopup from '../../../components/popups/ServicePopup'
import { updateBasketPrice } from '../../Basket/basketSlice'
import TipPopup from '../../../components/popups/TipPopup'
import { selectedTable } from '../../Scanner/tableSlice'
import { useDispatch, useSelector } from 'react-redux'
import { SCANNER_TYPE } from '../../Scanner'
import { post } from '../../../rest/request'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import OrderOpinion from './orderOpinion'
import OrderBasket from './orderBasket'
import OrderTimer from './orderTimer'

const Order = ({ data, tips, tipDefinitions, status, questions, answers }) => {
  const { order_ident, amount, godzina: hour, data: date, takeaway = false, delivery = false, payment_logo, payment_alt, pozycje } = data
  const [servicePopupVisible, setServicePopupVisibility] = useState(false)
  const [tipsPopupVisible, setTipsPopupVisibility] = useState(false)
  const [table, setTable] = useState(data.table)
  const tableNumber = useSelector(selectedTable)
  const dispatch = useDispatch()

  let { push } = useHistory()

  const showServicePopup = () => setServicePopupVisibility(!servicePopupVisible)
  const showTipsPopup = () => setTipsPopupVisibility(!tipsPopupVisible)

  useEffect(() => {
    const updateTable = async () => {
      const body = { order_ident, table: tableNumber }
      try {
        const response = await post(updatetable, body)
        if (response.wynik === 'ok') setTable(tableNumber)
      } catch (err) { console.log(err) }
    }

    if (status.step < 4 && !table && tableNumber)
      updateTable()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableNumber, table, status])

  const tipPopup = <TipPopup
    data={{ header: tipDefinitions.header, definitions: tipDefinitions.fields, basketPrice: amount, tips: {} }}
    closeAction={showTipsPopup}
    sendTips={async tips => {

      showTipsPopup()
      let donation_sum = 0
      const tipsList = Object.entries(tips).map(([key, value]) => {
        const a = value > 0 && value < 1 ? amount * value : value
        donation_sum += a
        return { def: key, amount: a.toFixed(2) }
      })

      const body = {
        tips: tipsList,
        order_ident,
        donation_sum,
        order_sum: amount
      }

      try {
        const response = await post(sendtip, body)
        console.log(response)
        if (response.wynik === 'ok') {
          dispatch(updateBasketPrice(donation_sum))
          push('/finalization/payment?type=completelater')
        }
      } catch (err) { console.log(err) }
    }} />

  return (
    <>
      {tipsPopupVisible && tipPopup}
      {servicePopupVisible && <ServicePopup
        order_ident={order_ident}
        closeAction={showServicePopup} />}

      <div className='order'>
        <div className='order__row'>
          {table ? <div className='order__group'>
            <div className='order__text--large'>{table}</div>
            <div className='order__text'>Numer stolika</div>
          </div>
            : delivery ? <div className='order__group'>
              <Delivery />
              <div className='order__text'>Zamówienia na dowóz</div>
            </div>
              : takeaway ? <div className='order__group'>
                <ShoppingBag />
                <div className='order__text'>Zamówienie na wynos</div>
              </div>
                : <div className='order__group'
                  onClick={() => push(`/scan?type=${SCANNER_TYPE.table}`)}>
                  <QrCode />
                  <div className='order__text'>Uzupełnij stolik!</div>
                </div>}

          <OrderTimer
            hour={hour}
            date={date}
            stop={status.step === 5} />
        </div>

        <div>
          {delivery && <p>{delivery}</p>}
          <p>{status.description}</p>

          <div className='order__progress'>
            <div className={`bubble ${status.step === 1 ? '--highlighted' : ''}`}>1</div>
            <hr />
            <div className={`bubble ${status.step === 2 ? '--highlighted' : ''}`}>2</div>
            <hr />
            <div className={`bubble ${status.step === 3 ? '--highlighted' : ''}`}>3</div>
            <hr />
            <div className={`bubble ${status.step === 4 ? '--highlighted' : ''}`}>4</div>
            <hr />
            <div className={`bubble ${status.step === 5 ? '--highlighted' : ''}`}>5</div>
          </div>
        </div>

        <hr />

        <div className='order__row --menu'>
          <div className='order__group'>
            <div className='order__price'>{amount}<sup> zł</sup></div>
            <img src={serverUrl + payment_logo} alt={payment_alt} />
            <div className='order__text'>{payment_alt}</div>
          </div>

          <div className='order__group' onClick={showTipsPopup}>
            {tips.length > 0 && <div
              className='order__price'>
              {tips.reduce((r, { is_paid, positions }) => {
                if (is_paid) r += positions.reduce((r, { amount }) => {
                  r += Number(amount)
                  return r
                }, 0)
                return r
              }, 0)
                .toFixed(2)
              }<sup> zł</sup>
            </div>}
            <Tips />
            <div className='order__text'>Dodaj napiwek</div>
          </div>

          <div className='order__group' onClick={showServicePopup}>
            <Announcement />
            <div className='order__text'>Wezwij obsługę</div>
          </div>
        </div>

        <hr />

        <OrderOpinion
          order_ident={order_ident}
          questions={questions}
          answers={answers} />

        {Object.entries(pozycje).map(([key, value]) =>
          Object.entries(value).map(([key, value]) =>
            <OrderBasket key={key} data={value} />
          )
        )}

      </div>
    </>
  )
}

export default Order