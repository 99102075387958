import TwoBtnPopup from '../../../components/popups/TwoBtnPopup'
import Input from '../../../components/inputs/Input'
import { useState } from 'react'
import './style.scss'

const TablePopup = ({ updateTable, showWarning, closeAction }) => {
  const [inputVal, setInputVal] = useState('')
  const [warning, setWarning] = useState(false)

  const changeHandler = e => {
    const { value } = e.target
    const number = Number(value)

    if (!value || Number.isInteger(number))
      setInputVal(value)
    if (number) setWarning(false)
  }

  const tableInput = <Input
    value={inputVal}
    warning={warning}
    type='number'
    placeholder='Wpisz numer stolika'
    warningMessage='Wpisz właściwy nr stolika'
    changeHandler={changeHandler}
  />

  return (
    <TwoBtnPopup
      title={showWarning ? 'Nie udało się skorzystać z kamery' : 'Wpisz numer stolika'}
      description={tableInput}
      firstBtnText='Zapisz'
      secondBtnText='Wróć'
      secondBtnAction={closeAction}
      firstBtnAction={() => {
        if (inputVal) updateTable(inputVal)
        else setWarning(true)
      }} />
  )
}

export default TablePopup