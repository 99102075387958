const User = () => {
  const style = {
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: 'var(--main-color)',
  }

  return (
    <div className='user__icon' style={style}>
      <svg xmlns="http://www.w3.org/2000/svg" width="11.938" height="12.779" viewBox="0 0 11.938 12.779">
        <path id="Path_187" data-name="Path 187" d="M584.546,118.6c.736-.412,1.246-.678,2.5-1.251a1.255,1.255,0,0,0,.731-.9,1.2,1.2,0,0,0-.328-1.068,4.307,4.307,0,0,1-1.169-2.834,3.8,3.8,0,0,1,.912-2.69,1.952,1.952,0,0,1,1.4-.591h.031c2.189,0,2.359,2.515,2.359,3.269a5,5,0,0,1-1.07,2.9.441.441,0,0,0-.066.114,1.256,1.256,0,0,0,.451,1.666l.156.077c1.332.655,1.69.83,2.527,1.277a2.326,2.326,0,0,1,1.052,1.982H583.651A2.221,2.221,0,0,1,584.546,118.6Z" transform="translate(-582.9 -108.515)" fill="rgba(0,0,0,0)" stroke="#fff" strokeWidth="1.5" />
      </svg>
    </div>
  )
}

export default User