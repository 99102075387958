import { createSlice } from '@reduxjs/toolkit';

export const basketSlice = createSlice({
  name: 'basket',
  initialState: {
    list: [],
    basketPrice: 0,
    tips: {},
  },
  reducers: {
    addFood: (state, action) => {
      const { id, name, amount = 1, drink, variant = '', additional = [], price } = action.payload

      if (id && name && price) {
        const additionalPrice = additional.length > 0 ? additional.reduce((r, { products }) =>
          r += products.reduce((r, { amount, price }) => r += (amount * price), 0), 0) : 0
        const finalPrice = (additionalPrice + price) * amount

        state.list.push({ id, name, drink, chosen_variant: variant, additional, comment: '', amount, unitPrice: price, finalPrice })
        state.basketPrice += finalPrice
      }
    },
    addOrder: (state, action) => {
      const { id, name, EKR_ID, order_zpo, fields, unitPrice, finalPrice } = action.payload

      if (id && EKR_ID && order_zpo && fields && finalPrice) {
        state.list.push({ id, name, EKR_ID, order_zpo, fields, unitPrice, finalPrice })
        state.basketPrice += finalPrice
      }
    },
    updateOrder: (state, action) => {
      const { index, EKR_ID, order_zpo, fields, finalPrice } = action.payload

      if (finalPrice && order_zpo && state.list[index]) {
        state.basketPrice -= state.list[index].finalPrice
        state.list[index].order_zpo = order_zpo
        state.list[index].finalPrice = finalPrice
        if (EKR_ID) state.list[index].EKR_ID = EKR_ID
        if (fields) state.list[index].fields = fields
        state.basketPrice += finalPrice
      }
    },
    addOrUpdateTips: (state, action) => {
      if (action.payload) state.tips = action.payload
    },
    updateAmountAndAdditionalOfFood: (state, action) => {
      const { value, additional, basketIdx, price } = action.payload
      const element = state.list[basketIdx]

      if (value > 0 && element) {
        const { amount, finalPrice } = element
        const newPrice = price ? price : (finalPrice * value) / amount

        element.amount = value
        element.finalPrice = newPrice
        state.basketPrice += newPrice - finalPrice

        if (additional) element.additional = additional
      }
    },
    updateCommentOfFood: (state, action) => {
      const { comment, basketIdx } = action.payload
      const element = state.list[basketIdx]

      if (element) element.comment = comment
    },
    deleteFood: (state, action) => {
      const { basketIdx = -1 } = action.payload

      if (basketIdx > -1) {
        const newList = state.list.reduce((result, element, index) => {
          index === basketIdx ? state.basketPrice -= element.finalPrice : result.push(element)
          return result
        }, [])

        state.list = newList
      }
    },
    updateBasketPrice: (state, action) => {
      state.list = []
      state.basketPrice = action.payload
      state.tips = {}
    },
    resetBasket: state => {
      state.list = []
      state.basketPrice = 0
      state.tips = {}
    },
  }
})

export const { addFood, addOrder, addOrUpdateTips, updateOrder, updateAmountAndAdditionalOfFood, updateCommentOfFood, deleteFood, updateBasketPrice, resetBasket } = basketSlice.actions

export const selectBasketList = state => state.basket.list
export const selectBasketListByIndex = (state, index) => state.basket.list[index]
export const selectBasketListLastIndex = state => state.basket.list.length - 1

export const selectBasketToRequest = state => {
  const { list, basketPrice, tips } = state.basket

  const cart = list.reduce((r, { id, EKR_ID, order_zpo, amount, unitPrice, chosen_variant, additional = [] }) => {
    if (additional.length > 0) {
      const additionalList = additional.reduce((r, { products }) => {
        const productsList = products.reduce((r, { id, amount }) => {
          if (amount) r[id] = amount
          return r
        }, {})

        r = { ...r, ...productsList }
        return r
      }, {})

      r.push({ set_id: id, quantity: amount, set_price: unitPrice.toFixed(2), dodatki: additionalList })

    } else if (EKR_ID) {
      r.push({ id, EKR_ID, order_zpo })
    } else r.push({ id, quantity: amount, price: unitPrice.toFixed(2), chosen_variant })
    return r
  }, [])

  const tipsList = Object.entries(tips).map(([key, value]) => {
    const amount = value > 0 && value < 1 ? basketPrice * value : value
    return { def: key, amount: amount.toFixed(2) }
  })

  return { cart, tips: tipsList }
}

export const selectBasketPrice = state => {
  const { basketPrice, tips } = state.basket

  const tipsPrice = Object.entries(tips).reduce((r, [key, value]) => {
    r += value > 0 && value < 1 ? basketPrice * value : value
    return r
  }, 0)
  return (basketPrice + tipsPrice).toFixed(2)
}

export const selectTips = state => {
  const { basketPrice, tips } = state.basket
  return { basketPrice, tips }
}

export default basketSlice.reducer
