import RangeSelector from './RangeSelector'
import { useRef } from 'react'

const Range = ({ min = 1, max = 2, value = [1.5, 1.5], setValue, step = 0.1 }) => {
  const lineRef = useRef(null)
  const minWidth = (100 * step) / (max - min)
  const period = (() => {
    const multiplier = `${min}`.match(/^\d+[.]{1}\d{1}5/) ? 0.5 : 1
    const list = []
    
    for (let i = min; i < max; i += step) {
      const width = `${i === min ? multiplier * minWidth : minWidth}%`
      list.push(<div key={i} className='peroid__item' style={{ width }} />)
    }
    return list
  })()

  const left = (v, m = min) => (v - m) * 10 * minWidth

  const handleMove = (e, idx, val) => {
    if (!lineRef.current) return

    const { clientWidth } = lineRef.current
    const offset = (window.innerWidth - clientWidth) / 2
    const x = (e.type === 'mousemove' ? e.clientX : e.touches[0].clientX) - offset
    const percent = (x * 100) / clientWidth

    let closest = val - step
    let prev = Math.abs(left(closest) - percent)

    for (let i = val; i <= val + step; i += step) {
      let curr = Math.abs(left(i) - percent)

      if (curr < prev) {
        closest = i
        prev = curr
      }
    }

    if (closest + 0.01 >= min && closest <= max + 0.01 && closest !== val) {
      setValue([!idx ? closest : value[0], idx ? closest : value[1]])

      if (e.type === 'mousemove') return closest
    }
  }

  return (
    <div className='range'>
      <RangeSelector
        idx={0}
        value={value[0]}
        left={left}
        handleMove={handleMove} />

      <RangeSelector
        idx={1}
        value={value[1]}
        left={left}
        handleMove={handleMove} />

      <div
        ref={lineRef}
        className='range__line'>
        <div className='range__peroid'>
          {period}
        </div>
        <div
          className='range__selected'
          style={value[0] < value[1] ? { left: `${left(value[0])}%`, width: `${left(value[1], value[0])}%` }
            : { left: `${left(value[1])}%`, width: `${left(value[0], value[1])}%` }} />
      </div>
    </div >
  )
}

export default Range