import { ReactComponent as BasketIcon } from '../../assets/icons/shoppingBasket.svg'
import { selectBasketPrice } from '../../conteiners/Basket/basketSlice'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import './style.scss'

const ShoppingBasket = ({ clickAction }) => {
  const finalPrice = useSelector(selectBasketPrice)
  let { push } = useHistory()

  const openBasket = () => {
    push('/basket')
    clickAction()
  }

  return (
    <div className='shoppingbasket' onClick={openBasket}>
      <BasketIcon />
      <div className='basket__info'>
        {finalPrice > 0 ? `W koszyku: ${finalPrice}zł` : 'Koszyk jest pusty'}
      </div>
    </div>
  )
}

export default ShoppingBasket