import { ReactComponent as Warning } from '../../../assets/icons/warning.svg'
import { ReactComponent as Search } from '../../../assets/icons/search.svg'
import FindCompanyPopup from '../../popups/FindCompanyPopup'
import { useEffect, useState, forwardRef } from 'react'
import { findCompany } from '../../../rest/urls'
import { STATUS } from '../../../rest/status'
import { get } from '../../../rest/request'
import './style.scss'

const NipInput = forwardRef(({ value, warning, showRestCompanyData, enterClicked, setFocus, changeHandler, blurHandler }, ref) => {
  const [popupVisible, setPopupVisibility] = useState(false)
  const [status, setStatus] = useState(STATUS.idle)
  const [lastValue, setLastValue] = useState(value)
  const [data, setData] = useState([])
  const placeholder = 'Numer NIP'

  useEffect(() => setStatus(STATUS.idle), [value])

  useEffect(() => {
    if (lastValue !== value && value.length === 10 && !popupVisible) {
      setFocus(false)
      showCompanyData()
    }
    setLastValue(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, popupVisible])

  const showPopup = () => setPopupVisibility(!popupVisible)

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      e.target.blur()
      ref && enterClicked(e.target)
    }
  }

  const showCompanyData = async () => {
    showPopup()

    if (status !== STATUS.idle) return
    setStatus(STATUS.pennding)

    try {
      const response = await get(findCompany(value))
      if (Array.isArray(response)) {
        setData(response)
        setStatus(STATUS.succeed)

      } else {
        setData(response.error || 'Brak wyników')
        setStatus(STATUS.failed)
      }

    } catch (err) {
      setStatus(STATUS.failed)
    }
  }

  const popup = <FindCompanyPopup
    status={status}
    data={data}
    closeAction={showRestData => {
      showRestData ? showRestCompanyData() : setFocus(true)
      showPopup()
    }} />

  return (
    <>
      {popupVisible && popup}
      <div className='input__container'>
        {warning && <div className='input__icon--left'>
          <Warning />
        </div>}

        {value.length === 10 && <div
          className='input__icon--right'
          onClick={showCompanyData}>
          <Search />
        </div>}

        <input
          className={`input ${warning ? '--warning' : ''}`}
          ref={ref}
          value={value}
          name='companyNip'
          type='number'
          min='1000000000'
          max='9999999999'
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          onChange={changeHandler}
          onBlur={blurHandler} />

        {warning && <span className='input__warning'>
          {value ? 'Wpisz właściwy numer NIP' : placeholder}
        </span>}
      </div>
    </>
  )
})

export default NipInput