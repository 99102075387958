import { ReactComponent as Warning } from '../../assets/icons/warning.svg'
import './style.scss'

const Select = ({ name, selected = '', multiple = false, warning = false, placeholder = '', warningMessage = '', options = [], changeHandler }) => {
  return (
    <div className='select'>
      {warning && <div className='select__icon --left'>
        <Warning />
      </div>}

      <div className='select__icon --right'>
        <div className='arrow__down' />
      </div>

      <select
        name={name}
        value={selected}
        className={`select__main ${warning ? '--warning' : ''}`}
        onChange={changeHandler}
        multiple={multiple}
        required>

        {!selected && <option value='' disabled hidden>{placeholder}</option>}
        {options.map(({ value, name: n }, idx) => <option
          key={idx}
          value={value}>
          {n}
        </option>)}

      </select>
      {warning && <span className='select__warning'>{warningMessage}</span>}
    </div>
  )
}

export default Select