import { selectedAvailableOptions, selectedOptions, updateSelectedOptions } from './reservationSlice'
import PriceBtn from '../../components/buttons/PriceBtn'
import TextBtn from '../../components/buttons/TextBtn'
import { useDispatch, useSelector } from 'react-redux'

const PaidOptions = () => {
  const options = useSelector(state => selectedAvailableOptions(state, true))
  const selected = useSelector(selectedOptions)
  const dispatch = useDispatch()

  return (
    <>
      <h2>Opcje płatne</h2>
      <p>Możesz dokupić dodatkowe opcje.<br />Sprawdź listę i wybierz.</p>

      <div className='reservation__options--paid'>
        {options.map(({ id = 0, name = '', desc = '', img = '', payable: { once = false, price = '' } }) => {
          const isSelected = selected.includes(id)
          return (
            <div key={id}
              className={`options__item ${isSelected ? '--selected' : ''}`}>
              <img src={img} alt={name} />
              <div>
                <div className='options__title'>{name}</div>
                <div className='options__desc'>{desc}</div>
              </div>
              <div className='options__buttons'>
                <div className='options__once'>{once ? 'Jednorazowo' : 'Za osobę'}</div>
                <PriceBtn price={price} />
                <TextBtn
                  desc={isSelected ? 'Usuń x' : 'Wybierz'}
                  clickAction={() => dispatch(updateSelectedOptions({ id, add: !isSelected }))} />
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default PaidOptions