import { createSlice } from '@reduxjs/toolkit'

export const tableSlice = createSlice({
  name: 'table',
  initialState: {
    value: ''
  },
  reducers: {
    updateTable: (state, action) => {
      const value = action.payload
      const number = value ? Number(value) : value

      if (number) state.value = value
    },
  },
})

export const { updateTable } = tableSlice.actions;
export const selectedTable = state => state.table.value

export default tableSlice.reducer
