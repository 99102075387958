import { selectSearchWord, updateSearchWord } from '../../Categories/categoriesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import './style.scss'

const SearchPanel = ({ closeSearchPanel }) => {
  const [wideSearch, showWideSearch] = useState(true)
  const searchWord = useSelector(selectSearchWord)
  const dispatch = useDispatch()
  let inputRef = useRef(null)

  useEffect(() => {
    window.addEventListener('popstate', resetSearchPanel)
    return () => window.removeEventListener('popstate', resetSearchPanel)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (wideSearch) inputRef?.current.focus()
  }, [wideSearch])


  const resetSearchPanel = () => {
    dispatch(updateSearchWord())
    closeSearchPanel()
  }

  return (
    wideSearch ? (
      <div className='search__panel'>
        <div className='search__arrow'
          onClick={() => searchWord ? showWideSearch(false) : closeSearchPanel()}>
          <div className='arrow__down' />
        </div>

        <div className='search__main'>
          <input
            ref={inputRef}
            value={searchWord}
            className='search__input'
            placeholder='Wpisz na co masz ochotę...'
            onKeyDown={e => {
              if (e.keyCode === 13) searchWord ? showWideSearch(false) : closeSearchPanel()
            }}
            onChange={e => dispatch(updateSearchWord(e.target.value))} />
        </div>
      </div>

    ) : (
      <div className='search__panel--minimized'>
        <span onClick={() => showWideSearch(true)}>{searchWord}</span>
        <span onClick={resetSearchPanel}> X</span>
      </div>
    )
  )
}

export default SearchPanel