import { selectPaymentStatus } from '../Announcements/statusSlice'
import { useDispatch, useSelector } from 'react-redux'
import TextBtn from '../../components/buttons/TextBtn'
import HistoryOfOrders from './HistoryOfOrders'
import Loading from '../../components/Loading'
import CurrentOrders from './CurrentOrders'
import { useEffect, useState } from 'react'
import { STATUS } from '../../rest/status'
import { useHistory } from 'react-router'
import Menu from './menu'
import './style.scss'

const Orders = () => {
  const [currentOrders, showCurrentOrders] = useState(true)
  const paymentStatus = useSelector(selectPaymentStatus)
  const [status, setStatus] = useState(STATUS.pennding)
  const [error, setError] = useState('')
  const dispatch = useDispatch()

  let { push } = useHistory()

  useEffect(() => {
    if (paymentStatus && status === STATUS.pennding) {
      const { status_raw = '', status: error = '' } = paymentStatus
      if (status_raw !== 'pending') {
        if (status_raw === 'payment_not_initiated' || status_raw === 'success')
          setStatus(STATUS.succeed)

        else {
          setError(error)
          setError('Płatność się nie powiodła')
          setStatus(STATUS.failed)
        }
      }
    }
  }, [status, paymentStatus, dispatch])

  return (
    <div className='orders'>
      {status === STATUS.pennding ? <Loading />
        : status === STATUS.succeed ? <>
          <Menu
            currentOrders={currentOrders}
            showCurrentOrders={showCurrentOrders} />

          {currentOrders ? <CurrentOrders /> : <HistoryOfOrders />}
        </>
          : <div className='orders__error'>
            <h2>{error}</h2>
            <TextBtn
              desc='Ponów płatność'
              clickAction={() => push('/finalization/payment/banks')} />
          </div>}
    </div>
  )
}

export default Orders