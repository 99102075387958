import { updateData } from '../../../conteiners/Finalization/formSlice'
import TextBtn, { TEXT_BTN_TYPE } from '../../buttons/TextBtn'
import { STATUS } from '../../../rest/status'
import { useDispatch } from 'react-redux'
import Loading from '../../Loading'
import Popup from '../Popup'
import './style.scss'

const FindCompanyPopup = ({ status, data, closeAction }) => {
  const dispatch = useDispatch()

  const dispatchData = (name, street, code, city) => {
    dispatch(updateData({ name: 'companyName', value: name }))
    dispatch(updateData({ name: 'companyStreet', value: street }))
    dispatch(updateData({ name: 'companyCode', value: code }))
    dispatch(updateData({ name: 'companyCity', value: city }))
    closeAction(true)
  }

  const elements = Array.isArray(data) && data
    .map(({ silosID, name, streetName, homeNr, flatNr, postalCode, postalCityName }) => {
      const street = `${streetName} ${homeNr}${flatNr ? `/${flatNr}` : ''}`
      const address = `${street}, ${postalCode} ${postalCityName}`

      return (
        <div key={silosID}
          className='list__element'
          onClick={() => dispatchData(name, street, postalCode, postalCityName)}>
          <div className='element__title'>{name}</div>
          <div className='element__title --secondary'>{address}</div>
        </div>
      )
    })

  return (
    <Popup
      closeBtnDesc='Wpisz dane ręcznie'
      closeAction={closeAction}>

      <div className='popup__main--findCompany'>
        <div className='popup__title'>
          {status !== STATUS.failed ? 'Wybierz z listy' : data}
        </div>

        {status === STATUS.pennding ? <Loading middleOfPage={false} />
          : status === STATUS.succeed ? <div className='popup__list'>{elements}</div>
            : false}

        <TextBtn
          type={TEXT_BTN_TYPE.unfilled}
          desc='Zmień NIP'
          clickAction={() => {
            dispatch(updateData({ name: 'companyNip', value: '' }))
            closeAction(false)
          }} />
      </div>
    </Popup>
  )
}

export default FindCompanyPopup