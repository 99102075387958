import { useState } from 'react'
import './style.scss'

const ComposeListElement = ({ data, maxOptions, currentOptions, updateAmount }) => {
  const { name, price, amount } = data
  const [isFilled, setFilled] = useState(amount > 0)
  const [value, setValue] = useState(amount)

  const updateValue = (e, difference) => {
    const newVal = value + difference
    e.stopPropagation()

    if (newVal < value || currentOptions < maxOptions) {
      updateAmount(data, newVal)
      setFilled(newVal > 0)
      setValue(newVal)
    }
  }

  const resetValue = () => {
    const newVal = isFilled ? 0 : 1

    if (isFilled || currentOptions < maxOptions) {
      updateAmount(data, newVal)
      setFilled(!isFilled)
      setValue(newVal)
    }
  }

  return (
    <div
      className={`composeList__element ${isFilled ? '--filled' : '--unfilled'}`}
      onClick={resetValue}>

      <div className='element__desc'>
        <div className='element__icon'
          onClick={(e) => updateValue(e, 1)}>+</div>
        {isFilled && <div className='element__icon'
          onClick={(e) => updateValue(e, -1)}>-</div>}
        <div className='element__title'>
          {isFilled && <span>{value} x </span>}
          <span>{name}</span>
        </div>
      </div>

      <div className='element__price'>
        <span>+{price.toFixed(2)}</span><sup>zł</sup>
      </div>
    </div>
  )
}

export default ComposeListElement