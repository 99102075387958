import { ReactComponent as Close } from '../../../assets/icons/close.svg'
import './style.scss'

const CloseBtn = ({ desc = 'Zamknij', isWhiteBG = false, closeAction }) => {
  return (
    <div
      className={`button__close ${isWhiteBG ? '--whitebg' : ''}`}
      onClick={closeAction}>
      <Close />
      <div className='button__description'>{desc}</div>
    </div>
  )
}

export default CloseBtn