import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import soup from '../../assets/icons/soup.svg'
import { categories } from '../../rest/urls'
import { STATUS } from '../../rest/status'
import { get } from '../../rest/request'

export const fetchCategories = createAsyncThunk('categories/fetchCategories', async () => {
  const screen_id = process.env.REACT_APP_CHRISTMAS_STORE === 'true' ? 200007 : 200003
  const response = await get(categories(screen_id))
  return response.collection
})

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
    searchWord: '',
    status: STATUS.idle,
  },
  reducers: {
    updateSearchWord: (state, action) => {
      const newVal = action.payload
      state.searchWord = newVal ? newVal : ''
    }
  },
  extraReducers: {
    [fetchCategories.fulfilled]: (state, action) => {
      const list = action.payload.map((element) => {
        const { name, icon } = element
        if (name) element.key = name.toLowerCase().replace(/\s|[/]/g, '-')
        if (!icon) element.icon = soup
        return element
      })
      state.status = STATUS.succeed
      state.categories = list
    },
    [fetchCategories.rejected]: (state, action) => {
      state.status = STATUS.failed
    },
  }
});

export const { updateSearchWord } = categoriesSlice.actions
export const selectSearchWord = state => state.categories.searchWord
export const selectResponseStatus = state => state.categories.status
export const selectCatregories = state => {
  const { searchWord, categories } = state.categories
  if (!searchWord) return categories

  return categories.reduce((result, category) => {
    const { id, key, name, description, icon, products } = category
    const list = products.filter(({ name }) => name.search(new RegExp(searchWord, 'i')) > -1)

    if (list.length > 0) result.push({
      id,
      key,
      name,
      description,
      icon,
      products: list
    })

    return result
  }, [])
}

export default categoriesSlice.reducer
