import { fetchStatus, selectDuration, selectStatus } from './statusSlice'
import { useDispatch, useSelector } from 'react-redux'
import { resetBasket } from '../Basket/basketSlice'
import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import AnnouncementsIcon from './Icon'
import './style.scss'

const Announcements = () => {
  const [notification, setNotification] = useState([])
  const [orders, setOrders] = useState([])

  const status = useSelector(selectStatus)
  const duration = useSelector(selectDuration)
  const dispatch = useDispatch()

  let { replace, location: { pathname } } = useHistory()

  useEffect(() => setTimeout(() => dispatch(fetchStatus())), [dispatch])

  useEffect(() => {
    let interval = setInterval(() => dispatch(fetchStatus()), duration)
    return () => clearInterval(interval)
  }, [duration, dispatch])

  useEffect(() => {
    const { payment, orders_status: ordersStatus, chat_unread_count: chatUnread } = status
    let n = []

    if (payment) {
      const { status_raw = '', wynik = '' } = payment
      if (wynik === 'end' && status_raw === 'success') {
        dispatch(resetBasket())
        if (!pathname.includes('orders')) replace('/orders')
      }
    }

    if (ordersStatus && !ordersStatus.carts) {
      n = Object.entries(ordersStatus).reduce((r, [key, { status_key, time }]) => {
        if (orders[key] && orders[key].status_key.key !== status_key.key && !pathname.includes('orders'))
          r.push(`Zamówienie z ${time} zmieniło status`)
        return r
      }, [])

      setOrders(ordersStatus)
    }

    if (chatUnread) n.push(`masz ${chatUnread} nieprzeczytane wiadomości`)
    setNotification(n)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, pathname])

  return (
    <div className='announcements'>
      {notification.length > 0 && <AnnouncementsIcon
        notification={notification}
        updateNotification={index => setNotification(notification.filter((v, i) => i !== index))} />}
    </div>
  )
}

export default Announcements