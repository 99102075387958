import SettingsList from './SettingsList'
import { useState } from 'react'
import './style.scss'

const Settings = () => {
  const [isClose, setClose] = useState(true)

  return (
    <div className={`settings ${isClose ? '' : '--wide'}`}>
      <div className='settings__panel'>
        {!isClose && <>
          <SettingsList />
          <div className='settings__message'>Zamknij panel</div>
        </>}
      </div>

      <div
        className='settings__arrow'
        onClick={() => setClose(!isClose)}>
        <div className={isClose ? 'arrow__down' : 'arrow__up'} />
      </div>
    </div>
  )
}

export default Settings