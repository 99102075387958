import { fetchCategories } from './conteiners/Categories/categoriesSlice'
import { fetchConfig } from './conteiners/Settings/settingsSlice'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import store from './store'
import React from 'react'
import App from './App'
import './index.css'

setTimeout(() => store.dispatch(fetchCategories())
  .then(() => store.dispatch(fetchConfig())))

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
