import Comments from '../../inputs/Comments'
import TextBtn from '../../buttons/TextBtn'
import { post } from '../../../rest/request'
import { chat } from '../../../rest/urls'
import { useState } from 'react'
import Popup from '../Popup'
import './style.scss'

const BasicOption = ({ submitAction, closeAction }) => {
  const [notes, setNotes] = useState('')

  const submitOption = async () => {
    const body = {
      action: 'new_request',
      help_type: 4,
      notes 
    }

    try {
      const response = await post(chat, body)
      console.log(response)
      if (response.wynik === 'ok') submitAction()
      else submitAction(response.error)
    }
    catch (err) { console.log(err) }
  }

  return (
    <Popup closeAction={closeAction}>
      <div className='popup__main--service'>
        <h2 className='popup__title'>Zgłoś problem</h2>

        <div className='popup__inputs'>
          <Comments
            value={notes}
            placeholder='Uwagi'
            changeHandler={e => setNotes(e.target.value)} />
        </div>

        <TextBtn
          desc='Tak, zgłaszam problem'
          clickAction={() => notes && submitOption()} />
      </div>
    </Popup>
  )
}

export default BasicOption