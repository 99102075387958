import './style.scss'

const Comments = ({ value, placeholder = 'Uwagi do zamówienia', changeHandler }) => {
  return (
    <textarea
      className='form__comments'
      value={value}
      name='comments'
      type='text'
      rows='1'
      maxLength='500'
      placeholder={placeholder}
      onChange={e => e.target.value !== '\n' && changeHandler(e)} />
  )
}

export default Comments