import './style.scss'

const Loading = ({ middleOfPage = true }) => {
  return (
    <div className={`loading ${middleOfPage ? '--centered' : ''}`}>
      <div className='loading__spinner'>
        <div className='spinner__interior' />
      </div>
    </div>
  )
}

export default Loading