import { ReactComponent as WeightIcon } from '../../../../assets/icons/weight.svg'
import { ReactComponent as PiecesLeftIcon } from '../../../../assets/icons/piecesLeft.svg'
import { ReactComponent as BasketIcon } from '../../../../assets/icons/shoppingBasket.svg'
import IconTextBtn from '../../../../components/buttons/IconTextBtn'
import PriceBtn from '../../../../components/buttons/PriceBtn'
import './style.scss'

const ListElement = ({ category, photoVisibility, element }) => {
  const { title, weight, price, piecesLeft, desc, picture } = element
  const { categoryTitle, Icon } = category
  const photoMod = photoVisibility ? '' : '--nophoto'
  const activMod = piecesLeft === 0 ? '--inactive' : ''

  const noPhoto = (
    <div className='element__photo'>
      <Icon />
      <div className='photo__desc'>{categoryTitle}</div>
    </div>)


  return (
    <div className={`list__element--tight ${activMod} ${photoMod}`}>
      {photoVisibility && picture ? <img src={picture} alt={title} /> : noPhoto}

      <div className='element__col'>
        <div className='element__title'>{title}</div>

        {photoVisibility && <div className='element__row'>
          <div className='element__group'>
            <WeightIcon />
            <div className='element__title'>{weight}g</div>
          </div>
          {piecesLeft !== 0 && piecesLeft < 5 && <div className='element__group'>
            <PiecesLeftIcon />
            <div className='element__title'>Zostało: {piecesLeft}</div>
          </div>}
        </div>}

        <div className='element__desc'>{desc}</div>
        <div className='element__row'>
          <PriceBtn price={price} />
          <IconTextBtn Icon={BasketIcon} />
        </div>
      </div>
    </div>
  )
}

export default ListElement