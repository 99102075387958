import { selectedCompanyData, selectedConfig } from '../../../conteiners/Settings/settingsSlice'
import TextBtn from '../../buttons/TextBtn'
import { useSelector } from 'react-redux'
import Logo from '../../Logo'
import Popup from '../Popup'
import './style.scss'

const AboutPopup = ({ closeAction }) => {
  const { address, numbers, mail } = useSelector(selectedCompanyData)
  const config = useSelector(selectedConfig)

  return (
    <Popup closeAction={closeAction}>
      <div className='popup__main--about'>
        <Logo />
        <h2 className='about__title'>Witamy serdecznie!</h2>

        <div className='about__date'>
        <div>Jesteśmy otwarci</div>
        {config.working_hours && Object.entries(config.working_hours).map(([key, value]) => <div key={key} dangerouslySetInnerHTML={{ __html: value }} />)}
        </div>

        <div><hr /></div>

        <div className='about__address'>
          <div>{address.street}</div>
          <div>{address.postalCode} {address.city}</div>
        </div>

        <div className='about__contact'>
          {numbers.map(n => <a key={n} href={`tel:${n}`}>tel. {n}</a>)}
          <a href={`mailto:${mail}`}>{mail}</a>
        </div>

        <div className='about__buttons'>
          <TextBtn
            desc='Zobacz na mapie'
            clickAction={() => window.open(`https://www.google.com/maps/place/${address.street}+${address.postalCode}+${address.city}`)} />

          <TextBtn
            desc='Zadzwoń'
            clickAction={() => numbers[0] && window.open(`tel:${numbers[0]}`)} />
        </div>
      </div>
    </Popup>
  )
}

export default AboutPopup