import TextBtn, { TEXT_BTN_TYPE } from '../../components/buttons/TextBtn'
import { selecetCanOrder, selectOpenedStatus } from '../Announcements/statusSlice'
import { selectCatregories } from '../Categories/categoriesSlice'
import VariantsPopup from '../../components/popups/VariantsPopup'
import TwoBtnPopup from '../../components/popups/TwoBtnPopup'
import OneBtnPopup from '../../components/popups/OneBtnPopup'
import { resetBasket, selectBasketList } from './basketSlice'
import { selectedConfig } from '../Settings/settingsSlice'
import CloseBtn from '../../components/buttons/CloseBtn'
import { useDispatch, useSelector } from 'react-redux'
import { FORM_TYPE } from '../Finalization'
import { useHistory } from 'react-router'
import Price from '../../components/Price'
import FoodElement from './FoodElement'
import TipElement from './TipElement'
import { useState } from 'react'
import './style.scss'

const Basket = () => {
  const canOrderStatus = useSelector(selecetCanOrder)
  const categories = useSelector(selectCatregories)
  const isOpened = useSelector(selectOpenedStatus)
  const basketList = useSelector(selectBasketList)
  const config = useSelector(selectedConfig)
  const [popup, setPopup] = useState('')
  const dispatch = useDispatch()

  let { push, goBack } = useHistory()

  const hidePopup = () => setPopup('')

  const list = categories.reduce((r, e) => {
    const { id, drinks, name, key } = e
    if (drinks) r.push({ id, name, key })
    return r
  }, [])

  const isDrinkInBasket = (() => basketList.find(({ drink }) => drink) ? true : false)()

  const variantPopup = <VariantsPopup
    title='A może coś do picia?'
    list={list}
    isAdditionalBtn
    closeAction={hidePopup}
    sendAction={item => {
      hidePopup()
      if (item === 0) push('/finalization')
      else if (item.key) push(`/categories/${item.key}`)
    }} />

  const selectionPopup = <TwoBtnPopup
    secondBtnAction={hidePopup}
    firstBtnAction={() => {
      dispatch(resetBasket())
      hidePopup()
    }} />

  const warningPopup = <OneBtnPopup
    title='Uwaga!'
    description='Niestety restauracja jest już nieczynna'
    btnText='OK'
    isCloseBtn={false}
    btnAction={hidePopup} />


  const warning2Popup = <OneBtnPopup
    title='Uwaga!'
    description='Chwilowo nie możemy zrealizować Państwa zamówienia'
    btnText='OK'
    isCloseBtn={false}
    btnAction={hidePopup} />

  return (
    <div className='basket'>
      {popup === 'variant' ? variantPopup
        : popup === 'warning' ? warningPopup
          : popup === 'warning2' ? warning2Popup
            : popup === 'selection' ? selectionPopup : false}

      <div className='basket__button--reset'>
        <CloseBtn
          isWhiteBG
          desc='Wyczyść zawartość koszyka'
          closeAction={() => setPopup('selection')} />
      </div>

      <div className='basket__list'>
        <div className='list__title'>Podsumowanie</div>
        <div className='list__main'>
          {basketList.map((e, idx) => <FoodElement key={idx} data={e} index={idx} />)}
          <TipElement />
        </div>

        <div className='list__price'>
          <Price />
        </div>
      </div>

      <div className='basket__buttons'>
        <TextBtn
          desc='Do kasy'
          clickAction={() => config.can_order !== '0' && canOrderStatus !== '0' ?
            process.env.REACT_APP_CHRISTMAS_STORE === 'true' ? push(`/finalization/form?type=${FORM_TYPE.chrisrtmasOrder}`) :
              isOpened && isOpened.is_opened ? isDrinkInBasket ? push('/finalization') : setPopup('variant')
                : setPopup('warning')
            : setPopup('warning2')} />
        <TextBtn
          desc='Wróć'
          type={TEXT_BTN_TYPE.unfilled}
          clickAction={() => window.history.state ? goBack() : push('/')} />
      </div>
    </div>
  )
}

export default Basket