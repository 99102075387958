import OpinionPopup from '../../../components/popups/OpinionPopup'
import { selectedConfig } from '../../Settings/settingsSlice'
import Star from '../../../components/icons/Star'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const OrderBasket = ({ data: { id, nazwa, opis, ilosc, total, dodatki } }) => {
  const [opinionPopupVisible, setOpinionPopupVisibility] = useState(false)
  const [addedOpinion, setAddedOpinion] = useState(false)
  const config = useSelector(selectedConfig)

  const showOpinionPopup = () => setOpinionPopupVisibility(!opinionPopupVisible)

  const opinionPopup = <OpinionPopup
    product_id={id}
    questions={config['product_questions'] ? config['product_questions'] : []}
    answers={{}}
    closeAction={showOpinionPopup}
    submitAnswers={answers => {
      setAddedOpinion(true)
      showOpinionPopup()
    }} />

  return (
    <>
      {opinionPopupVisible && opinionPopup}
      <div className='order__basket'>
        <div className='basket__desc'>
          <div className='basket__col'>

            <div className='basket__title'>
              <span>{nazwa}</span>
              {ilosc && <span className='--secondary'>({ilosc})</span>}
              {opis && <div dangerouslySetInnerHTML={{ __html: opis }} />}
            </div>

            {dodatki && dodatki.filter(({ czyGlowny }) => !czyGlowny).map(({ id, nazwa, ilosc }) =>
              <div key={id} className='basket__additional'>
                <span className='additional__title --color'>+</span>
                <span className='additional__title'>{nazwa}</span>
                {ilosc > 1 && <span className='additional__title --secondary'>({ilosc})</span>}
              </div>
            )}
          </div>

          <div className='basket__col' onClick={showOpinionPopup}>
            <Star filled={addedOpinion} />
          </div>
        </div>

        <div className='basket__price'>
          <span>{!isNaN(total) ? total.toFixed(2) : total}<sup> zł</sup></span>
        </div>
      </div>
    </>
  )
}

export default OrderBasket