import { ReactComponent as Notification } from '../../assets/icons/notification.svg'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useRef } from 'react'

const AnnouncementsIcon = ({ notification, updateNotification }) => {
  const [transform, setTransform] = useState({ x: 0, y: 0 })
  const [details, showDetails] = useState(false)

  const mainRef = useRef(null)
  const iconRef = useRef(null)

  let { push } = useHistory()

  useEffect(() => {
    const playground = document.querySelector('.announcements')
    const height = window.innerHeight - 116
    const width = window.innerWidth - 116

    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = transform.x
    let yOffset = transform.y

    const mouseDown = e => {
      e.preventDefault()
      initialX = e.clientX - xOffset
      initialY = e.clientY - yOffset

      const mouseUp = () => {
        document.removeEventListener('mouseup', mouseUp)
        document.removeEventListener('mousemove', drag)
        dragEnd()
      }

      document.addEventListener('mousemove', drag)
      document.addEventListener('mouseup', mouseUp)
    }

    const touchStart = e => {
      initialX = e.touches[0].clientX - xOffset
      initialY = e.touches[0].clientY - yOffset
    }

    const dragEnd = () => {
      if (currentX && currentY) setTransform({ x: currentX, y: currentY })
    }

    const drag = e => {
      e.preventDefault()

      if (e.type === 'touchmove') {
        currentX = e.touches[0].clientX - initialX
        currentY = e.touches[0].clientY - initialY

      } else {
        currentX = e.clientX - initialX
        currentY = e.clientY - initialY
      }

      currentX = currentX > 0 ? 0 : currentX < -width ? -width : currentX
      currentY = currentY < 0 ? 0 : currentY > height ? height : currentY
      xOffset = currentX
      yOffset = currentY

      mainRef.current.style.transform = `translate(${currentX}px, ${currentY}px)`
    }

    if (playground && mainRef.current && !details) {
      playground.addEventListener('touchstart', touchStart, false)
      playground.addEventListener('mousedown', mouseDown, false)
      playground.addEventListener('touchend', dragEnd, false)
      playground.addEventListener('touchmove', drag, false)
    }

    return () => {
      playground.removeEventListener('touchstart', touchStart, false)
      playground.removeEventListener('mousedown', mouseDown, false)
      playground.removeEventListener('touchend', dragEnd, false)
      playground.removeEventListener('touchmove', drag, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainRef, details])

  const toggleDetails = e => {
    showDetails(!details)
    if (mainRef.current) {
      mainRef.current.style.transform = `translate(${details ? `${transform.x}px, ${transform.y}px` : '0, 0'})`
    }
  }
  return (
    <div
      ref={mainRef}
      className={`announcements__main ${details ? '--full' : '--minimized'}`}>

      <div className='announcements__header'>
        <Notification
          ref={iconRef}
          onClick={toggleDetails} />

        {details && <>
          <h2>Notyfikacje</h2>
          <div
            className='close__icon'
            onClick={toggleDetails} ></div>
        </>}
      </div>

      {details && <div className='announcements__details'>
        {notification.map((n, i) => <div
          key={n}
          className='details__item'
          onClick={() => {
            toggleDetails()
            updateNotification(i)
            push(n.includes('wiadomości') ? '/chat' : '/orders')
          }}>{n}</div>
        )}
      </div>}
    </div>
  )
}

export default AnnouncementsIcon