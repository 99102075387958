import { ReactComponent as Share } from '../../../assets/icons/share.svg'
import PriceBtn from '../../buttons/PriceBtn'
import { ReactSVG } from 'react-svg'
import Popup from '../Popup'
import './style.scss'

const FoodDetailsPopup = ({ data, buyBtn, closeAction }) => {
  const { id, name, desc, price, src, icon } = data

  const share = async () => {
    const { origin, pathname } = window.location
    const url = `${origin}${pathname}?id=${id}`
    const shareData = {
      title: name,
      url,
    }

    if (navigator.share) {
      try { await navigator.share(shareData) }
      catch (err) { console.log(err) }
    } else copyToClipboard(url)
  }

  const copyToClipboard = text => {
    const temp = document.createElement('textarea')
    document.body.appendChild(temp)
    temp.value = text
    temp.select()
    document.execCommand('copy')
    document.body.removeChild(temp)
  }

  const header = src ? (
    <div className='popup__header'>
      <img src={src} alt={name} />
      <div className='popup__title'>{name}</div>
    </div>
  ) : (
    <div className='popup__header'>
      <ReactSVG src={icon} />
      <div className='popup__title'>{name}</div>
      <hr />
    </div>
  )

  return (
    <Popup closeAction={closeAction}>
      <div className='popup__main--foodDetails'>
        <div className='popup__top'>Dodaj do koszyka</div>
        {header}
        <div className='popup__description'>{desc}</div>
        <div className='popup__buttons'>
          <PriceBtn price={price} />
          <div className='button__share'
            onClick={share}>
            <Share />
          </div>
          {buyBtn}
        </div>
      </div>
    </Popup>
  )
}

export default FoodDetailsPopup