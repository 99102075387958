import { updateData } from '../../Finalization/formSlice'
import Loading from '../../../components/Loading'
import { Suspense, lazy, useState } from 'react'
import { STATUS } from '../../../rest/status'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import './style.scss'

const BarcodeScannerComponent = lazy(() => import('react-qr-barcode-scanner'))

const Barcode = ({ showError }) => {
  const [status, setStatus] = useState(STATUS.pennding)
  const dispatch = useDispatch()
  let { goBack, push } = useHistory()

  const mod = status === STATUS.succeed ? '--white' : '--normal'
  const back = () => window.history.state ? goBack() : push('/')

  const handleScan = (err, result) => {
    if (status !== STATUS.succeed) setStatus(STATUS.succeed)
    if (!result) return

    const number = result.text
    if (Number(number)) {
      dispatch(updateData({ name: 'cardNumber', value: number }))
      back()
    }
  }

  return (
    <div className='barcode'>
      <div className={`barcode__title ${mod}`}>
        Zeskanuj swoją kartę stałego klienta
      </div>
      {status === STATUS.pennding && <Loading />}
      {status === STATUS.succeed && <>
        <div className='barcode__frame --top' />
        <div className='barcode__frame --bottom' />
      </>}

      <Suspense fallback={<div />}>
        <div style={status !== STATUS.succeed ? { visibility: 'hidden' } : {}}>
          <BarcodeScannerComponent
            width={window.innerWidth}
            height={window.innerHeight}
            onError={() => {
              setStatus(STATUS.failed)
              showError()
            }}
            onUpdate={handleScan} />
        </div>
      </Suspense>

      <div
        className={`barcode__button ${mod}`}
        onClick={back}>Zamknij</div>
    </div>
  )
}

export default Barcode