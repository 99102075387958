import Comments from '../../inputs/Comments'
import { post } from '../../../rest/request'
import TextBtn from '../../buttons/TextBtn'
import { useEffect, useState } from 'react'
import { rate } from '../../../rest/urls'
import Star from '../../icons/Star'
import Popup from '../Popup'
import './style.scss'

const OpinionPopup = ({ order_ident, product_id, questions = [], answers: previousAnswers = {}, submitAnswers, closeAction }) => {
  const [answers, setAnswers] = useState({})
  const saveAnswer = (id, value) => setAnswers({
    ...answers,
    [`question_${id}`]: value
  })

  useEffect(() => setAnswers(Object.entries(previousAnswers)
    .reduce((r, [key, value]) => {
      r[key.includes('question') ? key : `question_${key}`] = value
      return r
    }, {})),
    [previousAnswers])

  const getStars = (id, scale) => {
    const value = answers[`question_${id}`] ? answers[`question_${id}`] : -1
    const stars = []

    for (let i = 1; i <= scale; i++)
      stars.push(<Star
        key={i}
        filled={i <= value}
        clickHandler={() => saveAnswer(id, i)} />)

    return <div className='stars'>{stars}</div>
  }

  const submitOpinion = async () => {
    const body = order_ident ? { order_ident, ...answers } : { product_id, ...answers }

    try {
      const response = await post(rate, body)
      console.log(response)
      if (response.wynik === 'ok') submitAnswers(answers)
    } catch (err) { console.log(err) }
  }

  return (
    <Popup closeAction={closeAction}>
      <div className='popup__main--opinion'>
        <h2 className='popup__title'>Wyraź swoją opinie</h2>

        <div className='popup__questions'>
          {questions.map(({ czy_aktywne, id, nazwa, skala }) => (
            czy_aktywne && <div key={id} className='question'>
              <h3>{nazwa}</h3>
              {skala ? getStars(id, skala) : <Comments
                value={answers[`question_${id}`] || ''}
                changeHandler={e => saveAnswer(id, e.target.value)} />}
            </div>))}
        </div>

        <TextBtn
          desc='Prześlij opinie'
          clickAction={submitOpinion} />
      </div>
    </Popup>
  )
}

export default OpinionPopup