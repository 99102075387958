import { loadState, saveState } from '../utils/storage'
const NAME = 'x-orders'

const OrdersManager = () => {
  let order = loadState(true, NAME, null)

  const get = () => order
  const set = o => {
    saveState(o, true, NAME)
    order = o
  }

  return {
    get,
    set
  }
}

export default OrdersManager()