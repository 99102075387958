import './style.scss'

const HistoryOfOrders = () => {
  return (
    <div className='orders__history'>
      <h2>Historia zamówień</h2>
    </div>
  )
}

export default HistoryOfOrders