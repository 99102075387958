import TextBtn from '../../buttons/TextBtn'
import Popup from '../Popup'
import './style.scss'

const OneBtnPopup = ({ title, description, btnText, btnAction, isCloseBtn = true, closeAction }) => {
  return (
    <Popup isCloseBtn={isCloseBtn} closeAction={closeAction}>
      <div className='popup__main--onebtn'>
        <h2 className='popup__title'>{title}</h2>
        <div className='popup__description'>{description}</div>
        <TextBtn desc={btnText} clickAction={btnAction} />
      </div>
    </Popup>
  )
}

export default OneBtnPopup