import TextBtn, { TEXT_BTN_TYPE } from '../../../components/buttons/TextBtn'
import { ReactComponent as Phone } from '../../../assets/icons/phone.svg'
import Loading from '../../../components/Loading'
import { STATUS } from '../../../rest/status'

const BlikVerification = ({ status, stopPayment }) => {
  return (
    <>
      <div className='blik__phone'>
        <Phone />
      </div>

      {status === STATUS.pennding ? <Loading middleOfPage={false} /> : <>
        <div className='blik__title'>
          <div>Teraz potwierdź</div>
          <div>płatność PINem za</div>
          <div>pomocą aplikacji w</div>
          <div>swoim telefonie</div>
        </div>

        <TextBtn
          desc='Lub przerwij transakcje'
          type={TEXT_BTN_TYPE.unfilled}
          clickAction={stopPayment} />
      </>}
    </>
  )
}

export default BlikVerification