import { ReactComponent as CleaningBrush } from '../../../assets/icons/cleaningBrush.svg'
import { ReactComponent as Basket } from '../../../assets/icons/shoppingBasket.svg'
import { ReactComponent as Trouble } from '../../../assets/icons/trouble.svg'
import { ReactComponent as Bill } from '../../../assets/icons/bill.svg'
import { ReactComponent as Chat } from '../../../assets/icons/chat.svg'
import { selectBasketPrice } from '../../../conteiners/Basket/basketSlice'
import { selectedTable } from '../../../conteiners/Scanner/tableSlice'
import { SCANNER_TYPE } from '../../../conteiners/Scanner'
import { post } from '../../../rest/request'
import TextBtn from '../../buttons/TextBtn'
import ServiceOption from './ServiceOption'
import { useState, useEffect } from 'react'
import { chat } from '../../../rest/urls'
import CleanUpTable from './CleanUpTable'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import OneBtnPopup from '../OneBtnPopup'
import BasicOption from './BasicOption'
import Popup from '../Popup'
import './style.scss'

const ServicePopup = ({ order_ident = 0, closeAction }) => {
  const [popupVisible, setPopupVisibility] = useState(0)
  const [errorPopup, setErrorPopup] = useState('')
  const [chats, setChats] = useState([])

  const finalPrice = useSelector(selectBasketPrice)
  const table = useSelector(selectedTable)

  let { push } = useHistory()

  const closeOption = error => {
    setPopupVisibility(0)
    error ? setErrorPopup(error) : push('/chat')
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await post(chat, { action: 'get_chat' })
        if (response.wynik === 'chat') setChats(response.chats)
      } catch (err) { console.log(err) }
    })()
  }, [])

  if (errorPopup) {
    return <OneBtnPopup
      title='Uwaga'
      btnText='OK'
      description={errorPopup}
      isCloseBtn={true}
      btnAction={() => setErrorPopup('')}
    />

  } else if (popupVisible) {
    return popupVisible === 1 ? <CleanUpTable
      order_ident={order_ident}
      closeAction={() => setPopupVisibility(0)} />
      : popupVisible === 4 ? <BasicOption
        submitAction={closeOption}
        closeAction={() => setPopupVisibility(0)} />
        : <ServiceOption
          id={popupVisible}
          order_ident={order_ident}
          submitAction={closeOption}
          closeAction={() => setPopupVisibility(0)} />
  }

  return (
    <Popup closeAction={closeAction}>
      <div className='popup__main--service'>
        <h2 className='service__title'>Kontakt z obsługą</h2>
        <div className='popup__description'>Jak możemy pomóc?</div>

        <div className='service__tiles'>
          <div className='service__tile'
            onClick={() => setPopupVisibility(1)}>
            <CleaningBrush />
            <div className='service__desc'>
              <div>Posprzątaj</div>
              <div>stolik</div>
            </div>
          </div>

          <div className='service__tile'
            onClick={() => setPopupVisibility(3)}>
            <Bill />
            <div className='service__desc'>
              <div>Poproś</div>
              <div>o rachunek</div>
            </div>
          </div>

          <div className='service__tile'
            onClick={() => setPopupVisibility(2)}>
            <Trouble />
            <div className='service__desc'>
              <div>Problem</div>
              <div>z zamówieniem</div>
            </div>
          </div>
        </div>

        {chats.length > 0 && <>
          <div className='popup__description'>Aktualnie prowadzone dyskusje</div>
          <div className='service__chats'>
            {chats.map(({ id, type_name, time, related }, index) => {
              const table = related ? related.table_number : ''
              const timeList = time ? time.split(' ') : []

              return <div
                key={index}
                className='chats__tile'
                onClick={() => push(`/chat?id=${id}`)}>
                <div className='service__desc'>{type_name}</div>
                <span>{timeList.length === 2 ? timeList[1] : time} </span>
                {table && <span>stolik: {table}</span>}
              </div>
            })}
          </div>
        </>}

        {Number(finalPrice) > 0 && <>
          <span className='button__desc'>Wykryto zamówienie w koszyku</span>
          <div className='service__button --basket'
            onClick={() => {
              closeAction()
              push('/basket')
            }}>
            <div className='button__basket --left'>
              <Basket />
              <div className='basket__desc'>W koszyku: {finalPrice}<sup>zł</sup></div>
            </div>

            <div className='button__basket --right'>
              <div className='basket__desc'>Finalizuj</div>
            </div>
          </div>
        </>}

        {!table && <TextBtn
          desc='Zeskanuj stolik aby zamówić'
          clickAction={() => push(`/scan?type=${SCANNER_TYPE.table}`)} />}

        <div className='service__button --chat'
          onClick={() => setPopupVisibility(4)}>
          <Chat />
          <span>Chat na żywo</span>
        </div>
      </div>
    </Popup>
  )
}

export default ServicePopup