import { ReactComponent as Plate } from '../../assets/icons/plate.svg'
import { ReactComponent as Osada } from '../../assets/icons/osada.svg'
import { ReactComponent as Cup } from '../../assets/icons/cup.svg'
import './style.scss'

const Logo = () => {
  return process.env.REACT_APP_CHRISTMAS_STORE === 'true' ? <Osada /> : (
    <div className='logo'>
      <Cup />
      <div className='logo__text'>
        <span className='--medium'>do</span>
        <span className='--light'>stolika</span>
      </div>
      <Plate />
    </div>
  )
}

export default Logo