import TextBtn, { TEXT_BTN_TYPE } from '../../components/buttons/TextBtn'
import { selectedForm, updateForm } from './reservationSlice'
import TimePicker from '../../components/selects/TimePicker'
import DateInput from '../../components/inputs/DateInput'
import Comments from '../../components/inputs/Comments'
import { phone as phoneRegex } from '../../utils/regex'
import { useDispatch, useSelector } from 'react-redux'
import Select from '../../components/selects/Select'
import Input from '../../components/inputs/Input'
import { useHistory } from 'react-router'
import { useState } from 'react'

const ReservationForm = ({ move }) => {
  const form = useSelector(selectedForm)
  const dispatch = useDispatch()

  let { goBack, push } = useHistory()
  let refs = []

  const [warning, setWarning] = useState({
    fullName: false,
    phone: false,
    people: false,
    time: false,
    date: false,
    type: false,
    place: false,
  })

  const changeHandler = e => {
    if (!e) return
    const { name, value } = e.target

    dispatch(updateForm({ name, value }))

    if ((name === 'fullName' && value.length > 2) ||
      (name === 'people' && value) ||
      (name === 'time' && value) ||
      (name === 'date' && value) ||
      (name === 'type' && value) ||
      (name === 'place' && value) ||
      (name === 'phone' && value.match(phoneRegex)))
      setWarning({
        ...warning,
        [name]: false,
      })

  }

  const blurHandler = e => {
    if (!e) return
    const { name, value } = e.target

    if ((name === 'fullName' && value.length < 2) ||
      (name === 'people' && !value) ||
      (name === 'time' && !value) ||
      (name === 'date' && !value) ||
      (name === 'type' && !value) ||
      (name === 'place' && !value) ||
      (name === 'phone' && !value.match(phoneRegex)))
      setWarning({
        ...warning,
        [name]: true,
      })
  }

  const submitForm = () => {
    const { fullName, phone, people, time, date, type, place } = form
    const phoneOK = phone.match(phoneRegex) !== null

    if (fullName.length > 2 && phoneOK && people && time && date && type && place) move(1)
    else setWarning({
      fullName: fullName.length < 2,
      phone: !phoneOK,
      people: !people,
      time: !time,
      date: !date,
      type: !type,
      place: !place,
    })
  }

  const enterClicked = ref => {
    const index = refs.findIndex(r => Object.is(r, ref))
    if (index !== undefined && refs[index + 1]) refs[index + 1].focus()
  }

  return (
    <>
      <h2>Rezerwacja stolika</h2>
      <p>Rezerwacja stolika jest płatna.<br />Koszt rezerwacji wynosi 20 zł - płatne z góry!</p>

      <div className='form__inputs'>
        <Input
          ref={r => refs.push(r)}
          value={form.fullName}
          warning={warning.fullName}
          name='fullName'
          placeholder='Imię i nazwisko'
          warningMessage='Wpisz imię i nazwisko'
          enterClicked={enterClicked}
          changeHandler={changeHandler}
          blurHandler={blurHandler}
          restAttr={{ minLength: '1', maxLength: '100' }} />

        <Input
          ref={r => refs.push(r)}
          value={form.phone}
          warning={warning.phone}
          name='phone'
          type='tel'
          placeholder='Numer telefonu'
          warningMessage='Wpisz numer telefonu'
          enterClicked={enterClicked}
          changeHandler={e => {
            e.target.value = e.target.value.replace(/(\d{3})(\d+)/g, '$1 $2')
            changeHandler(e)
          }}
          blurHandler={blurHandler} />

        <Input
          ref={r => refs.push(r)}
          value={form.people}
          warning={warning.people}
          type='number'
          name='people'
          placeholder='Ilość osób'
          warningMessage='Podaj ilość osób'
          enterClicked={enterClicked}
          changeHandler={changeHandler}
          blurHandler={blurHandler}
          restAttr={{ max: '1000' }} />

        <TimePicker
          today={false}
          selected={form.time}
          warning={warning.time}
          warningMessage='Podaj godzinę przybycia'
          changeHandler={changeHandler} />

        <DateInput
          value={form.date}
          warning={warning.date}
          placeholder='Data przybycia'
          warningMessage='Podaj datę przybycia'
          changeHandler={changeHandler}
          blurHandler={blurHandler} />

        <Select
          name='type'
          selected={form.type}
          placeholder='Rodzaj rezerwacji'
          warningMessage='Podaj rodzaj rezerwacji'
          warning={warning.type}
          options={[{value: 1, name: 'zwykła'}, {value: 2, name: 'niezwykła'}]}
          changeHandler={changeHandler} />

        <Select
          name='place'
          selected={form.place}
          placeholder='Miejsce rezerwacji'
          warningMessage='Podaj miejsce rezerwacji'
          warning={warning.place}
          options={[{value: 1, name: 'restauracja'}, {value: 2, name: 'ogród'}]}
          changeHandler={changeHandler} />

        <Comments
          value={form.comments}
          changeHandler={changeHandler} />
      </div>

      <div className='reservation__buttons'>
        <TextBtn
          desc='Przejdź dalej'
          clickAction={submitForm} />

        <TextBtn
          desc='Lub wróć'
          type={TEXT_BTN_TYPE.unfilled}
          clickAction={() => window.history.state ? goBack() : push('/')} />
      </div>
    </>
  )
}

export default ReservationForm