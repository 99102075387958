import FetchPopup from '../popups/FetchPopup'
import Checkbox from '../inputs/Checkbox'
import { useState } from 'react'

const RulesAgreement = ({ checked = false, warning = false, changeHandler }) => {
  const [termsPopupVisibile, setTermsPopupVisibility] = useState(false)
  const showTermsPopup = () => setTermsPopupVisibility(!termsPopupVisibile)
  const termsClicked = e => {
    e.preventDefault()
    showTermsPopup()
  }

  const termsStyle = {
    color: 'var(--main-color)',
    textDecoration: 'underline'
  }

  return (
    <>
      {termsPopupVisibile && <FetchPopup closeAction={showTermsPopup} />}
      <Checkbox
        name='rulesAgreement'
        label={<div>* Zapoznałem się i akceptuję <span style={termsStyle} onClick={termsClicked}>regulamin</span> oraz wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w formularzu w celu realizacji zamówienia.</div>}
        warning={warning}
        checked={checked}
        changeHandler={changeHandler} />
    </>
  )
}

export default RulesAgreement