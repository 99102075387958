import { ReactComponent as Friend } from '../../../assets/icons/friend.svg'
import TextBtn, { TEXT_BTN_TYPE } from '../../../components/buttons/TextBtn'
import OneBtnPopup from '../../../components/popups/OneBtnPopup'
import { selectBasketToRequest } from '../../Basket/basketSlice'
import { phone as phoneRegex } from '../../../utils/regex'
import { selectedData, updateData } from '../formSlice'
import { useDispatch, useSelector } from 'react-redux'
import { cart as cartUrl } from '../../../rest/urls'
import Input from '../../../components/inputs/Input'
import { post } from '../../../rest/request'
import { useHistory } from 'react-router'
import { useState } from 'react'
import './style.scss'

const SendToFriendForm = () => {
  const [popupVisibile, setPopupVisibility] = useState(false)
  const { cart, tips } = useSelector(selectBasketToRequest)
  const data = useSelector(selectedData)
  const dispatch = useDispatch()

  let { goBack, push } = useHistory()
  let refs = []

  const [warning, setWarning] = useState({
    name: false,
    friendPhone: false,
  })

  const back = () => window.history.state ? goBack() : push('/')
  const showPopup = () => setPopupVisibility(!popupVisibile)

  const changeHandler = e => {
    if (e) {
      const { name, value } = e.target

      dispatch(updateData({ name, value }))

      if ((name === 'name' && value.length > 0) ||
        (name === 'friendPhone' && value.match(phoneRegex)))
        setWarning({
          ...warning,
          [name]: false,
        })
    }
  }

  const blurHandler = e => {
    if (e) {
      const { name, value } = e.target

      if ((name === 'name' && value.length < 1) ||
        (name === 'friendPhone' && !value.match(phoneRegex)))
        setWarning({
          ...warning,
          [name]: true,
        })
    }
  }

  const enterClicked = ref => {
    const index = refs.findIndex(r => Object.is(r, ref))
    if (index !== undefined && refs[index + 1]) refs[index + 1].focus()
  }

  const submitForm = async () => {
    const { friendPhone, name } = data
    const phoneNum = friendPhone.replace(/[-| ]/g, '')
    const phoneOK = friendPhone.match(phoneRegex) !== null
    const body = { cart, tips }

    if (name && phoneOK) {
      body.orderToFriend = { myName: name, friendPhone: phoneNum, }
      console.log(body)
      try {
        const response = await post(cartUrl, body)
        console.log(response)
        if (response.success) showPopup()
      } catch (err) { console.log(err) }

    } else {
      setWarning({
        name: data.name.length < 1,
        friendPhone: !phoneOK,
      })
    }
  }

  const popup = <OneBtnPopup
    title='Przekazano znajomemu!'
    description='Twoje zamówienie zostało przekazane znajomemu do potwierdzenia.'
    btnText='OK, zamknij'
    isCloseBtn={false}
    btnAction={() => {
      showPopup()
      push('/')
    }} />

  return (
    <div className='friend__form'>
      {popupVisibile && popup}
      <div className='form__main'>
        <Friend />
        <h1 className='form__title'>Prześlij znajomemu</h1>

        <div className='form__inputs'>
          <Input
            ref={r => refs.push(r)}
            value={data.name}
            warning={warning.name}
            name='name'
            placeholder='Podaj swoje imię'
            warningMessage='Wpisz właściwe imię'
            enterClicked={enterClicked}
            changeHandler={changeHandler}
            blurHandler={blurHandler}
            restAttr={{ minLength: '1', maxLength: '60' }} />

          <Input
            ref={r => refs.push(r)}
            value={data.friendPhone}
            warning={warning.friendPhone}
            name='friendPhone'
            type='tel'
            placeholder='Wpisz numer tel. znajomego'
            warningMessage='Wpisz właściwy numer telefonu'
            enterClicked={enterClicked}
            changeHandler={e => {
              e.target.value = e.target.value.replace(/(\d{3})(\d+)/g, '$1 $2')
              changeHandler(e)
            }}
            blurHandler={blurHandler} />
        </div>

        <p className='form__text'>Wyślij swoje zamówienie znajomemu do opłacenia</p>

        <TextBtn
          desc='Prześlij zamówienie znajomemu'
          clickAction={submitForm} />
        <TextBtn
          desc='Lub wybierz inną formę'
          type={TEXT_BTN_TYPE.unfilled}
          clickAction={back} />
      </div>
    </div>
  )
}

export default SendToFriendForm