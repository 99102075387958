import { selectBasketPrice } from '../conteiners/Basket/basketSlice'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const RestrictedRoute = ({ path, children }) => {
  const price = useSelector(selectBasketPrice)

  return (
    <Route path={path}>
      {Number(price) ? children : <Redirect to='/categories' />}
    </Route>
  )
}