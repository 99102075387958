import OrdersManager from './OrdersManager'
import { getUuid } from './uuid'

const uuid = getUuid()

const basicRequest = async (resource, method = 'GET', body) => {
  const headers = new Headers({
    'Authorization': 'Bearer vCo8s9MsN0H1ZRpzGIgsk6jTiGKeQb0DGjfJMetCZDP5wJ6PYsLITG20tYrW',
    'Accept': 'application/json',
    'X-Client-Hash': uuid
  })

  const storedOrders = OrdersManager.get()
  if (storedOrders) headers.set('X-Orders', storedOrders)

  const init = {
    method,
    headers,
    credentials: 'include',
  }

  if (body) {
    init.body = JSON.stringify(body)
    init.headers.append('content-type', 'application/json')
  }

  try {
    const response = await fetch(resource, init)
    const orders = response.headers.get('X-Orders')
    const type = response.headers.get('content-type')
    if (orders) OrdersManager.set(orders)

    const data = type.includes('json') ? await response.json() : await response.text()
    if (response.ok) return data

    throw new Error(response.statusText)

  } catch (err) {
    return Promise.reject(err.message)
  }
}

export const get = resource => basicRequest(resource)
export const post = (resource, body) => basicRequest(resource, 'POST', body)