import { ReactComponent as Tap } from '../../../assets/icons/tap.svg'
import fb from '../../../assets/backgrounds/fb.png'
import TextBtn from '../../buttons/TextBtn'
import { useEffect } from 'react'
import './style.scss'

const FbPopup = ({ closeAction }) => {

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => document.body.style.overflow = 'auto'
  }, [])

  return (
    <div className='popup--fb'>
      <div className='popup__arrow'>
        <Tap />
      </div>
      
      <div className='popup__interior'>
        <div className='popup__main'>
          <h2 className='popup__title'>Nieobsługiwana przeglądarka</h2>
          <div className='popup__description'>Do prawidłowego działania prosimy o otworzenie w przeglądarce systemowej, np. Chrome</div>
          <img src={fb} alt='manual' />

          <div className='popup__button'>
            <TextBtn
              desc='OK'
              clickAction={closeAction} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FbPopup