import { useState, useEffect } from 'react'

const OrderTimer = ({ hour, date, stop }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [time, setTime] = useState('0:00')

  const calculateTime = (date = startDate) => {
    const timer = new Date(new Date() - date)
    const minutes = timer.getMinutes()
    setTime(`${timer.getHours() - 1}:${minutes < 10 ? '0' : ''}${minutes}`)
  }

  useEffect(() => {
    const tl = hour.split(':')
    const dl = date.split('.')
    if (tl.length > 1 && dl.length > 2) {
      const date = new Date(dl[2], dl[1] - 1, dl[0], tl[0], tl[1])
      setStartDate(date)
      calculateTime(date)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hour, date])

  useEffect(() => {
    let interval
    if (hour && date && !stop) interval = setInterval(calculateTime, 60000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, stop])

  return (
    <div className='order__timer'>
      <div className='order__text--large'>{time}</div>
      <div className='order__text'>Czas od zamówienia</div>
    </div>
  )
}

export default OrderTimer