const KEY_NAME = 'redux_state'

export const loadState = (session = false, name = KEY_NAME, defaultValue = {}) => {
  try {
    const storage = session ? sessionStorage : localStorage
    const state = storage.getItem(name)
    return !state ? defaultValue
      : typeof defaultValue === 'object' ? JSON.parse(state)
        : state

  } catch (err) {
    console.log('loadState', err)
    return defaultValue
  }
}

export const saveState = (state, session = false, name = KEY_NAME) => {
  try {
    const storage = session ? sessionStorage : localStorage
    const stringState = typeof state === 'string' ? state : JSON.stringify(state)
    storage.setItem(name, stringState)

  } catch (err) {
    console.log('saveState', err)
  }
}

export const loadAllState = () => {
  return { ...loadState(), ...loadState(true) }
}

export const saveAllState = state => {
  saveState(state.local)
  saveState(state.session, true)
}
