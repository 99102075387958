const FormHeader = ({ Icon, title, subTitle, clickHandler }) => {
  return (
    <header className='form__header'>
      <Icon />
      <h2 className='header__title'>{title}</h2>
      <p className='header__text'
        onClick={clickHandler}>{subTitle}</p>
    </header>
  )
}

export default FormHeader