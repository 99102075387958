import { useEffect, useRef, useState } from 'react'
import './style.scss'

const TipListElement = ({ value, text, options, sendData }) => {
  const [selectedOption, setSelectedOption] = useState(0)
  const [isInputFocus, setInputFocus] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    if (value > 0 && value < 1) setSelectedOption(2)
    else if (value === options[0].value) setSelectedOption(1)
    else if (value >= 1) {
      setSelectedOption(3)
      setInputValue(value)
    }
  }, [options, value])

  useEffect(() => {
    if (isInputFocus) inputRef.current.focus()
  }, [isInputFocus])

  const inputChange = e => {
    const value = e.target.value
    if (!value || value.match(/^[1-9]([0-9]{1,3})?(\.[0-9]{1,2})?$/)) {
      setInputValue(value)
      sendData(parseFloat(value))
    }
  }

  const selectOption = key => {
    setSelectedOption(key)
    sendData(key === 1 ? options[0].value : Number(`.${options[1].value[0]}`))
  }

  return (
    <div className='tipList__element'>
      <div className='list__text'>{text}</div>
      <div className='list__options'>
        <div className={`options__element ${selectedOption === 1 ? '--selected' : '--unselected'}`}
          onClick={() => selectOption(1)}>
          <span>{options[0].name}</span>
        </div>

        <div className={`options__element ${selectedOption === 2 ? '--selected' : '--unselected'}`}
          onClick={() => selectOption(2)}>
          <span>{options[1].name}</span>
        </div>

        <div className={`options__element ${selectedOption === 3 ? '--selected' : '--unselected'}`}
          onClick={() => {
            setSelectedOption(3)
            setInputFocus(true)
          }}>
          <input
            value={inputValue}
            ref={inputRef}
            type='number'
            min='1.00'
            max='9999.00'
            onChange={inputChange}
            style={selectedOption !== 3 ? { display: 'none' } : {}} />
          <span>{selectedOption === 3 ? 'zł' : 'Wpisz ręcznie'}</span>
        </div>
      </div>
    </div>
  )
}

export default TipListElement