import { selectedPhotoVisibility, selectedWideElements } from '../../Settings/settingsSlice'
import { ReactComponent as SortIcon } from '../../../assets/icons/sort.svg'
import DropDownList from '../../../components/DropDownList'
import { getDimensions } from '../../../utils/scroll'
import ListElementWide from './ListElementWide'
import Error from '../../../components/Error'
import { useEffect, useState } from 'react'
import ListElement from './ListElement'
import { useSelector } from 'react-redux'
import FoodHeader from '../FoodHeader'
import './style.scss'


const FoodList = ({ id, categories, selectedKey, sendKey }) => {

  const wideElements = useSelector(selectedWideElements)
  const photoVisibility = useSelector(selectedPhotoVisibility)
  const sectionRefs = []

  const sortOption = ['Domyślnie', 'Najtaniej', 'Najdrożej']
  const [selectedValue, setSelectedValue] = useState(sortOption[0])
  const [header, showHeader] = useState(true)

  useEffect(() => {
    let lastScrollPos = 200

    const handleScroll = () => {
      const scrollPosition = window.scrollY + 200;
      const selected = sectionRefs.find(({ ref }) => {
        if (ref) {
          const { offsetBottom, offsetTop } = getDimensions(ref)
          return scrollPosition > offsetTop && scrollPosition < offsetBottom
        } return false
      })

      if (scrollPosition > lastScrollPos) showHeader(false)
      else if (scrollPosition < lastScrollPos) showHeader(true)
      lastScrollPos = scrollPosition

      if (selected && selectedKey !== selected.key) {
        sendKey(selected.key)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey, categories])


  const elements = categories.map(category => {
    const { key, products } = category

    const elements = products ? [...products]
      .sort((a, b) => {
        switch (selectedValue) {
          case sortOption[1]:
            return a.gross_price - b.gross_price
          case sortOption[2]:
            return b.gross_price - a.gross_price
          default: return 0
        }
      })
      .map(food =>
        wideElements ? (
          <ListElementWide
            key={food.id}
            show={id === food.id}
            icon={category.icon}
            photoVisibility={photoVisibility}
            element={food} />
        ) : (
          <ListElement
            key={food.id}
            category={category}
            photoVisibility={photoVisibility}
            element={food} />)
      ) : false

    return (
      <div className='list__section'
        id={`food_list_section_${key}`}
        key={key}
        ref={ref => sectionRefs.push({ 'key': key, 'ref': ref })} >
        <FoodHeader data={category} />
        {elements}
      </div>
    )
  })

  return (
    <div className='food__list'>
      <div className={`list__header ${header ? '--down' : '--up'}`}>
        <div className='list__title'>Sortowanie</div>

        <div className='list__group'>
          <SortIcon />
          <div className='list__sort'>
            <DropDownList
              list={sortOption}
              selectedValue={selectedValue}
              changeValue={e => setSelectedValue(e)} />
          </div>
        </div>
      </div>
      
      <div className='list__main'>
        {elements.length > 0 ? elements : <Error text='Brak wyników' />}
      </div>
    </div>
  )
}

export default FoodList