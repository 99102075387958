import TextBtn, { TEXT_BTN_TYPE } from '../../buttons/TextBtn'
import Popup from '../Popup'
import './style.scss'

const TwoBtnPopup = ({ title = 'Czy jesteś pewien?', description = '', isCloseBtn = false, secondBtnReduced = true, firstBtnText = 'Tak', secondBtnText = 'Anuluj', firstBtnAction, secondBtnAction, closeAction }) => {
  return (
    <Popup
      isCloseBtn={isCloseBtn}
      closeAction={closeAction}>

      <div className='popup__main--twobtn'>
        <h2 className='popup__title'>{title}</h2>

        <div className='popup__description'>{description}</div>
        <div className='popup__buttons'>
          <TextBtn
            desc={firstBtnText}
            clickAction={firstBtnAction} />

          <TextBtn
            desc={secondBtnText}
            type={secondBtnReduced ? TEXT_BTN_TYPE.unfilled : TEXT_BTN_TYPE.normal}
            clickAction={secondBtnAction} />
        </div>

      </div>
    </Popup>
  )
}

export default TwoBtnPopup