import { ReactComponent as Plate } from '../../../assets/icons/plate.svg'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import './style.scss'

const CategoriesTiles = ({ path, isMobile, actualKey, data: { key, name, description: desc, icon } }) => {

  if (!isMobile) {
    const clicked = actualKey === key ? '--dim' : ''

    return (<Link
      to={`${path}categories/${key}`}
      className={`categories__tile--desktop ${clicked}`}>
      {name}
    </Link>)
  }

  return (
    <Link to={`${path}/${key}`} className='categories__tile'>
      <ReactSVG src={icon} />
      <div className='tile__title'>{name}</div>
      <Plate />
      <div className='tile__desc'>{desc}</div>
    </Link>
  )
}

export default CategoriesTiles