import { selectOrderStatus } from '../../../conteiners/Announcements/statusSlice'
import OrderPicker from '../../selects/OrderPicker'
import Comments from '../../inputs/Comments'
import TextBtn from '../../buttons/TextBtn'
import { post } from '../../../rest/request'
import { chat } from '../../../rest/urls'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import Popup from '../Popup'
import './style.scss'

const ServiceOption = ({ id, order_ident, submitAction, closeAction }) => {
  const orders = useSelector(selectOrderStatus)
  const [selectedOrder, selectOrder] = useState('')
  const [notes, setNotes] = useState('')
  const [warning, setWarning] = useState({
    order: false,
    notes: false,
  })

  const submitOption = async () => {
    const body = {
      action: 'new_request',
      help_type: id,
      order_ident: order_ident ? order_ident : selectedOrder,
      notes,
    }

    try {
      const response = await post(chat, body)
      console.log(response)
      if (response.wynik === 'ok') submitAction()
      else submitAction(response.error)
    }
    catch (err) { console.log(err) }
  }

  return (
    <Popup closeAction={closeAction}>
      <div className='popup__main--service'>
        <h2 className='popup__title'>{id === 3 ? 'Poprośba o rachunek' : 'Zgłaszanie stolika'}</h2>
        <div className='popup__description'>Czy jesteś pewien, że chcesz {id === 3 ? 'poprosić o rachunek' : 'zgłosić problem'}?</div>

        <div className='popup__inputs'>
          <OrderPicker
            orders={orders}
            warning={warning.order}
            selected={order_ident}
            changeHandler={e => {
              selectOrder(e.target.value)
              setWarning({
                ...warning,
                order: false
              })
            }} />

          <Comments
            value={notes}
            placeholder='Uwagi'
            changeHandler={e => {
              setNotes(e.target.value)
              if (e.target.value) setWarning({
                ...warning,
                notes: false
              })
            }} />

          {warning.notes && <span className='input__warning'>Musisz podać uwagi</span>}
        </div>

        <TextBtn
          desc={`Tak, ${id === 3 ? 'proszę o rachunek' : 'zgłaszam problem'}`}
          clickAction={() => {
            if ((order_ident || selectedOrder) && ((id === 2 && notes) || id === 3)) submitOption()
            else setWarning({
              order: !selectedOrder ? true : false,
              notes: id === 2 && !notes ? true : false,
            })
          }} />
      </div>
    </Popup>
  )
}

export default ServiceOption