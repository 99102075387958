import OneBtnPopup from '../../components/popups/OneBtnPopup'
import FbPopup from '../../components/popups/FbPopup'
import { isFacebookApp } from '../../utils/browser'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Barcode from './Barcode'
import Qr from './Qr'

export const SCANNER_TYPE = {
  status: 'status',
  table: 'table',
  cleanUp: 'cleanUp',
  barcode: 'barcode',
}

const Scanner = ({ type }) => {
  const [errorPopupVisible, setErrorPopupVisibility] = useState(false)
  const [isFb, setFb] = useState(false)

  let { goBack, push } = useHistory()

  useEffect(() => {
    if (isFacebookApp()) setFb(true)
  }, [])

  const showErrorPopup = () => setErrorPopupVisibility(!errorPopupVisible)

  const errorPopup = <OneBtnPopup
    title='Uwaga'
    description='Niestety nie jest możliwe uruchomienie kamery'
    btnText='OK'
    isCloseBtn={false}
    btnAction={() => {
      showErrorPopup()
      window.history.state ? goBack() : push('/')
    }} />

  const wrongBrowserPopup = <FbPopup
    closeAction={() => window.history.state ? goBack() : push('/')} />

  return (
    <>
      {errorPopupVisible && errorPopup}
      {isFb ? wrongBrowserPopup :
        type === SCANNER_TYPE.barcode ? <Barcode showError={showErrorPopup} />
          : <Qr type={type} showError={showErrorPopup} />}
    </>
  )
}

export default Scanner