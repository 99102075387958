import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { config } from '../../rest/urls'
import { get } from '../../rest/request'

export const fetchConfig = createAsyncThunk('settings/fetchConfig', async () => {
  const response = await get(config)
  return response
})

const THEMES = {
  light: 'light',
  dark: 'dark'
}

export const COLORS = ['gold', 'red', 'blue', 'green', 'pink']

const companyData = {
  address: {
    street: 'Graniczna 46',
    postalCode: '95-200',
    city: 'Pabianice',
  },
  numbers: ['+48 42 227 58 48', '+48 500 25 20 20'],
  mail: 'osada@osadarybacka.pl',
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    theme: THEMES.light,
    mainColor: COLORS[process.env.REACT_APP_CHRISTMAS_STORE === 'true' ? 1 : 0],
    visiblePhoto: true,
    wideElements: true,
    companyData,
    config: {},
  },
  reducers: {
    setLightMode: state => {
      state.theme = THEMES.light
    },
    setDarkMode: state => {
      state.theme = THEMES.dark
    },
    setMainColor: (state, action) => {
      state.mainColor = action.payload
    },
    changePhotoVisibility: state => {
      state.visiblePhoto = !state.visiblePhoto
    },
  },
  extraReducers: {
    [fetchConfig.fulfilled]: (state, action) => {
      state.config = action.payload
    },
  }
})

export const { setLightMode, setDarkMode, setMainColor, changePhotoVisibility } = settingsSlice.actions

export const isDarkMode = state => state.settings.theme === THEMES.dark

export const selectedTheme = state => state.settings.theme
export const selectedConfig = state => state.settings.config
export const selectedMainColor = state => state.settings.mainColor
export const selectedCompanyData = state => state.settings.companyData
export const selectedWideElements = state => state.settings.wideElements
export const selectedPhotoVisibility = state => state.settings.visiblePhoto

export default settingsSlice.reducer
