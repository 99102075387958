import staffAvatar from '../../assets/icons/staffAvatar.png'
import UserAvatar from '../../components/icons/User'

const Message = ({ isAvatar, author, message, time }) => {
  const authorMod = author === 'user' ? '--user' : '--restaurant'
  const marginMod = isAvatar ? '--bottom' : ''
  const timeList = time ? time.split(' ') : []

  return <div className={`receiver__message ${authorMod} ${marginMod}`}>

    <div className='message__main'>
      <span>{message}</span>
      <div className='message__time'>{timeList.length === 2 ? timeList[1] : time}</div>
    </div>

    <div
      className='message__avatar'>
      {isAvatar ? author === 'user' ? <UserAvatar /> : <img src={staffAvatar} alt='staff' /> : false}
    </div>
  </div>
}

export default Message