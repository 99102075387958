import FinalizationForm from '../../conteiners/Finalization/FinalizationForm'
import SendToFriendForm from '../../conteiners/Finalization/SendToFriendForm'
import Finalization, { FORM_TYPE } from '../../conteiners/Finalization'
import { Switch, Route, useLocation, Redirect } from 'react-router'
import OrderForm from '../../conteiners/Finalization/OrderForm'
import { RestrictedRoute } from '../../utils/RestrictedRoute'
import Restaurant from '../../conteiners/Payment/Restaurant'
import OrderStatus from '../../conteiners/Home/OrderStatus'
import TableStatus from '../../conteiners/Home/TableStatus'
import Menu, { MENU_TYPE } from '../../conteiners/Menu'
import Reservation from '../../conteiners/Reservation'
import Categories from '../../conteiners/Categories'
import Banks from '../../conteiners/Payment/Banks'
import Blik from '../../conteiners/Payment/Blik'
import Payment from '../../conteiners/Payment'
import Scanner from '../../conteiners/Scanner'
import Orders from '../../conteiners/Orders'
import Basket from '../../conteiners/Basket'
import Break from '../../conteiners/Break'
import Food from '../../conteiners/Food'
import Chat from '../../conteiners/Chat'
import Home from '../../conteiners/Home'
import { useEffect } from 'react'
import ReactGA from 'react-ga'
import './style.scss'

const Mobile = () => {
  const { pathname, search } = useLocation()

  const query = new URLSearchParams(search)
  const checkout = query.get('checkout')
  const type = query.get('type')
  const id = query.get('id')

  useEffect(() => ReactGA.initialize('UA-125828517-1'), [])

  useEffect(() => ReactGA.pageview(pathname + search)
    , [pathname, search])

  const typeExists = () =>
    Object.entries(FORM_TYPE).find(([key, value]) => value === type) !== undefined

  const menuSearch = <Menu type={MENU_TYPE.search} />
  const menuCategories = <Menu type={MENU_TYPE.categories} />

  return (
    <div className='main--mobile'>
      <Switch>
        <Route exact path='/'>
          <Home />
          <Menu />
        </Route>

        <Route exact path='/basket'>
          <Basket />
          {menuCategories}
        </Route>

        <Route exact path='/break'>
          <Break />
        </Route>

        <Route exact path='/categories'>
          <Categories />
          {menuSearch}
        </Route>

        <Route path='/categories/:foodKey'>
          <Food id={id ? id : ''} />
          {menuSearch}
        </Route>

        <Route exact path='/chat'>
          <Chat id={id ? id : ''} />
        </Route>

        <RestrictedRoute exact path='/finalization'>
          <Finalization />
          {menuCategories}
        </RestrictedRoute >

        <RestrictedRoute exact path='/finalization/form'>
          {typeExists() ? (
            <>
              {type === FORM_TYPE.chrisrtmasOrder ? <OrderForm />
                : type === FORM_TYPE.sendToFriend ? <SendToFriendForm /> : <FinalizationForm type={type} />}
              {menuCategories}
            </>
          ) : <Redirect to='/finalization' />}
        </RestrictedRoute>

        <RestrictedRoute exact path='/finalization/payment'>
          {typeExists() && type !== FORM_TYPE.sendToFriend ? (
            <>
              <Payment type={type} />
              {menuCategories}
            </>
          ) : <Redirect to='/finalization' />}
        </RestrictedRoute>

        <RestrictedRoute exact path='/finalization/payment/banks'>
          <Banks />
          {menuCategories}
        </RestrictedRoute>

        <RestrictedRoute exact path='/finalization/payment/blik'>
          <Blik />
          {menuCategories}
        </RestrictedRoute>

        <RestrictedRoute exact path='/finalization/payment/restaurant'>
          {checkout ? (
            <>
              <Restaurant checkout={checkout} />
              {menuCategories}
            </>
          ) : <Redirect to='/finalization' />}
        </RestrictedRoute>

        <Route exact path='/orders'>
          <Orders />
          {menuCategories}
        </Route>

        <Route exact path='/reservation'>
          <Reservation />
          {menuCategories}
        </Route>

        <Route exact path='/scan'>
          <Scanner type={type} />
        </Route>

        <Route exact path='/status/:code'>
          <TableStatus status />
        </Route>

        <Route exact path='/stolik/:code'>
          <TableStatus />
        </Route>

        <Route exact path='/zam/:code'>
          <OrderStatus />
        </Route>

        <Redirect to='/' />
      </Switch>
    </div>
  )
}

export default Mobile