import './style.scss'

const PriceBtn = ({ isWhite = false, price }) => {
  return (
    <button className={`button__price ${isWhite ? '--white' : ''}`}>
      <div className='button__description'>
        {price.toFixed(2)}<sup>zł</sup>
      </div>
    </button>
  )
}

export default PriceBtn