import { ReactComponent as Arrows } from '../../../assets/icons/arrowUpDown.svg'
import { ReactComponent as Calendar } from '../../../assets/icons/calendar.svg'
import { ReactComponent as Warning } from '../../../assets/icons/warning.svg'
import CalendarPopup from './CalendarPopup'
import { useState } from 'react'
import './style.scss'

const DateInput = ({ value, warning = false, options, placeholder, warningMessage = '', changeHandler }) => {
  const [calendarPopup, setCalendarPopup] = useState(false)
  const showCalendarPopup = () => setCalendarPopup(!calendarPopup)

  const change = date => changeHandler({ target: { name: 'date', value: date } })

  const cPopup = <CalendarPopup
    value={value}
    options={options}
    change={change}
    close={showCalendarPopup} />

  return (
    <div className='date'>
      {calendarPopup && cPopup}

      <div className='date__container'>
        <div className='date__icon --left'>
          {warning ? <Warning /> : <Calendar />}
        </div>

        <div className='date__icon --right'>
          <Arrows />
        </div>

        {!value && <span className='date__placeholder'>{placeholder}</span>}

        <div
          className={`date__input ${warning ? '--warning' : ''}`}
          onClick={showCalendarPopup}>{value}</div>

        {warning && <span className='date__warning'>
          {warningMessage}
        </span>}
      </div>
    </div>
  )
}

export default DateInput