import reservationReducer from './conteiners/Reservation/reservationSlice'
import categoriesReducer from './conteiners/Categories/categoriesSlice'
import statusReducer from './conteiners/Announcements/statusSlice'
import settingsReducer from './conteiners/Settings/settingsSlice'
import formReducer from './conteiners/Finalization/formSlice'
import basketReducer from './conteiners/Basket/basketSlice'
import tableReducer from './conteiners/Scanner/tableSlice'
import { loadAllState, saveAllState } from './utils/storage'
import { configureStore } from '@reduxjs/toolkit'
import { get } from './rest/request'

const preloadedState = loadAllState()

const reducer = {
  basket: basketReducer,
  categories: categoriesReducer,
  form: formReducer,
  reservation: reservationReducer,
  settings: settingsReducer,
  status: statusReducer,
  table: tableReducer,
}

const store = configureStore({
  preloadedState,
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: { extraArgument: get },
      serializableCheck: false,
    })
})

const getSelectedState = () => {
  const { settings, basket, form, table } = store.getState()
  return {
    local: {
      settings,
      form,
    },
    session: {
      basket,
      table,
    },
  }
}

store.subscribe(() => saveAllState(getSelectedState()))

export default store
