const Weight = ({ white = false }) => {
  const color = white ? '#fff' : 'var(--main-color)'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.053" height="13.686" viewBox="0 0 21.053 13.686">
      <g id="waga" transform="translate(-755.65 206.017)">
        <path id="Path_180" data-name="Path 180" d="M776.036-205.667H756.318a.318.318,0,0,0-.318.318,3.343,3.343,0,0,0,3.339,3.339h4.85v1.113h-2.045a1.982,1.982,0,0,0-1.917,1.48l-1.659,6.337a.318.318,0,0,0,.056.275.318.318,0,0,0,.252.124H773.16a.318.318,0,0,0,.253-.125.318.318,0,0,0,.055-.277l-1.718-6.35a1.985,1.985,0,0,0-1.913-1.464h-1.672v-1.113h4.85a3.343,3.343,0,0,0,3.339-3.339A.318.318,0,0,0,776.036-205.667Zm-6.2,5.406a1.348,1.348,0,0,1,1.3.994l1.609,5.949H759.288l1.554-5.939a1.346,1.346,0,0,1,1.3-1Zm-5.011-.636v-1.113h2.7v1.113Zm8.189-1.749H759.339a2.707,2.707,0,0,1-2.685-2.385H775.7A2.707,2.707,0,0,1,773.014-202.646Z" transform="translate(0 0)" fill={color} stroke={color} strokeLinecap="round" strokeWidth="0.7" />
        <path id="Path_181" data-name="Path 181" d="M801.97-163.643a3.224,3.224,0,0,0,3.22-3.22,3.224,3.224,0,0,0-3.22-3.22,3.224,3.224,0,0,0-3.22,3.22A3.224,3.224,0,0,0,801.97-163.643Zm0-5.8a2.587,2.587,0,0,1,2.584,2.584,2.587,2.587,0,0,1-2.584,2.584,2.587,2.587,0,0,1-2.584-2.584A2.587,2.587,0,0,1,801.97-169.447Z" transform="translate(-35.952 -29.926)" fill={color} stroke={color} strokeLinecap="round" strokeWidth="0.7" />
      </g>
    </svg>
  )
}

export default Weight