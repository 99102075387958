import { addOrder, updateOrder } from '../../../conteiners/Basket/basketSlice'
import TextBtn, { TEXT_BTN_TYPE } from '../../buttons/TextBtn'
import { christmasorders } from '../../../rest/urls'
import { post } from '../../../rest/request'
import { STATUS } from '../../../rest/status'
import Comments from '../../inputs/Comments'
import FoodCounter from '../../FoodCounter'
import Select from '../../selects/Select'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Loading from '../../Loading'
import Popup from '../Popup'
import Range from './Range'
import './style.scss'

const OrderPopup = ({ id, name = '', price, index = -1, order = {}, screen = 0, closeAction }) => {
  const [status, setStatus] = useState(STATUS.idle)
  const [screenIdx, setScreenIdx] = useState(0)
  const [warning, setWarning] = useState(false)
  const [screens, setScreens] = useState([])
  const [error, setError] = useState('')
  const [form, setForm] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    setStatus(STATUS.pennding);

    (async () => {
      try {
        const body = { action: 'GetOrderForm', product_id: id }
        const response = await post(christmasorders, body)

        if (response.wynik === 'ok') {
          const { screens: s } = response.form
          setScreens(s)
          setStatus(STATUS.succeed)

        } else if (response.wynik === 'error') {
          setError(response.error)
          setStatus(STATUS.failed)
        }
      } catch (err) {
        console.log(err)
        setError('Coś poszło nie tak')
        setStatus(STATUS.failed)
      }
    })()
  }, [id])

  useEffect(() => {
    if (screen) {
      const idx = screens.findIndex(({ EKR_ID }) => EKR_ID === screen)
      if (idx > -1) setScreenIdx(idx)
    }
  }, [screen, screens])

  useEffect(() => {
    if (status === STATUS.succeed && screens[screenIdx]) {
      let newForm

      if (warning) setWarning(false)
      if (screens[screenIdx].EKR_ID === screen) {
        const numfield = screens[screenIdx].fields.find(({ type }) => type === 'number')
        const numfieldId = numfield ? `${numfield.field_id}` : '-1'
        newForm = Object.entries(order)
          .reduce((r, [key, value]) => {
            const val = numfieldId === key ? Number(value)
              : value.min && value.max ? [Number(value.min), Number(value.max)]
                : Array.isArray(value) ? value.map(e => Number(e)) : value

            return { ...r, [key]: val }
          }, {})

      } else {
        newForm = screens[screenIdx].fields
          .filter(({ type }) => type === 'number' || type === 'select' || type === 'slider' || type === 'hidden')
          .reduce((r, e) => {
            const { field_id, type } = e
            const value = type === 'number' ? Number(e.min_value) || 1
              : type === 'slider' ? [Number(e.start[0]), Number(e.start[1])]
                : type === 'select' ? [e.options[0].value] : e.value

            return { ...r, [field_id]: value }
          }, {})
      }

      setForm(newForm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, screens, screenIdx])

  const updateForm = (id, value) => setForm({
    ...form,
    [id]: value
  })

  const submitForm = () => {
    if (!screens[screenIdx]) return
    const EKR_ID = screens[screenIdx].EKR_ID
    const fields = screens[screenIdx].fields
    const select = fields.find(({ type }) => type === 'select')

    if (select) {
      const v = form[select.field_id]
      const min = select.min_items_selected
      if (min && (!v || (Array.isArray(v) && v.length < min))) {
        setWarning(true)
        return
      }
    }

    let sliderId = '2'
    const finalPrice = fields.reduce((r, { field_id, type }) => {
      if ((type === 'number' || type === 'hidden') && form[field_id]) {
        r *= form[field_id]

      } else if (type === 'slider' && Array.isArray(form[field_id])) {
        sliderId = `${field_id}`
        r *= ((form[field_id][0] + form[field_id][1]) / 2)
      }

      return r
    }, price)

    const order_zpo = Object.entries(form).reduce((r, [key, value]) => {
      if (key === sliderId) {
        const min = (value[0] > value[1] ? value[1] : value[0]).toFixed(2)
        const max = (value[0] > value[1] ? value[0] : value[1]).toFixed(2)
        return { ...r, [key]: { min, max } }

      } else if (Array.isArray(value)) {
        const list = value.map(e => `${e}`)
        return { ...r, [key]: list }

      } else return { ...r, [key]: `${value}` }
    }, {})

    dispatch(index > -1 ? updateOrder({ index, EKR_ID, order_zpo, fields, finalPrice })
      : addOrder({ id, name, EKR_ID, order_zpo, fields, unitPrice: price, finalPrice }))

    closeAction(true)
  }

  return (
    <Popup
      isCloseBtn={status === STATUS.failed}
      closeAction={closeAction}>

      <div className='popup--order'>
        {status === STATUS.failed ? <div className='popup__error'>{error}</div>
          : status === STATUS.succeed ? <div>
            <div className='popup__nav'>
              {screens.map(({ EKR_ID, EKR_Name }, idx) => <div
                key={EKR_ID}
                className={`nav__item ${idx === screenIdx - 1 ? '--left' : ''} ${idx === screenIdx + 1 ? '--right' : ''} ${idx === screenIdx ? '--selected' : ''}`}
                style={screens.length > 2 ? { width: '33.3%' } : { width: '50%' }}
                onClick={() => setScreenIdx(idx)}>
                <span>{EKR_Name}</span>
              </div>)}
            </div>

            <div className='popup__main'>
              <div className='popup__title'>{name}</div>

              <div className='popup__form'>
                {screens[screenIdx] && screens[screenIdx].fields.map(e => {
                  const { description, field_id, step, type } = e

                  return <div
                    key={field_id}
                    className='form__item'>

                    {description && <p>{description}</p>}

                    {type === 'number' && <FoodCounter
                      amount={form[field_id]}
                      amountChanged={a => updateForm(field_id, a)}
                      min={Number(e.min_value || step)}
                      max={Number(e.max_value)}
                      step={Number(step)}
                      editable />}

                    {type === 'slider' && <Range
                      min={Number(e.min_value)}
                      max={Number(e.max_value)}
                      step={Number(step)}
                      value={form[field_id]}
                      setValue={r => updateForm(field_id, r)} />}

                    {type === 'select' && <Select
                      name='type'
                      selected={e.max_items_selected === '1' || e.max_items_selected === '' ? form[field_id] ? form[field_id][0] : '' : form[field_id] || []}
                      warning={warning}
                      options={e.options}
                      warningMessage={`Musisz wybrać minimum: ${e.min_items_selected || 1} opcję`}
                      multiple={e.max_items_selected > 1}
                      changeHandler={ev => {
                        const { selectedOptions: selected } = ev.target
                        const min = e.min_items_selected
                        const max = e.max_items_selected || 1
                        let newList = []

                        for (let i = selected.length - 1; i >= 0; i--) {
                          const val = Number(selected[i].value)
                          if (newList.length >= max) break
                          else newList.push(val)
                        }

                        if (min && warning && newList.length >= min) setWarning(false)
                        newList.reverse()
                        updateForm(field_id, newList)
                      }} />}

                    {type === 'text' && <Comments
                      value={form[field_id]}
                      placeholder={description}
                      changeHandler={ev => updateForm(field_id, ev.target.value)} />}
                  </div>
                })}
              </div>

              <div className='popup__buttons'>
                <TextBtn
                  desc='Zamów'
                  clickAction={submitForm} />

                <TextBtn
                  desc='Wróć'
                  type={TEXT_BTN_TYPE.unfilled}
                  clickAction={() => closeAction()} />
              </div>
            </div>

          </div> : <Loading middleOfPage={false} />}
      </div>
    </Popup>
  )
}

export default OrderPopup