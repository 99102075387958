import { isDarkMode, setDarkMode, setLightMode } from '../../conteiners/Settings/settingsSlice'
import { ReactComponent as Moon } from '../../assets/icons/moon.svg'
import { ReactComponent as Sun } from '../../assets/icons/sun.svg'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'

const ThemeSwitcher = () => {
  const darkMode = useSelector(isDarkMode)
  const dispatch = useDispatch()

  const themeChanged = () => {
    dispatch(darkMode ? setLightMode() : setDarkMode())
  }

  return (
    <div
      className='theme__switcher'
      onClick={themeChanged}>
      {darkMode ? <Sun /> : <Moon />}
    </div>
  )
}

export default ThemeSwitcher