import { ReactComponent as Warning } from '../../../assets/icons/warning.svg'
import { forwardRef } from 'react'
import './style.scss'

const Input = forwardRef(({ name, type = 'text', value, warning = false, placeholder, warningMessage = '', enterClicked, changeHandler, blurHandler, restAttr = {} }, ref) => {
  const onKeyDown = e => {
    if (e.keyCode === 13) {
      e.target.blur()
      ref && enterClicked(e.target)
    }
  }

  return (
    <div className='input__container'>
      {warning && <div className='input__icon'>
        <Warning />
      </div>}

      <input
        className={`input ${warning ? '--warning' : ''}`}
        ref={ref}
        value={value}
        name={name}
        type={type}
        {...restAttr}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onChange={changeHandler}
        onBlur={blurHandler} />

      {warning && <span className='input__warning'>
        {value ? warningMessage : placeholder}
      </span>}
    </div>
  )
})

export default Input