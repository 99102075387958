import TextBtn, { TEXT_BTN_TYPE } from '../buttons/TextBtn'
import { useEffect, useState } from 'react'
import './style.scss'

const FoodCounter = ({ amount = 1, amountChanged, min = '', max = '', step = 1, editable = false }) => {
  const [value, setValue] = useState(amount)

  useEffect(() => setValue(amount), [amount])

  const updateAmount = difference => {
    const newAmount = Number((amount + difference).toFixed(1))
    if (newAmount >= min && (!max || newAmount <= max)) amountChanged(newAmount)
  }

  return (
    <div className='foodCounter'>
      <TextBtn
        desc='-'
        type={TEXT_BTN_TYPE.border}
        clickAction={() => updateAmount(-step)} />

      {editable ? <input
        value={value}
        className='counter__input'
        type='tel'
        onBlur={() => amountChanged(value > 0 ? Number(value) : 1)}
        onKeyDown={e => e.keyCode === 13 && e.target.blur()}
        onChange={e => {
          const { value: v } = e.target
          if (!v || step < 1 ? v.match(/^[0-9]+[.]?[0-9]?$/) : v.match(/^[0-9]+$/)) setValue(v)
        }}
      /> : <div className='counter__title'>{amount}</div>}

      <TextBtn
        desc='+'
        type={TEXT_BTN_TYPE.border}
        clickAction={() => updateAmount(step)} />
    </div>
  )
}

export default FoodCounter