import { ReactSVG } from 'react-svg'
import './style.scss'

const IconTextBtn = ({ isFilled = true, isEnlarged = true, isColumnDirection = false, Icon, desc = 'Do koszyka', clickAction }) => {
  const filled = isFilled ? '--filled' : ''
  const enlarged = isEnlarged ? '--enlarged' : ''
  const column = isColumnDirection ? '--column' : ''

  return (
    <button className={`button__iconText ${filled} ${enlarged} ${column}`} onClick={clickAction}>
      {typeof Icon === 'string' ? <ReactSVG src={Icon} /> : <Icon />}
      <div className='button__description'>{desc}</div>
    </button>
  )
}

export default IconTextBtn