import { selectedForm, updateForm } from './reservationSlice'
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg'
import RulesAgreement from '../../components/RulesAgreement'
import { useDispatch, useSelector } from 'react-redux'

const Summary = ({ rulesWarning = false }) => {
  const { fullName, date, time, comments, rulesAgreement } = useSelector(selectedForm)
  const dispatch = useDispatch()
  const dl = date.split('-')

  const changeHandler = e => {
    if (!e) return
    const { name, checked } = e.target
    dispatch(updateForm({ name, value: checked }))
  }

  return (
    <>
      <h2>Podsumowanie</h2>
      <p>Sprawdź czy wszystkie dane są poprawne.<br />Następnie opłać rezerwację.</p>

      <div className='reservation__summary'>
        <div>
          <div className='summary__title'>{fullName}</div>
          <div className='summary__group'>
            <Calendar />
            {dl.length > 2 && <span className='summary__text'>{dl[2]}.{dl[1]}.{dl[0]}</span>}
            {time && <span className='summary__text'>, godz. {time}</span>}
          </div>
          <hr />
        </div>

        {comments && <div>
          <div className='summary__title'>Uwagi do zamówienia</div>
          <div className='summary__text'>"{comments}"</div>
        </div>}


        <RulesAgreement
          checked={rulesAgreement}
          warning={rulesWarning}
          changeHandler={changeHandler} />

      </div>
    </>
  )
}

export default Summary