import { ReactComponent as CleaningBrush } from '../../assets/icons/cleaningBrush.svg'
import { ReactComponent as DiningRoom } from '../../assets/icons/diningRoom.svg'
import { ReactComponent as NewOrder } from '../../assets/icons/newOrder.svg'
import { ReactComponent as QrCode } from '../../assets/icons/qrCode.svg'
import { ReactComponent as List } from '../../assets/icons/list.svg'
import CleanUpTable from '../../components/popups/ServicePopup/CleanUpTable'
import { getBannersList } from '../../components/Banners/getBannersList'
import IconTextWideBtn from '../../components/buttons/IconTextWideBtn'
import OneBtnPopup from '../../components/popups/OneBtnPopup'
import { selectedConfig } from '../Settings/settingsSlice'
import { selectedTable } from '../Scanner/tableSlice'
import { useEffect, useState, useRef } from 'react'
import Banners from '../../components/Banners'
import { useLocation } from 'react-router-dom'
import { SCANNER_TYPE } from '../Scanner'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Logo from '../../components/Logo'
import Settings from '../Settings'
import './style.scss'

const Home = () => {
  const isChristmasStore = process.env.REACT_APP_CHRISTMAS_STORE === 'true'
  const [cleanUpPopupVisible, setCleanUpPopupVisibility] = useState(false)
  const [tablePopupVisible, setTablePopupVisibility] = useState(false)
  const [bannersList, setbannerList] = useState([])
  const [margins, setMargins] = useState(0)

  const { banners, christmas_homepage, working_hours } = useSelector(selectedConfig)
  const table = useSelector(selectedTable)
  const mainRef = useRef(null)

  let { search } = useLocation()
  let { push, replace } = useHistory()

  const cleanUpClick = () => setCleanUpPopupVisibility(!cleanUpPopupVisible)
  const removeTablePopup = () => {
    setTablePopupVisibility(false)
    replace('/')
  }

  useEffect(() => {
    const query = new URLSearchParams(search)
    const scanned = query.get('scanned')

    if (scanned) setTablePopupVisibility(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    const calculateMargins = () => {
      const workSpace = window.innerHeight - 169
      const filledSpace = mainRef.current?.clientHeight || 418
      const freeSpace = workSpace - filledSpace
      return freeSpace > 36 ? freeSpace : 36
    }

    if (banners && banners.collection && !bannersList.length) {
      const newList = getBannersList(banners, true)

      if (newList.length) {
        setbannerList(newList)
        setMargins(calculateMargins())
        window.scroll(0, 0)
      }
    }
  }, [banners, bannersList.length])

  return (
    <div className='home'>
      {tablePopupVisible && <OneBtnPopup
        title='Zeskanowano stolik'
        btnText='OK'
        description={table}
        isCloseBtn={true}
        closeAction={removeTablePopup}
        btnAction={removeTablePopup}
      />}

      {cleanUpPopupVisible && <CleanUpTable
        closeAction={cleanUpClick} />}

      <Settings />

      <div
        ref={mainRef}
        className='home__main'
        style={bannersList.length ? { marginTop: (margins * .6), marginBottom: (margins * .4) } : {}}>
        <Logo />

        {isChristmasStore && christmas_homepage ? <div dangerouslySetInnerHTML={{ __html: christmas_homepage }} />
          : <>
            <div className='home__title'>Witaj w {isChristmasStore ? 'naszym sklepiku' : 'naszej restauracji'}!</div>
            <div className='home__title--secondary'>
              <div>Jesteśmy otwarci</div>
              {working_hours && Object.entries(working_hours).map(([key, value]) => <div key={key} dangerouslySetInnerHTML={{ __html: value }} />)}
            </div>
          </>}

        <div>
          <div className='home__tiles'>
            <div
              className='tiles__element'
              onClick={() => push('/categories')}>
              <NewOrder />
              <div className='tiles__description'>Złóż<br />zamówienie</div>
            </div>

            {!isChristmasStore ? <>
              <div
                className='tiles__element'
                onClick={() => push(`/scan?type=${SCANNER_TYPE.status}`)}>
                <QrCode />
                <div className='tiles__description'>Zeskanuj<br />zamówienie</div>
              </div>

              <div
                className='tiles__element'
                onClick={cleanUpClick}>
                <CleaningBrush />
                <div className='tiles__description'>Posprzątaj<br />stolik</div>
              </div>

              <div
                className='tiles__element --muted'>
                {/* onClick={() => push('/reservation')}> */}
                <DiningRoom />
                <div className='tiles__description'>Rezerwuj<br />stolik</div>
              </div>
            </> : <div
              className='tiles__element'
              onClick={() => push('/orders')}>
              <List />
              <div className='tiles__description'>Twoje<br />zamówienia</div>
            </div>}
          </div>

          {!isChristmasStore && <IconTextWideBtn clickAction={() => push('/orders')} />}

        </div>
      </div>

      {bannersList.length > 0 && <div className='home__banner'>
        <Banners extraList={bannersList} />
      </div>}
    </div>
  )
}

export default Home