import { setMainColor, changePhotoVisibility, selectedPhotoVisibility } from '../settingsSlice'
import { useDispatch, useSelector } from 'react-redux'
import ColorList from '../ColorList'
import './style.scss'

const SettingsList = () => {
  const photoVisibility = useSelector(selectedPhotoVisibility)
  const dispatch = useDispatch()

  const mainColorChanged = (e) => {
    if (e.target.id) {
      dispatch(setMainColor(e.target.id))
    }
  }

  const setPhotoVisibile = () => {
    if (!photoVisibility) {
      dispatch(changePhotoVisibility())
    }
  }

  const setPhotoInvisibile = () => {
    if (photoVisibility) {
      dispatch(changePhotoVisibility())
    }
  }

  return (
    <div className='settings__list'>
      <div className='list__column'>
        <div className='list__row'>
          <div className='list__element'>
            <div className='list__title'>Wybierz kolor dominujący aplikacji</div>
            <ColorList colorChanged={mainColorChanged} />
          </div>
        </div>
      </div>
      <div className='list__column'>
        <div className='list__row'>
          <div className='list__element'>
            <div className={`list__title ${photoVisibility ? '--dim' : ''}`}
              onClick={setPhotoInvisibile}>No photo</div>
          </div>
        </div>
        <div className='list__row'>
          <div className='list__element'>
            <div className={`list__title ${photoVisibility ? '' : '--dim'}`}
              onClick={setPhotoVisibile}>Mixed</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsList