import { selectCatregories, selectResponseStatus } from '../Categories/categoriesSlice'
import IconTextBtn from '../../components/buttons/IconTextBtn'
import BackBtn from '../../components/buttons/BackBtn'
import { useHistory, useParams } from 'react-router'
import { useEffect, useRef, useState } from 'react'
import { scrollToTop } from '../../utils/scroll'
import Loading from '../../components/Loading'
//import FoodCarousel from './FoodCarousel'
import { STATUS } from '../../rest/status'
import Error from '../../components/Error'
import { useSelector } from 'react-redux'
import FoodList from './FoodList'
import './style.scss'

const Food = ({ id, isMobile = true }) => {

  let { foodKey } = useParams()
  let { replace, push, goBack } = useHistory()

  const [selectedBtnKey, setSlectedBtnKey] = useState(foodKey)
  const [navHeight, setNavHeight] = useState(80)
  const categories = useSelector(selectCatregories)
  const status = useSelector(selectResponseStatus)
  const navRef = useRef(null)

  const backBtn = <BackBtn backAction={() => window.history.state ? goBack() : push('/')} />

  useEffect(() => {
    setNavHeight(navRef.current?.clientHeight || 80)
  }, [navRef.current?.clientHeight])

  useEffect(() => {
    if (status === STATUS.succeed) {
      const keyChanged = categories[0] ? categories[0].key !== foodKey : false

      if (keyChanged) scrollNav(foodKey)
      if (id) scrollListToElement()
      else if (keyChanged) scrollList(foodKey, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, status])

  const changeSection = key => {
    setSlectedBtnKey(key)
    replace(key)
    scrollNav(key)
  }

  const scrollNav = key => {
    const item = document.querySelector(`#food_navigation_item_${key}`)
    const navigation = document.querySelector(`#food_navigation_list`)

    if (item && navigation) {
      navigation.scrollLeft = item.offsetLeft - 80
    }
  }

  const scrollList = (key, start = false) => {
    const element = document.querySelector(`#food_list_section_${key}`)
    if (element) scrollToTop(element, -(navHeight + 30), start ? 'auto' : 'smooth')
  }

  const scrollListToElement = () => {
    const element = document.querySelector(`#food_list_element_${id}`)
    if (element) scrollToTop(element, -navHeight, 'auto')
  }

  const navButtons = categories.map(({ key, name, icon }) => (
    <div key={key}
      id={`food_navigation_item_${key}`}
      className='navigation__item'>

      <IconTextBtn
        isFilled={key === selectedBtnKey}
        isEnlarged={false}
        desc={name}
        Icon={icon}
        clickAction={() => scrollList(key)} />
    </div>
  ))

  return (
    <div className='food'>
      {isMobile && <div ref={navRef} className='food__navigation'>
        {backBtn}
        <div id='food_navigation_list' className='navigation__list'>
          {navButtons}
        </div>
      </div>}

      {status === STATUS.failed ? <Error />
        : status === STATUS.succeed ? (
          <>
            {/* {isMobile && <FoodCarousel />} */}
            <FoodList
              id={Number(id)}
              categories={categories}
              selectedKey={selectedBtnKey}
              sendKey={key => changeSection(key)} />
          </>
        ) : <Loading />}

    </div>
  )
}

export default Food