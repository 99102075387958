import { useSelector } from 'react-redux'
import { COLORS, selectedMainColor } from '../settingsSlice'
import './style.scss'

const ColorList = ({ colorChanged }) => {
  const mainColor = useSelector(selectedMainColor)

  const elements = COLORS.map((color) => {
    const className = `colors__block ${mainColor === color ? '--dim' : ''}`
    return <div id={color} key={color} className={className} onClick={colorChanged}></div>
  })

  return (
    <div className='colors'>
      {elements}
    </div>
  )
}

export default ColorList