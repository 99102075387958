import { selectedAvailableOptions, selectedOptions, updateSelectedOptions } from './reservationSlice'
import { useDispatch, useSelector } from 'react-redux'

const FreeOptions = () => {
  const options = useSelector(state => selectedAvailableOptions(state, false))
  const selected = useSelector(selectedOptions)
  const dispatch = useDispatch()

  return (
    <>
      <h2>Opcje dodatkowe</h2>
      <p>Dla twojej wygody przygotowaliśmy również<br />dodatkowe, darmowe opcje.</p>

      <div className='reservation__options--free'>
        {options.map(({ id = 0, name = '' }) => {
          const isSelected = selected.includes(id)
          return (
            <div key={id}
              className={`options__item ${isSelected ? '--selected' : ''}`}
              onClick={() => dispatch(updateSelectedOptions({ id, add: !isSelected }))}>
              <div className='options__title'>{name}</div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default FreeOptions