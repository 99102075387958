import { ReactComponent as Warning } from '../../../assets/icons/warning.svg'
import './style.scss'

const Checkbox = ({ name, label, warning = false, checked = false, changeHandler }) => {
  return (
    <div className='checkbox__container'>
      {warning && <div className='checkbox__icon'>
        <Warning />
      </div>}

      <label>
        {label}
        <input
          name={name}
          type='checkbox'
          className='checkbox'
          checked={checked}
          onChange={changeHandler} />
        <span className={`checkmark ${warning ? '--warning' : ''}`} />
      </label>
    </div>
  )
}

export default Checkbox