import waiter from '../../assets/backgrounds/waiter.png'
import fire from '../../assets/backgrounds/fire.png'
import { createSlice } from '@reduxjs/toolkit'

const phoneRegex = /^[1-9][0-9]{0,2}[-| ]?[0-9]{0,3}[-| ]?[0-9]{0,3}$/

const form = {
  fullName: '',
  phone: '',
  people: '',
  time: '',
  date: '',
  type: '',
  place: '',
  comments: '',
  rulesAgreement: false,
}

const options = [{
  id: 1,
  payable: {
    once: false,
    price: 12.9,
  },
  name: 'Obsługa kelnerska',
  desc: 'Aenean in venenatis orci. Curabitur venenatis feugiat interdum. Donec sodales vulputate tellus. Curabitur at fringilla enim.',
  img: waiter,
}, {
  id: 2,
  payable: {
    once: true,
    price: 30.0,
  },
  name: 'Ognisko',
  desc: 'Aenean in venenatis orci. Curabitur venenatis feugiat interdum. Donec sodales vulputate tellus. Curabitur at fringilla enim.',
  img: fire,
}, {
  id: 3,
  payable: false,
  name: 'Menu w języku angielskim',
}, {
  id: 4,
  payable: false,
  name: 'Krzesło dziecięce',
}, {
  id: 5,
  payable: false,
  name: 'Menu bez cen',
}]

export const reservationSlice = createSlice({
  name: 'reservation',
  initialState: {
    form,
    options,
    selected: [],
  },
  reducers: {
    updateForm: (state, action) => {
      const { name, value } = action.payload

      if (name && state.form[name] !== undefined) {
        if (name === 'phone' || name === 'people')
          if (value && !value.match(name === 'phone' ? phoneRegex : /^[0-9]*$/)) return

        state.form[name] = value
      }
    },
    updateSelectedOptions: (state, action) => {
      const { id, add } = action.payload

      if (id) {
        if (add) state.selected.push(id)
        else state.selected = state.selected.filter(e => e !== id) 
      }
    },
    resetReservation: (state) => {
      state.form.fullName = ''
      state.form.phone = ''
      state.form.people = ''
      state.form.time = ''
      state.form.date = ''
      state.form.type = ''
      state.form.place = ''
      state.form.comments = ''
      state.form.rulesAgreement = false
    },
  },
})

export const { updateForm, resetReservation, updateSelectedOptions } = reservationSlice.actions

export const selectedForm = state => state.reservation.form
export const selectedAvailableOptions = (state, paid) => {
  const { options } = state.reservation
  return paid === undefined ? options : options.filter(({ payable }) => paid ? payable : !payable)
}
export const selectedOptions = state => state.reservation.selected

export default reservationSlice.reducer
