import { ReactComponent as Categories } from '../../assets/icons/categories.svg'
import { updateSearchWord } from '../Categories/categoriesSlice'
import ServicePopup from '../../components/popups/ServicePopup'
import OneBtnPopup from '../../components/popups/OneBtnPopup'
import ShoppingBasket from '../../components/ShoppingBasket'
import AboutPopup from '../../components/popups/AboutPopup'
import { selectedConfig } from '../Settings/settingsSlice'
import ThemeSwitcher from '../../components/ThemeSwicher'
import Hamburger from '../../components/icons/Hamburger'
import { useDispatch, useSelector } from 'react-redux'
import Search from '../../components/icons/Search'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import SearchPanel from './SearchPanel'
import SubMenu from './SubMenu'
import './style.scss'

export const MENU_TYPE = {
  initial: 'initial',
  search: 'search',
  categories: 'categories'
}

const Menu = ({ type = MENU_TYPE.initial }) => {
  const [isBurgerClicked, setBurgerClicked] = useState(false)
  const [isSearchClicked, setSearchClicked] = useState(false)
  const [menuType, setMenuType] = useState(type)
  const [popup, setPopup] = useState('')

  const { christmas_howto = '' } = useSelector(selectedConfig)
  const dispatch = useDispatch()
  let { push } = useHistory()

  const hidePopup = () => setPopup('')

  useEffect(() => {
    setMenuType(type)
  }, [type])

  const burgerClicked = () => {
    isBurgerClicked ? setMenuType(type) : setMenuType(MENU_TYPE.categories)
    setBurgerClicked(!isBurgerClicked)
    if (isSearchClicked) {
      dispatch(updateSearchWord())
      setSearchClicked(false)
    }
  }

  const searchClicked = () => {
    if (isSearchClicked) {
      dispatch(updateSearchWord())
      setMenuType(type)
    }
    setSearchClicked(!isSearchClicked)
    setBurgerClicked(false)
  }

  const burger = <Hamburger
    clicked={isBurgerClicked}
    clickHandler={burgerClicked} />

  const subMenu = <SubMenu
    closeSubMenu={burgerClicked}
    openPopup={name => {
      burgerClicked()
      setPopup(name)
    }} />

  return (
    <>
      {popup && <>
        {popup === 'about' ? <AboutPopup
          closeAction={hidePopup} />
        : popup === 'service' ? <ServicePopup
          closeAction={hidePopup} />
        : <OneBtnPopup
          title='Jak to działa?'
          description={<div dangerouslySetInnerHTML={{ __html: christmas_howto }} />}
          btnText='OK'
          btnAction={hidePopup} />}
      </>}

      {menuType === MENU_TYPE.initial ? (
        <div className='menu'>
          <div className='menu__main'>
            <div className='menu__switch'>
              <ThemeSwitcher />
            </div>
            <div className='menu__description'>Tutaj zamówisz potrawy omijając kolejkę!</div>
            {burger}
          </div>
        </div>
      ) : (
        <div className='menu'>
          {isBurgerClicked && subMenu}
          {isSearchClicked && <SearchPanel
            closeSearchPanel={searchClicked} />}

          <div className='menu__main'>
            <ShoppingBasket
              clickAction={() => (isBurgerClicked && burgerClicked()) || (isSearchClicked && searchClicked())} />

            <div className='menu__group'>
              {menuType === MENU_TYPE.search && <Search
                clicked={isSearchClicked}
                clickHandler={searchClicked} />}
              {menuType === MENU_TYPE.categories && <Categories
                onClick={() => push('/categories')} />}
              {burger}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Menu