import { createSlice } from '@reduxjs/toolkit';

const phoneRegex = /^[1-9][0-9]{0,2}[-| ]?[0-9]{0,3}[-| ]?[0-9]{0,3}$/
const mailRegex = /^[a-zA-Z0-9_.-]*[@]{0,1}[a-zA-Z0-9-]*[.]{0,1}[a-zA-Z0-9-.]*$/
const codeRegex = /^[0-9]{0,2}[-]?[0-9]{0,3}$/
const nipRegex = /^[1-9][0-9]{0,9}$/

const data = {
  name: '',
  surname: '',
  phone: '',
  friendPhone: '',
  cardNumber: '',
  comments: '',
  mail: '',
  address: '',
  town: '',
  vat: false,
  companyNip: '',
  companyName: '',
  companyStreet: '',
  companyCode: '',
  companyCity: '',
  rulesAgreement: false,
  notificationsAgreement: false,
}

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    data,
  },
  reducers: {
    updateData: (state, action) => {
      const { name, value } = action.payload

      if (name && state.data[name] !== undefined) {
        if (name === 'phone' || name === 'friendPhone' || name === 'mail' || name === 'companyNip' || name === 'companyCode') {
          if (value && !value.match(name === 'mail' ? mailRegex
            : name === 'companyNip' ? nipRegex
              : name === 'companyCode' ? codeRegex
                : phoneRegex)) return
        }
        state.data[name] = value
      }
    },
    resetCompanyData: (state) => {
      state.data.companyNip = ''
      state.data.companyName = ''
      state.data.companyStreet = ''
      state.data.companyCode = ''
      state.data.companyCity = ''
    },
  },
})

export const { updateData, resetCompanyData } = formSlice.actions
export const selectedData = state => state.form.data

export default formSlice.reducer
