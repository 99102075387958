import { ReactComponent as OrdersHistory } from '../../assets/icons/ordersHistory.svg'
import { ReactComponent as NewOrder } from '../../assets/icons/newOrderWhite.svg'
import { ReactComponent as OrdersIcon } from '../../assets/icons/orders.svg'
import { ReactComponent as QrCode } from '../../assets/icons/qrCode.svg'
import { useHistory } from 'react-router'
import { SCANNER_TYPE } from '../Scanner'
import './style.scss'

const Menu = ({ currentOrders, showCurrentOrders }) => {
  let { push } = useHistory()

  return (
    <div className='orders__menu'>
      <div className='menu__item'
        onClick={() => push('/categories')}>
        <NewOrder />
        <div className='item__desc'>
          <div>Nowe</div>
          <div>zamówienie</div>
        </div>
      </div>

      <hr />

      <div className={`menu__item ${currentOrders ? '--selected' : ''}`}
        onClick={() => showCurrentOrders(true)}>
        <OrdersIcon />
        <div className='item__desc'>
          <div>Bieżące</div>
          <div>zamówienie</div>
        </div>
      </div>

      <hr />

      <div className={`menu__item ${!currentOrders ? '--selected' : ''}`}
        onClick={() => showCurrentOrders(false)}>
        <OrdersHistory />
        <div className='item__desc'>
          <div>Historia</div>
          <div>zamówień</div>
        </div>
      </div>

      <hr />

      <div className='menu__item'
        onClick={() => push(`/scan?type${SCANNER_TYPE.status}`)}>
        <QrCode />
        <div className='item__desc'>
          <div>Zeskanuj</div>
          <div>zamówienie</div>
        </div>
      </div>
    </div>
  )
}

export default Menu