import { selectPaymentStatus, speedUpDuration } from '../../Announcements/statusSlice'
import { useDispatch, useSelector } from 'react-redux'
import blik from '../../../assets/icons/blik.png'
import BlikVerification from './verification'
import { STATUS } from '../../../rest/status'
import { payment } from '../../../rest/urls'
import { post } from '../../../rest/request'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import BlikForm from './form'
import './style.scss'

const Blik = () => {
  const paymentStatus = useSelector(selectPaymentStatus)
  const [status, setStatus] = useState(STATUS.idle)
  const [error, showError] = useState(false)
  const [form, showForm] = useState(true)
  const dispatch = useDispatch()

  let { replace } = useHistory()

  useEffect(() => {
    if (paymentStatus && status === STATUS.pennding) {
      const { status_raw, wynik = '' } = paymentStatus
      if (wynik === 'end' && status_raw !== 'success') {
        showError(true)
        setStatus(STATUS.failed)
      }
    }
  }, [status, paymentStatus, replace, dispatch])

  const submitForm = async code => {
    const blik_code = Number(code.replace(/[ ]/g, ''))
    showForm(false)

    try {
      const response = await post(payment, { blik_code })
      console.log(response)
      if (response.wynik === 'ok') {
        dispatch(speedUpDuration())
        setStatus(STATUS.pennding)
      }

    } catch (err) {
      console.log(err)
      showError(true)
    }
  }

  return (
    <div className='blik'>
      <div className='blik__main'>
        <header>
          <h2>Płacę z</h2>
          <img src={blik} alt='blik' />
        </header>

        {form ? <BlikForm
          error={error}
          submitForm={submitForm} />
          : <BlikVerification
            status={status}
            stopPayment={() => {
              showForm(true)
              showError(true)
            }} />}
      </div>
    </div>
  )
}

export default Blik