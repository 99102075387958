import Popup from '../Popup'
import './style.scss'

const VariantsPopup = ({ title = 'Wybierz wariant', isAdditionalBtn = false, list = [], closeAction, sendAction }) => {
  const elements = list.map(e => {
    const name = typeof e === 'object' ? e.name : e
    return (
      <div key={name}
        className='list__element'
        onClick={() => sendAction(e)}>
        {name}
      </div>
    )
  })

  return (
    <Popup closeAction={closeAction}>
      <div className='popup__main--variants'>
        <div className='popup__title'>{title}</div>
        <div className='popup__list'>
          {elements}
        </div>
        {isAdditionalBtn && <div className='popup__button'
          onClick={() => sendAction(0)}>Nie, dziękuję!</div>}
      </div>
    </Popup>
  )
}

export default VariantsPopup