export const getBannersList = (banners, isHomePage = true) => {
  const isChristmasStore = process.env.REACT_APP_CHRISTMAS_STORE === 'true'

  if (banners && banners.collection) {
    return banners.collection.filter(({ which_app, where_to_show }) => {
      let filtered = false

      if (isChristmasStore) filtered = which_app === 0 || which_app === 2
      else filtered = which_app === 1 || which_app === 2
      if (isHomePage) filtered = filtered && (where_to_show === 1 || where_to_show === 3)
      else filtered = filtered && (where_to_show === 2 || where_to_show === 3)

      return filtered
    })
  }

  return []
}