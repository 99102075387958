import { ReactComponent as Announcement } from '../../../assets/icons/announcement.svg'
import { ReactComponent as Logout } from '../../../assets/icons/logout.svg'
import { ReactComponent as Home } from '../../../assets/icons/home.svg'
import { ReactComponent as List } from '../../../assets/icons/list.svg'
import CloseBtn from '../../../components/buttons/CloseBtn'
import { useHistory } from 'react-router'
import './style.scss'

const SubMenu = ({ closeSubMenu, openPopup }) => {
  const isChristmasStore = process.env.REACT_APP_CHRISTMAS_STORE === 'true'
  let { location, replace, push } = useHistory()

  const backToHome = () => {
    closeSubMenu()
    if (location.pathname !== '/') replace('/')
  }

  return (
    <div className='menu__submenu'>
      <div className='submenu__background'
        onClick={closeSubMenu}>
        <CloseBtn />
      </div>

      <div className='submenu__main'>
        <div className='submenu__row'>
          <div className='submenu__group'>
            <div
              className={`submenu__title--secondary ${isChristmasStore ? '' : '--muted'}`}
              onClick={() => isChristmasStore && openPopup('howTo')}>Jak to działa?</div>
            <div
              className='submenu__title--secondary'
              onClick={() => openPopup('about')}>O nas</div>
          </div>

          <div 
            className='submenu__group --muted'
            onClick={backToHome}>
            <Logout />
            <div className='submenu__title--secondary'>Zaloguj</div>
          </div>
        </div>

        <div className='submenu__row'>
          <div 
            className='submenu__element'
            onClick={backToHome}>
            <Home />
            <div className='submenu__title'>Wróć na start</div>
          </div>

          <div 
            className='submenu__element'
            onClick={() => {
              closeSubMenu()
              push('/orders')
            }}>
            <List />
            <div className='submenu__title'>Moje zamówienia</div>
          </div>

          {process.env.REACT_APP_CHRISTMAS_STORE !== 'true' && <div
            className='submenu__element'
            onClick={() => openPopup('service')}>
            <Announcement />
            <div className='submenu__title'>Wezwij obsługę</div>
          </div>}
        </div>

      </div>
    </div>
  )
}

export default SubMenu