import { ReactComponent as Warning } from '../../assets/icons/warning.svg'
import './style.scss'

const OrderPicker = ({ selected = false, warning = false, orders = {}, changeHandler }) => {
  return (
    <div className='select'>
      {warning && <div className='select__icon --left'>
        <Warning />
      </div>}

      <div className='select__icon --right'>
        <div className='arrow__down' />
      </div>

      <select
        name='orders'
        defaultValue={selected ? selected : ''}
        className={`select__main ${warning ? '--warning' : ''}`}
        onChange={changeHandler}
        required>

        {!selected && <option value='' disabled hidden>Wybierz zamówienie</option>}
        {Object.entries(orders).map(([key, { table_number: table, time, cart_total }]) => <option
          key={key}
          value={key}>
          Zam. z {time}{table ? `, stolik: ${table}` : ''} za {cart_total}zł
        </option>)}

      </select>
      {warning && <span className='select__warning'>Musisz wybrać zamówienie</span>}
    </div>
  )
}

export default OrderPicker